import { Component, OnInit, ViewChild } from '@angular/core';
import { IonPullUpFooterState } from 'ionic-pullup';
import { DataManagementService } from '../../data-management/data-management.service';
import { HistoricalService } from '../historical.service';
import { ToastController, Platform, PopoverController } from '@ionic/angular';
import { OverspeedModalComponent } from './overspeed-modal/overspeed-modal.component';
import { Subscription } from 'rxjs';
import { Overspeed } from 'src/app/model/historical';
import { Group, Vehicule } from 'src/app/model/data-managemnt';
import Icon = L.Icon;
import * as L from 'leaflet';
import Marker = L.Marker;
import { DatePipe } from "@angular/common";
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { Map } from "leaflet";
import { GlobalService } from 'src/app/global.config';
import { LoadingService } from 'src/app/utils/loading/loadingService';
import { version } from '../../../global.config';

@Component({
  selector: 'app-overspeed',
  templateUrl: './overspeed.component.html',
  styleUrls: ['./overspeed.component.scss'],
})
export class OverspeedComponent implements OnInit {

  @ViewChild(OverspeedModalComponent, { static: false }) overspeedModalComponent: OverspeedModalComponent;

  overspeedMap: Map;
  footerState: IonPullUpFooterState;
  AllOverspeeds: Subscription;
  AllGroups: Subscription;
  overspeedClicked: Overspeed;
  OverspeedArray = [];
  overspeeds: Overspeed[] = [];
  typicalPathName: string;

  backButtonSubscription: Subscription;
  loading: boolean = false;
  version: string;

  constructor(
    private mapService: MapService, public historicalService: HistoricalService,
    public toastr: ToastController, private geocodingService: GeocodingService, private platform: Platform,
    public popoverController: PopoverController, public dataManagementService: DataManagementService,
    private globalService: GlobalService, public dataPipe: DatePipe,
    public loadingService: LoadingService
  ) {
    this.footerState = IonPullUpFooterState.Collapsed;
    this.historicalService.selectedTab = "overspeed";
    this.version = version;
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
    if (this.dataManagementService.selectedVehicule) {
      this.historicalService.getAllOverSpeedRoutine();
    }
  }


  ionViewDidEnter() {
    this.mapService.initMap(this.overspeedMap, "overspeedMap");
  }

  ionViewWillLeave() {
    this.mapService.map.remove();
  }

  footerExpanded() {
    console.log('Footer expanded!');
  }

  footerCollapsed() {
    console.log('Footer collapsed!');
  }

  toggleFooter() {

    this.footerState = this.footerState === IonPullUpFooterState.Collapsed ? IonPullUpFooterState.Expanded : IonPullUpFooterState.Collapsed;

  }

  async openPopover() {
    const popover = await this.popoverController.create({
      component: OverspeedModalComponent,
      cssClass: 'historiquePopovers'
    });

    await popover.present();
  }

  ngOnDestroy() {
    if (this.AllGroups) this.AllGroups.unsubscribe();
    if (this.AllOverspeeds) this.AllOverspeeds.unsubscribe();
  }


  drawOverspeed(overspeed: Overspeed) {
    if (
      this.overspeedClicked == null ||
      this.overspeedClicked.beginPathTime !== overspeed.beginPathTime
    ) {
      this.overspeedClicked = new Overspeed();
      this.overspeedClicked = overspeed;
    }
    let maxSpeed = this.dataManagementService.selectedVehicule!.maxSpeed;
    this.historicalService
      .getOverspeedDetails(this.dataManagementService.selectedVehicule!.device.idDevice, {
        startDate: overspeed.beginPathTime,
        endDate: overspeed.endPathTime
      })
      .subscribe(
        points => {
          let startHour: any = overspeed.beginPathTime;
          let pathStartTime: any = startHour;
          overspeed.displayBeginPathTime = pathStartTime;
          let endHour: any = overspeed.endPathTime;
          let pathEndTime: any = endHour;
          overspeed.displayEndPathTime = pathEndTime;


          let polyline = L.polyline(points.coordinates, {
            color: "#0031D9",
            weight: 3
          });
          points.coordinates.forEach((coordinate: any, i: any) => {
            let marker = new Marker(coordinate);
            if (i != 0 && i != points.coordinates.length - 1) {
              if (coordinate.speed > maxSpeed) {
                marker.setIcon(
                  new Icon({
                    iconUrl: this.globalService.imagesDir + "red-point.png",
                    iconAnchor: [2, 2]
                  })
                );
              } else if (coordinate.speed < 5) {
                marker.setIcon(
                  new Icon({
                    iconUrl: this.globalService.imagesDir + "blue-point.png",
                    iconAnchor: [2, 2]
                  })
                );
              } else {
                marker.setIcon(
                  new Icon({
                    iconUrl: this.globalService.imagesDir + "green-point.png",
                    iconAnchor: [2, 2]
                  })
                );
              }
              let pointTime: any = coordinate.date;
              let coordinateTime: any = pointTime;
              coordinate.date = coordinateTime;
              //var pointDatePipe = new DatePipe(coordinate.date);
              let popup =
                "<b>Heure:</b> " +
                this.dataPipe.transform(
                  coordinate.date, "dd/MM/yyyy HH:mm:ss", 'en-US') +
                "<b><br>vitesse:</b> " +
                coordinate.speed +
                " Km/h";
              marker.bindPopup(popup);
              marker.on("click", () => {
                this.mapService.map.setView(coordinate, 17);
              });
              this.mapService.addMarker(marker);
            }
          });
          var startMarker = new Marker({
            lat: overspeed.beginPathLatitude,
            lng: overspeed.beginPathLongitude
          });
          let startTime: any = overspeed.displayBeginPathTime;
          // var startDatePipe = new DatePipe(startTime);
          startMarker.bindPopup(
            "<b> Lieu de début: </b>" +
            this.overspeedClicked.beginPathGeocoding +
            "<br><b> <i>[lat: </b>" +
            overspeed.beginPathLatitude +
            "<b> ,long: </b>" +
            overspeed.beginPathLongitude +
            "<b>]</i><br>Temps de debut du trajet : </b>" +
            this.dataPipe.transform(startTime, "dd/MM/yyyy HH:mm:ss", 'en-US')
          );
          startMarker.setIcon(
            new Icon({
              iconUrl: this.globalService.imagesDir + "startMarker.png",
              iconAnchor: [-2, 30],
              popupAnchor: [10, -25]
            })
          );
          this.mapService.addMarker(startMarker);
          if (
            overspeed.endPathLatitude != null &&
            overspeed.endPathLongitude != null &&
            overspeed.endPathTime != null
          ) {
            var endMarker = new Marker({
              lat: overspeed.endPathLatitude,
              lng: overspeed.endPathLongitude
            });

            let endTime: any = overspeed.displayEndPathTime;
            var endDatePipe = new DatePipe(endTime);

            endMarker.bindPopup(
              "<b> Lieu de fin: </b>" +
              this.overspeedClicked.endPathGeocoding +
              "<br><i>[<b>lat:  </b>" +
              overspeed.endPathLatitude +
              "<b>,long:  </b>" +
              overspeed.endPathLongitude +
              "<br><b> Durée du trajet : </b>" +
              overspeed.pathDurationStr +
              "<br><b> Durée d'arrêt : </b>" +
              overspeed.nextStopDurationStr +
              "<br><b> Vitesse maximum : </b>" +
              overspeed.maxSpeed


            );
            endMarker.setIcon(
              new Icon({
                iconUrl: this.globalService.imagesDir + "endMarker.png",
                iconAnchor: [-2, 30],
                popupAnchor: [10, -25]
              })
            );
            this.mapService.addMarker(endMarker);
          }
          this.mapService.addPolyline(polyline);
          /*var middle =
            points.coordinates[Math.round((points.coordinates.length - 1) / 2)];
          //document.getElementById("save_path_button").classList.remove('hide');

          //map center offset(axis y)
          this.mapService.offsetMap(middle, 12, 0, -120);**/
          this.mapService.map.fitBounds(polyline.getBounds());
        },
        err => { }
      );
  }

  addOverspeedToArray(overspeed: any, event: any) {
    if (!event.ctrlKey) {
      this.OverspeedArray = [];
      this.mapService.removePolylinesFromMap();
      this.mapService.removeMarkersFromMap();
    }

    this.OverspeedArray.push(overspeed as never);
    this.drawOverspeed(overspeed);
    this.footerState = IonPullUpFooterState.Collapsed;
  }



  OverspeedSelected(overspeed: any) {
    return this.OverspeedArray.indexOf(overspeed as never) !== -1;
  }

  displayGoogleAdress(overspeed: Overspeed) {
    if (overspeed) {
      this.geocodingService
        .inverseGeocondingGoogle(
          overspeed.beginPathLatitude,
          overspeed.beginPathLongitude
        )
        .subscribe(response => {
          if (response && response.results[0]) {
            overspeed.beginPathGeocodingDetails =
              response.results[0].formatted_address;
          }
        });

      this.geocodingService
        .inverseGeocondingGoogle(overspeed.endPathLatitude, overspeed.endPathLongitude)
        .subscribe(response => {
          if (response && response.results[0]) {
            overspeed.endPathGeocodingDetails =
              response.results[0].formatted_address;
          }
        });
    }
  }
}
