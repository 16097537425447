import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { HistoricalService } from '../historical.service';
import { Group } from 'src/app/model/data-managemnt';
import { Platform, PopoverController } from '@ionic/angular';
import { DataManagementService } from '../../data-management/data-management.service';
import { Stop } from 'src/app/model/historical';
import { IonPullUpFooterState } from 'ionic-pullup';
import * as L from 'leaflet';
import Marker = L.Marker;
import Icon = L.Icon;
import { DatePipe } from "@angular/common";
import { StopModalComponent } from './stop-modal/stop-modal.component';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { Map } from "leaflet";
import { GlobalService } from 'src/app/global.config';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { LoadingService } from 'src/app/utils/loading/loadingService';
import { version } from '../../../global.config';

@Component({
  selector: 'app-stop',
  templateUrl: './stop.component.html',
  styleUrls: ['./stop.component.scss'],
})
export class StopComponent implements OnInit {

  @ViewChild(StopModalComponent, { static: false }) stopModalComponent: StopModalComponent;

  AllStops: Subscription;
  selectedGroup: Group = new Group();
  //selectedVehicule: Vehicule = null;
  stopClicked: Stop;
  stops: Stop[] = [];
  StopArray = [];
  footerState: IonPullUpFooterState;
  stopMap: Map;
  backButtonSubscription: Subscription;
  loading: boolean;
  version: string;

  constructor(public mapService: MapService, public historicalService: HistoricalService,
    private popoverController: PopoverController, public dataManagementService: DataManagementService, private platform: Platform,
    private globalService: GlobalService,
    private geocodingService: GeocodingService, public dataPipe: DatePipe,
    public loadingService: LoadingService) {
    this.footerState = IonPullUpFooterState.Collapsed;
    this.historicalService.selectedTab = "stop";
    this.version = version;
  }

  ngOnInit() {

  }
  ionViewWillEnter() {
    if (this.dataManagementService.selectedVehicule) {
      //this.historicalService.loading()
      this.loading = true
      let endDate = this.historicalService.endDate;
      let startDate = this.historicalService.startDate;
      let idDevice = this.dataManagementService.selectedVehicule.idDevice
      this.historicalService.getAllStops(idDevice, { startDate, endDate }).subscribe(
        stops => {
          this.loading = false
          //this.historicalService.dismissLoader()
          this.historicalService.stops = stops
          this.historicalService.stops.forEach(stop => {
            stop.geocodingDetails = this.dataManagementService.getRelativePosition(
              stop.stopLatitude,
              stop.stopLongitude
            );
            stop.geocoding = stop.geocodingDetails;
            stop.beginStopTime = stop.beginStopTime;
            if (stop.geocodingDetails == null || stop.geocodingDetails == '') {
              /*if (version == 'click') {
                this.geocodingService
                  .inverseGeocondingAddok(stop.stopLatitude, stop.stopLongitude)
                  .subscribe(
                    adress => {
                      stop.geocoding = adress.features[0].properties.label;
                      stop.geocodingDetails = adress.features[0].properties.label;
                    },
                    err => {
                      stop.geocodingDetails = null;
                      stop.geocoding = null;
                    }
                  );
              } else {*/
              this.geocodingService
                .inverseGeoconding(stop.stopLatitude, stop.stopLongitude, 17)
                .subscribe(
                  adress => {
                    stop.geocoding = this.geocodingService.proccessingNomitamAddress(
                      adress
                    );
                    stop.geocodingDetails = stop.geocoding;
                  },
                  err => {
                    stop.geocodingDetails = null;
                    stop.geocoding = null;
                  }
                );
              //}
            }
          });
        }
      )
    }
  }


  ionViewDidEnter() {
    this.mapService.initMap(this.stopMap, "stopMap");
  }

  ionViewWillLeave() {
    this.mapService.map.remove();
  }

  footerExpanded() {
    console.log('Footer expanded!');
  }

  footerCollapsed() {
    console.log('Footer collapsed!');
  }

  toggleFooter() {

    this.footerState = this.footerState === IonPullUpFooterState.Collapsed ? IonPullUpFooterState.Expanded : IonPullUpFooterState.Collapsed;

  }
  async openPopover() {
    const popover = await this.popoverController.create({
      component: StopModalComponent,
      cssClass: 'historiquePopovers'
    });

    await popover.present();
  }

  ngOnDestroy() {
    if (this.AllStops) {
      this.AllStops.unsubscribe();
    }
  }

  addStopToArray(stop: any, event: any) {
    if (!event.ctrlKey) {
      this.StopArray = [];
      this.mapService.removeMarkersFromMap();
    }

    this.StopArray.push(stop as never);
    this.drawStop(stop);
    this.footerState = IonPullUpFooterState.Collapsed;
  }

  StopSelected(stop: any) {
    return this.StopArray.indexOf(stop as never) !== -1;
  }
  drawStop(stop: Stop) {
    console.log(stop.geocoding)
    if (
      this.stopClicked == null ||
      this.stopClicked.beginStopTime != stop.beginStopTime
    ) {
      this.stopClicked = new Stop();
      this.stopClicked.beginStopTime = stop.beginStopTime;
      this.stopClicked.endStopTime = stop.endStopTime;
    }

    let marker = new Marker({
      lat: stop.stopLatitude,
      lng: stop.stopLongitude
    });
    // let beginStopTime: any = stop.beginStopTime;
    // const pointDatePipe = new DatePipe(beginStopTime);
    let popup =
      "<b>Date debut d'arrêt: </b>" +
      this.dataPipe.transform(stop.beginStopTime, "dd/MM/yyyy HH:mm:ss", 'en-US') +
      "<br><b>Durée d'arrêt: </b>" +
      stop.stopDurationStr +
      "<br><hr><b>" +
      stop.geocodingDetails +
      "</b>";
    marker.setIcon(
      new Icon({
        iconUrl: this.globalService.imagesDir + 'endMarker.png',
        iconAnchor: [-2, 30],
        popupAnchor: [10, -25]
      })
    );

    marker.bindPopup(popup);

    marker.on('click', () => {
      this.mapService.map.setView(
        { lat: stop.stopLatitude, lng: stop.stopLongitude },
        17
      );
    });

    marker.on('add', () => {
      marker.openPopup();
    });

    this.mapService.addMarker(marker);

    this.mapService.map.setView(
      { lat: stop.stopLatitude, lng: stop.stopLongitude },
      15
    );
  }
}
