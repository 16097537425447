import { Credentials, AuthorityName } from '../signin/credentials';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './../global.config';
import { createAuthorizationHeader } from '../utils/headers';
import { User, Action } from '../signin/credentials';
import { Events } from '../utils/Events';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  authState: boolean = false;

  constructor(private _http: HttpClient, private globalService: GlobalService, public events: Events) {
  }

  login(credentials: Credentials): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    return this._http.post(this.globalService.dns + 'signin', credentials, { headers: headers });
  }

  logout() {
    let headers = createAuthorizationHeader();
    return this._http.post(this.globalService.dns + 'signout', {}, { headers: headers });
  }

  addAction(idUser: number, action: Action): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(this.globalService.dns + 'accounts/actions/add/' + idUser, action, {
        headers: headers
      });
  }
  public isRootAdmin() {
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentUser && currentUser.isRoot) {
      return true;
    }
    return false;
  }

  public isHasCarburantOption() {
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentUser && currentUser.options) {
      for (let i = 0; i < currentUser.options.length; i++) {
        if (currentUser.options[i].idOption === 1) {
          return true;
        }
      }
    }
    return false;
  }

  public isHasAntidemOption() {
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentUser && currentUser.options) {
      for (let i = 0; i < currentUser.options.length; i++) {
        if (currentUser.options[i].idOption === 2) {
          return true;
        }
      }
    }
    return false;
  }

  public isHasTemperatureOption() {
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentUser && currentUser.options) {
      for (let i = 0; i < currentUser.options.length; i++) {
        if (currentUser.options[i].idOption === 3) {
          return true;
        }
      }
    }
    return false;
  }

  public isHasChauffeurOption() {
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentUser && currentUser.options) {
      for (let i = 0; i < currentUser.options.length; i++) {
        if (currentUser.options[i].idOption === 4) {
          return true;
        }
      }
    }
    return false;
  }

  public isHasBetonOption() {
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentUser && currentUser.options) {
      for (let i = 0; i < currentUser.options.length; i++) {
        if (currentUser.options[i].idOption === 5) {
          return true;
        }
      }
    }
    return false;
  }


  public isHasRole(authority: AuthorityName) {
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentUser && currentUser.authorities && !currentUser.isRoot) {
      for (let i = 0; i < currentUser.authorities.length; i++) {
        if (currentUser.authorities[i].name === authority) {
          return true;
        }
      }
    }
    return false;
  }

  public getUserId() {
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser') || '{}');
    return currentUser.id;
  }

  isAuthenticated() {
    return this.authState;
  }
}
