export class Path {
  nextStopDuration: number;
  nextStopDurationStr: string;
  pathDurationStr: string | null | undefined;

  beginPathLatitude: number ;
  beginPathLongitude: number  ;

  endPathLatitude: number;
  endPathLongitude: number;

  beginPathGeocoding: string | null;
  endPathGeocoding: string | null;

  beginPathGeocodingDetails: string  | null;
  endPathGeocodingDetails: string  | null;

  beginPathTime: Date;
  endPathTime: Date;

  displayBeginPathTime: Date;
  displayEndPathTime: Date;

  deviceId: number;

  pathDuration: number;
  maxSpeed: number;

  distanceDriven: number;

  constructor() {
    this.distanceDriven = 0;
    this.maxSpeed = 0;
  }
}

export class Stop {
  stopDurationStr: string;
  deviceId: number;

  beginStopTime: number;
  stopDuration: Date;

  stopLatitude: number;
  stopLongitude: number;

  endStopTime: Date;
  inPath: boolean;

  geocoding: string | null;
  geocodingDetails: string | null;

  constructor() { }
}
export class Overspeed {
  nextStopDuration: number;
  nextStopDurationStr: string;
  pathDurationStr: string;

  beginPathLatitude: number;
  beginPathLongitude: number;

  endPathLatitude: number;
  endPathLongitude: number;

  beginPathGeocoding: string | null;
  endPathGeocoding: string | null;

  beginPathGeocodingDetails: string | null;
  endPathGeocodingDetails: string | null;

  beginPathTime: Date;
  endPathTime: Date;

  displayBeginPathTime: Date;
  displayEndPathTime: Date;

  deviceId: number;

  pathDuration: number;
  maxSpeed: number;

  distanceDriven: number;

  constructor() {}
}
export class Mileage {
  startHour: Date;
  endHour: Date;
  drivingDistance: number;
  drivingDurationStr: string;

  constructor() {
    this.drivingDistance = 0;
    this.drivingDurationStr = '';
  }

}


