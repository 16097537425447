import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-alert-management',
  templateUrl: './alert-management.component.html',
  styleUrls: ['./alert-management.component.scss'],
})
export class AlertManagementComponent implements OnInit {




  constructor() { }

  ngOnInit() {}

}
