import { Pipe, PipeTransform } from '@angular/core';
//import { Group } from '../client-management/data-management/data-management';

@Pipe({ name: 'groupfilter' })

export class GroupFilterPipe implements PipeTransform {
  transform(/*groups: Array<Group>, */search: any): any {
/*
    search = search.toLocaleLowerCase();

    if (groups) {
      return groups.filter(group => {
        if (group.nom.toLocaleLowerCase().indexOf(search) !== -1) {
          return group;
        } else {
          let filtredVehicules = [];

          filtredVehicules = group.vehicules.filter(vehicule => {
            let matricule = vehicule.matricule
              ? vehicule.matricule.toLocaleLowerCase()
              : null;
            let alias = vehicule.alias
              ? vehicule.alias.toLocaleLowerCase()
              : null;
            let condition;

            if (matricule) {
              condition = matricule.indexOf(search) !== -1;
            }

            if (alias) {
              condition = condition || alias.indexOf(search) !== -1;
            }

            return condition;
          });

          if (filtredVehicules.length > 0) {
            return group;
          }
        }
      });
    }*/
  }
}

