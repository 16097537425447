export class RealTimeRecord {

  idRealTimeRecord: number;
  recordTime: number;
  coordinate: { lat: number, lng: number };
  speed: number;
  temperature: string;
  validity: boolean;
  ignition: boolean;
  power: number;
  mems_x: number;
  mems_y: number;
  mems_z: number;
  sendFlag: number;
  type: 'AA' | 'GPRMC';
  satInView: number;
  signal: string;
  heading: number;
  matricule: string;
  mark: string;
  icon: string;
  realTimeRecordStatus: string;
  geocoding: string;
  geocodingDetails: string;
  relativePosition: string | null;
  rotationAngle: number;
  fuel: number;
  temp_engine: number;
  rpm: number;
  tfu: number;
  accum_odo: number;
  odo: number;
  fuel_rate: number;
  di1: boolean;
  di2: boolean;
  state: RtState;
  //vehicule: Vehicule = null;
  forceReload:boolean;

  constructor() {
    this.geocoding = `Chargement..`;
    this.geocodingDetails = 'Chargement..';
    this.speed = 0;
    //this.recordTime = new Date();
    this.coordinate = { lat: 0, lng: 0 };
    this.realTimeRecordStatus = '';
    this.state = RtState.UNINITILIZED;
    this.forceReload=true;
  }
}

export enum RtState {
  UNINITILIZED,
  NO_DATA,
  WAIT_FOR_GEOCODING,
  FILLED
}

export class CalTravel{
  state: number;
  deviceId : number;
  lastTime: Date;
  accumOdo: number;
  lastTfu :number;
  tow :number;
  activityInProgrss : boolean;
  fuelLevel : number;
  constructor(){
    this.state=0;
  }
}

export class RealTimeDisplay {
  idDevice: number;
  idVehicule: number;
  idGroup: number;
  groupName: String;
  matricule: String;
  position: String;
  detailPosition: String;
  speed: number;
  ignition: boolean;
  status: String;
  odometer: number;
  lasttime: Date;
}