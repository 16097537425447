import { Component, OnInit, Input } from '@angular/core';
import { DataManagementService } from '../../data-management/data-management.service';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { Vehicule } from 'src/app/model/data-managemnt';
import { SigninService } from 'src/app/signin/signin.service';

//let HighchartsMore = require('highcharts/highcharts-more');
//let HighchartsSolidGauge = require('highcharts/modules/solid-gauge');

//HighchartsMore(Highcharts);
//HighchartsSolidGauge(Highcharts)

@Component({
  selector: 'app-dashborad',
  templateUrl: './dashborad.component.html',
  styleUrls: ['./dashborad.component.scss'],
})
export class DashboradComponent implements OnInit {

  charged: boolean = false;

  //selectedV: any;
  marque: string;
  matricule: string;


  // Dashboard values
  @Input() valueRpm: number = 0;
  @Input() valueSpeed: number = 0;
  @Input() valueFuel: number = 0;
  @Input() valueTemp: number = 60;

  @Input() valueAccumOdo: number;
  @Input() valueFuelRate: number;
  @Input() valueTfu: number;

  fuelLitreValue: number;
  tow: number;
  dateTow: string;
  backButtonSubscription: any;
  constructor(public dataManagementService: DataManagementService, public modalController: ModalController, private platform: Platform,
    private popoverCtrl: PopoverController, public signinService: SigninService
  ) {
    this.marque = this.dataManagementService.selectedVehicule!.mark;
    this.matricule = this.dataManagementService.selectedVehicule!.matricule;
  }

  ngOnInit() {
    this.getDashboard(this.dataManagementService.selectedVehicule!);
  }

  ionViewWillEnter() {
    this.backButtonSubscription = this.platform.backButton.subscribe(async () => {
      navigator['app'].exitApp();
    });
  }
  ionViewDidLeave() {
    this.backButtonSubscription.unsubscribe();
  }

  async close() {
    await this.popoverCtrl.dismiss('cancel');
  }

  //Get RealTimeRecord data
  getDashboard(vehicule: Vehicule) {
    this.valueAccumOdo = vehicule.odo;
    this.valueTfu = vehicule.tfu;
    this.tow = vehicule.tow;
    this.dateTow = this.dataManagementService.toDuration(this.tow);
    this.fuelLitreValue = (vehicule.fuelLevel == -1) ? 0 : vehicule.fuelLevel * vehicule.device.carb.volume / 100;
    // this.dataManagementService.getOdoFromRecord(vehicule, vehicule.realTimeRecord.accum_odo);
    // console.log("vehicule.realTimeRecord",vehicule)
    if (vehicule.device.carb && vehicule.device.opt && vehicule.realTimeRecord) {
      this.valueSpeed = vehicule.realTimeRecord.speed;
      if (vehicule.device.opt.useFms != 0 || vehicule.device.opt.useJ1708 != 0) {
        if (vehicule.realTimeRecord.ignition) {
          this.valueTfu = vehicule.realTimeRecord.tfu;
          //Temp
          if (vehicule.realTimeRecord.temp_engine >= 60) {
            this.valueTemp = vehicule.realTimeRecord.temp_engine;
          } else {
            this.valueTemp = 60;
          }
          //RPM
          this.valueRpm = vehicule.realTimeRecord.rpm / 1000;
          //Fulerate
          this.valueFuelRate = vehicule.realTimeRecord.fuel_rate;
          //TFU

        }
        //Fuel FMS
        if (vehicule.device.carb.max - vehicule.device.carb.min > 0) {
          if (
            vehicule.realTimeRecord.fuel >= vehicule.device.carb.min &&
            vehicule.realTimeRecord.fuel <= vehicule.device.carb.max
          ) {
            this.valueFuel = Math.round((vehicule.realTimeRecord.fuel - vehicule.device.carb.min) / (vehicule.device.carb.max - vehicule.device.carb.min) * 100);
            //this.fuelLitreValue = this.valueFuel * vehicule.device.carb.volume / 100;
          }
        } else {
          if (
            vehicule.realTimeRecord.fuel >= vehicule.device.carb.max &&
            vehicule.realTimeRecord.fuel <= vehicule.device.carb.min
          ) {
            this.valueFuel = Math.round(
              (vehicule.realTimeRecord.fuel - vehicule.device.carb.min) /
              (vehicule.device.carb.max - vehicule.device.carb.min) *
              100
            );
            //this.fuelLitreValue = this.valueFuel * vehicule.device.carb.volume / 100;
          }
        }
      }
      if (vehicule.device.opt.useFuel) {
        if (vehicule.realTimeRecord.ignition) {
          //Anlog Fuel
          if (vehicule.device.carb.max - vehicule.device.carb.min > 0) {
            if (
              vehicule.realTimeRecord.fuel >= vehicule.device.carb.min &&
              vehicule.realTimeRecord.fuel <= vehicule.device.carb.max
            ) {
              this.valueFuel = Math.round(
                (vehicule.realTimeRecord.fuel - vehicule.device.carb.min) /
                (vehicule.device.carb.max - vehicule.device.carb.min) *
                100
              );
              //this.fuelLitreValue = this.valueFuel * vehicule.device.carb.volume / 100;
            }
          } else {
            if (
              vehicule.realTimeRecord.fuel >= vehicule.device.carb.max &&
              vehicule.realTimeRecord.fuel <= vehicule.device.carb.min
            ) {
              this.valueFuel = Math.round(
                (vehicule.realTimeRecord.fuel - vehicule.device.carb.min) /
                (vehicule.device.carb.max - vehicule.device.carb.min) *
                100
              );
              //this.fuelLitreValue = this.valueFuel * vehicule.device.carb.volume / 100;
            }
          }
        }
      }
    }
  }

}
