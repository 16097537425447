import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AlertNotificationComponent } from './pages/alert-management/alert-notification/alert-notification.component';
import { MileageComponent } from './pages/historical/mileage/mileage.component';
import { OverspeedComponent } from './pages/historical/overspeed/overspeed.component';
import { PathComponent } from './pages/historical/path/path.component';
import { StopComponent } from './pages/historical/stop/stop.component';
import { DashboradComponent } from './pages/realtime/dashborad/dashborad.component';
import { RealtimeComponent } from './pages/realtime/realtime.component';
import { SigninComponent } from './signin/signin.component';

const routes: Routes = [
  { path: '', redirectTo: '/signin', pathMatch: 'full' },
  {
    path: 'signin', component: SigninComponent
  },
  {
    path: 'client', component: RealtimeComponent,
  },
  {
    path: 'dashbord', component: DashboradComponent,
  },
  {
    path: 'notification-details', component:  AlertNotificationComponent,
  },
  {
    path: 'path', component: PathComponent,
  },
  {
    path: 'stop', component: StopComponent,
  },
  {
    path: 'mileage', component: MileageComponent,
  },
  {
    path: 'overspeed', component: OverspeedComponent,
  },
  {
    path: 'info-device',
    loadChildren: () => import('./pages/realtime/popup-rt/info-device/info-device.module').then( m => m.InfoDevicePageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
