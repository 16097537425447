import { Injectable, EventEmitter } from '@angular/core';
import { LatLngBounds, LatLng, DivIcon, Marker, Icon, FeatureGroup, Polygon, Polyline, Circle } from 'leaflet';
import { token_google, GlobalService, template, version } from '../../../global.config';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataManagementService } from '../../../pages/data-management/data-management.service';

import { PointInterest } from '../../../model/data-managemnt';
import { User } from '../../../signin/credentials';
import * as P from 'leaflet';
import 'leaflet.markercluster';
import "leaflet-draw";
import "leaflet.animatedmarker/src/AnimatedMarker";

import { PointInteretComponent } from 'src/app/pages/data-management/point-interet/point-interet.component';
import { PopoverController } from '@ionic/angular';
import { SigninService } from 'src/app/signin/signin.service';
import { RtMarker, PoiCluster } from "../marker"
import { DatePipe } from '@angular/common';

declare let L;


@Injectable()
export class MapService {
	public currentUser: User = new User();

	public map: P.Map;
	public baseMaps: any;

	public drawnItems: FeatureGroup;

	public mapLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

	public markerWasAdded: EventEmitter<any> = new EventEmitter<any>();
	public markerWasEdited: EventEmitter<any> = new EventEmitter<any>();

	public polygons: Polygon[] = [];
	public markers: Marker[] = [];
	public polylines: Polyline[] = [];
	public circles: Circle[] = [];
	public rtMarkers: RtMarker[] = [];
	public markersPoi: Marker[] = [];
	public polygonsPoi: Polygon[] = [];
	points: any[][] = new Array();
	markercluster = P.markerClusterGroup();
	poiCluster: PoiCluster = new PoiCluster();
	imagesDir = this.globalService.getimagesDir();
	imagesPOIDir = this.globalService.getimagesPOIDir();
	poisInMap = false;
	template: string;
	selectedMap: any;
	poi = new PointInterest();
	animatedMarker: any;
	simulation: boolean;
	showSimulation: boolean;
	replaySimulation: any;
	version: String = version;

	simulationContainer: any;
	simulationButton: any;
	bounds = []
	
	constructor(private globalService: GlobalService,
		private _http: HttpClient, private datePipe: DatePipe,
		private dataManagementService: DataManagementService,
		private popoverCtrl: PopoverController,
		private signinService: SigninService) {
		this.template = template;
	}

	createMap(container: string) {
		// this.selectedMap = localStorage.getItem("map");
		try {
			this.baseMaps = {
				Fleet: P.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
					maxZoom: 20,
					subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
				}),
				OpenStreetMap: P.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
					maxZoom: 20,
					maxNativeZoom: 17,
					attribution:
						'&copy; <a href="#">Fleet</a>, Tiles courtesy of <a href="http://hot.openstreetmap.org/" target="_blank">Humanitarian OpenStreetMap Team</a>'
				}),
				CartoDB: P.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
					maxZoom: 20,
					maxNativeZoom: 17,
					attribution:
						'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
				}),
				'Sat Full HD': P.tileLayer(
					'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
					{
						maxZoom: 20,
						maxNativeZoom: 17,
						attribution: 'Fleet'
					}
				),
				'Google Streets': P.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
					maxZoom: 20,
					subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
				}),
				'Google Hybrid': P.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
					maxZoom: 20,
					subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
				}),
				'Google Sat': P.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
					maxZoom: 20,
					subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
				}),
				'Google Terrain': P.tileLayer('https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
					maxZoom: 20,
					subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
				})
			};
		} catch (e) {
			console.log(e);
		}
	}

	init() {
		this.polygons = [];
		this.markers = [];
		this.polylines = [];
		this.circles = [];
		this.rtMarkers = [];
		this.markersPoi = [];
		this.polygonsPoi = [];
	}

	initMap(currentMap: any, container: string) {

		/*if(this.map){
			this.map.remove();
		}*/
		this.createMap(container);
		try {
			this.markercluster = P.markerClusterGroup({
				disableClusteringAtZoom: 16
			});
		} catch (e) {

		}
		this.initCluster();
		if (currentMap) {
			currentMap.remove();
		}

		let defaultLayer;
		this.selectedMap = localStorage.getItem("map");
		if (this.selectedMap)
			defaultLayer = this.baseMaps[this.selectedMap];
		if (!defaultLayer) {
			if (template == "mekatech") {
				defaultLayer = this.baseMaps.Fleet;
			} else if (template == "fleet") {
				defaultLayer = this.baseMaps.Fleet;
			} else {
				defaultLayer = this.baseMaps.OpenStreetMap;
			}
		}

		var lat = 34;
		var lon = 10;
		switch (version) {
			case "click":
				var lat = 46.750552;
				var lon = 3.176464;
				break;
			case "tchycotrack":
				var lat = 9.765;
				var lon = 4.048;
				break;

		}

		let optionsSpec: any = {
			zoomControl: false,
			attributionControl: false,
			center: P.latLng(lat, lon),
			zoom: 6,
			minZoom: 3,
			maxZoom: 20,
			maxNativeZoom: 17,
			layers: [defaultLayer]
		};

		let map = new P.Map(container, optionsSpec);

		P.control.layers(this.baseMaps, undefined, {
			position: 'topright'
		}).addTo(map);
		P.control.zoom({
			position: 'topleft'
		}).addTo(map);

		if (container == 'rtMap') {
			var drawnItems = new P.FeatureGroup();
			map.addLayer(drawnItems);
			if (this.signinService.isRootAdmin() || this.signinService.isHasRole('ROLE_POI')) {
				var icon = P.icon({
					iconUrl: this.globalService.imagesDir + 'map-marker.png',
					iconSize: [10, 10],
					iconAnchor: [10, 10],
				})
				var drawControl = new P.Control.Draw({
					draw: {
						polygon: false,
						circle: false,
						polyline: false,
						rectangle: false,
						circlemarker: false,
						marker: {
							icon: icon
						}

					},
					edit: {
						featureGroup: drawnItems,
						edit: false,
						remove: false
					},
				});
				map.addControl(drawControl);
			}


			map.on(P.Draw.Event.CREATED, (e: any) => {

				let latPoi = e.layer._latlng.lat
				let lngPoi = e.layer._latlng.lng
				this.addPoiPopover(latPoi, lngPoi)
			});
		}
		currentMap = map;
		this.map = currentMap;
		this.onMapReady(this.map);
		this.mapLoaded.emit(true);


		this.map.on("baselayerchange", function (e: any) {
			//alert('Changed to ' + e.name);
			localStorage.setItem("map", e.name);
		});

	}
	onMapReady(map: P.Map) {
		setTimeout(() => {
			map.invalidateSize();
		}, 100);
	}
	addRtMarker(rtMarker: RtMarker) {
		rtMarker.value.setIcon(rtMarker.icon);
		this.rtMarkers.push(rtMarker);
		rtMarker.value.addTo(this.map);
	}

	initCluster() {
		try {
			this.markercluster = P.markerClusterGroup({
				disableClusteringAtZoom: 16,
				zoomToBoundsOnClick: true,
				iconCreateFunction: function (cluster: any) {
					return new DivIcon({
						html: '<div style="background-color:rgba(77,77,77,0.5) ; color:white; font-weight:bold; "><span>' +
							cluster.getChildCount() +
							'</div><span>',
						className: 'marker-cluster'
					});
				}
			});
		} catch (e) {

		}
	}
	removePoiClusters() {
		Array.from(this.poiCluster.mapMarkerclusterPoi.values()).forEach((cluser: any) => {
			this.map.removeLayer(cluser);
		});
		this.poiCluster.mapMarkerclusterPoi.clear();
	}
	intPoiClusters() {
		this.poiCluster = new PoiCluster();
		for (let i = 0; i < this.dataManagementService.groupsPoi.length; i++) {

			if (this.dataManagementService.groupsPoi[i].markerUrl.indexOf("red_g") == 0) {
				this.poiCluster.mapMarkerclusterPoi.set(this.dataManagementService.groupsPoi[i].id, P.markerClusterGroup({
					iconCreateFunction: function (cluster: any) {
						return new DivIcon({
							html:
								"<div style='background-image:url(\"https://slama.fleet.tn/images/poi/blue_g.png\");background-size: 100% auto; padding-top: 3px; color:white; font-weight:bold'><span>" +
								cluster.getChildCount() +
								'</div><span>',
							className: 'marker-cluster'
						});
					}
				}));

			}
			if (this.dataManagementService.groupsPoi[i].markerUrl.indexOf("green_g") == 0) {
				this.poiCluster.mapMarkerclusterPoi.set(this.dataManagementService.groupsPoi[i].id, P.markerClusterGroup({
					iconCreateFunction: function (cluster: any) {
						return new DivIcon({
							html:
								"<div style='background-image:url(\"https://slama.fleet.tn/images/poi/green_g.png\");background-size: 100% auto; padding-top: 3px; color:white; font-weight:bold'><span>" +
								cluster.getChildCount() +
								'</div><span>',
							className: 'marker-cluster'
						});
					}
				}));
			}
			if (this.dataManagementService.groupsPoi[i].markerUrl.indexOf("aziza_g") == 0) {
				this.poiCluster.mapMarkerclusterPoi.set(this.dataManagementService.groupsPoi[i].id, P.markerClusterGroup({
					iconCreateFunction: function (cluster: any) {
						return new DivIcon({
							html:
								"<div style='background-image:url(\"https://slama.fleet.tn/images/poi/aziza_g.png\");background-size: 100% auto; padding-top: 3px; color:white; font-weight:bold'><span>" +
								cluster.getChildCount() +
								'</div><span>',
							className: 'marker-cluster'
						});
					}
				}));
			}
			if (this.dataManagementService.groupsPoi[i].markerUrl.indexOf("mg_g") == 0) {
				this.poiCluster.mapMarkerclusterPoi.set(this.dataManagementService.groupsPoi[i].id, P.markerClusterGroup({
					iconCreateFunction: function (cluster: any) {
						return new DivIcon({
							html:
								"<div style='background-image:url(\"https://slama.fleet.tn/images/poi/mg_g.png\");background-size: 100% auto; padding-top: 3px; color:white; font-weight:bold'><span>" +
								cluster.getChildCount() +
								'</div><span>',
							className: 'marker-cluster'
						});
					}
				}));
			}
			if (this.dataManagementService.groupsPoi[i].markerUrl.indexOf("mono_g") == 0) {
				this.poiCluster.mapMarkerclusterPoi.set(this.dataManagementService.groupsPoi[i].id, P.markerClusterGroup({
					iconCreateFunction: function (cluster: any) {
						return new DivIcon({
							html:
								"<div style='background-image:url(\"https://slama.fleet.tn/images/poi/mono_g.png\");background-size: 100% auto; padding-top: 3px; color:white; font-weight:bold'><span>" +
								cluster.getChildCount() +
								'</div><span>',
							className: 'marker-cluster'
						});
					}
				}));
			}
			if (this.dataManagementService.groupsPoi[i].markerUrl.indexOf("carrafour_g") == 0) {
				this.poiCluster.mapMarkerclusterPoi.set(this.dataManagementService.groupsPoi[i].id, P.markerClusterGroup({
					iconCreateFunction: function (cluster: any) {
						return new DivIcon({
							html:
								"<div style='background-image:url(\"https://slama.fleet.tn/images/poi/carrafour_g.png\");background-size: 100% auto; padding-top: 3px; color:white; font-weight:bold'><span>" +
								cluster.getChildCount() +
								'</div><span>',
							className: 'marker-cluster'
						});
					}
				}));
			}
		}
	}

	getRTMarker(id: number): RtMarker | null {
		var res = this.rtMarkers.filter((rtMarker) => rtMarker.id === id);
		if (res) {
			return res[0];
		}
		return null;
	}

	/*	updateRtMarker(rtMarker: RtMarker, isCurrentPathClicked: boolean, isFocus: boolean, isNewPoint: boolean) {
			let ok: boolean = false;
	
			this.rtMarkers.map((rt) => {
				if (rt.id == rtMarker.id) {
					if (isFocus && this.dataManagementService.selectedVehicule && this.dataManagementService.selectedVehicule.idDevice == rtMarker.id) {
	
						if (isCurrentPathClicked == true && isNewPoint) {
							let marker = new Marker(rt.value.getLatLng());
							marker.setIcon(
								new Icon({
									iconUrl: this.imagesDir + 'green-point.png',
									iconAnchor: [2, 2]
								})
							);
							marker.on('click', () => {
								this.map.setView(rt.value.getLatLng(), 17);
							});
							this.markers.push(marker);
							try {
								this.markercluster.addLayer(marker);
							} catch (e) {
	
							}
							let points: any[] = [];
							points.push(rt.value.getLatLng() as never);
							points.push(rtMarker.value.getLatLng() as never);
							let polyline = new Polyline(points, { color: '#0031D9', weight: 3 });
							this.addPolyline(polyline);
						}
						rt.value.setLatLng(rtMarker.value.getLatLng());
						rt.value.getPopup().setContent(rtMarker.value.getPopup().getContent());
						rt.value.options.rotationAngle = rtMarker.angle;
						rt.value.setIcon(rtMarker.icon);
						ok = true;
					}
					if (isFocus && this.dataManagementService.selectedVehicule && this.dataManagementService.selectedVehicule.idDevice == rtMarker.id) {
						this.map.setView(rtMarker.value.getLatLng(), 16);
					}
	
				}
			});
			if (!ok) {
	
				//console.log("not fount "+rtMarker.id);
				rtMarker.value.setIcon(rtMarker.icon);
				this.rtMarkers.push(rtMarker);
				try {
					this.markercluster.addLayer(rtMarker.value);
				} catch (e) {
	
				}
			}
			try {
				this.map.addLayer(this.markercluster);
			} catch (e) {
	
			}
		}*/

	updateRtMarker(rtMarker: RtMarker, isCurrentPathClicked: boolean) {
		let ok: boolean = false;

		this.rtMarkers.map((rt) => {
			if (rt.id == rtMarker.id) {
				//console.log("fount "+rtMarker.id);
				if (isCurrentPathClicked == true) {
					let marker = new Marker(rt.value.getLatLng());
					marker.setIcon(
						new Icon({
							iconUrl: this.imagesDir + 'green-point.png',
							iconAnchor: [2, 2]
						})
					);
					marker.on('click', () => {
						this.map.setView(rt.value.getLatLng(), 17);
					});
					this.markers.push(marker);
					try {
						this.markercluster.addLayer(marker);
					} catch (e) {

					}
					let points: any[] = [];
					points.push(rt.value.getLatLng() as never);
					points.push(rtMarker.value.getLatLng() as never);
					let polyline = new Polyline(points, { color: '#0031D9', weight: 3 });
					this.addPolyline(polyline);
				}
				rt.value.setLatLng(rtMarker.value.getLatLng());
				rt.value.getPopup().setContent(rtMarker.value.getPopup().getContent());

				rt.value.options.rotationAngle = rtMarker.angle;
				rt.value.setIcon(rtMarker.icon);
				ok = true;
			}
		});
		if (!ok) {
			rtMarker.value.setIcon(rtMarker.icon);
			this.rtMarkers.push(rtMarker);
			try {
				this.markercluster.addLayer(rtMarker.value);
			} catch (e) {

			}
		}
		try {
			this.map.addLayer(this.markercluster);
		} catch (e) {

		}
	}




	removeAllRtMarkers() {
		this.rtMarkers.forEach((m) => {
			this.map.removeLayer(m.value);
		});
		this.rtMarkers = [];
	}

	setView(coordinate: any) {
		this.map.setView(coordinate, 15);
	}

	addCircle(circle: Circle) {
		this.circles.push(circle);
		circle.addTo(this.map);
	} *

		removeCirclesFromMap() {
		this.circles.forEach((m) => {
			this.map.removeLayer(m);
		});
		this.circles = [];
	}

	addMarker(marker: Marker) {
		this.markers.push(marker);
		marker.addTo(this.map);
	}

	addMarkerPoi(marker: Marker, idGroupPoi: number) {
		this.markersPoi.push(marker);
		if (!idGroupPoi || idGroupPoi == 0)
			marker.addTo(this.map);
		else {
			this.poiCluster.mapMarkerclusterPoi.get(idGroupPoi).addLayer(marker);
			this.map.addLayer(this.poiCluster.mapMarkerclusterPoi.get(idGroupPoi));
		}
	}


	removeMarkersPoiFromMap() {
		this.markersPoi.forEach((m) => {
			this.map.removeLayer(m);
		});
		this.markersPoi = [];
	}

	removeMarker(index: number) {
		this.map.removeLayer(this.markers[index]);
		delete this.markers[index];
	}

	removeMarkerByName(markerName: any) {
		let index = this.markers.indexOf(markerName)
		this.map.removeLayer(this.markers[index]);
		delete this.markers[index];
	}

	addMarkersToMap() {
		this.markers.forEach((marker) => {
			marker.addTo(this.map);
		});
	}

	removeMarkersFromMap() {
		this.markers.forEach((m) => {
			this.map.removeLayer(m);
		});
		this.markers = [];
	}

	addPolyline(polyline: Polyline | any) {
		this.polylines.push(polyline);
		polyline.addTo(this.map);
	}

	removePolylinesFromMap() {
		this.polylines.forEach((m) => {
			this.map.removeLayer(m);
		});
		this.polylines = [];
	}

	addPolygon(polygon: Polygon) {
		this.polygons.push(polygon);
		polygon.addTo(this.map);
	}

	addPolygonPoi(polygon: Polygon) {
		this.polygonsPoi.push(polygon);
		polygon.addTo(this.map);
	}

	removePolygon(index: number) {
		delete this.polygons[index];
	}

	addPolygonsToMap() {
		this.polygons.forEach((Polygon) => {
			Polygon.addTo(this.map);
		});
	}

	removePolygonsFromMap() {
		this.polygons.forEach((m) => {
			this.map.removeLayer(m);
		});
		this.polygons = [];
	}

	removePolygonsPoiFromMap() {
		this.polygonsPoi.forEach((m) => {
			this.map.removeLayer(m);
		});
		this.polygonsPoi = [];
	}

	resetMap() {
		this.removeMarkersFromMap();
		this.removePolygonsFromMap();
		this.removePolylinesFromMap();
	}

	disableMouseEvent(elementId: string) {
		let element = <HTMLElement>document.getElementById(elementId);
		//P.DomEvent.disableClickPropagation(element);
		//P.DomEvent.disableScrollPropagation(element);
	}

	centerMap(arr: any, maxZoom: number) {
		var maxlat = -Infinity,
			minlat = +Infinity,
			maxlng = -Infinity,
			minlng = +Infinity;

		if (arr.length > 0) {
			arr.forEach((rt: any) => {
				if (rt.coordinate.lat != 0 && rt.coordinate.lng != 0) {
					if (maxlat < rt.coordinate.lat) {
						maxlat = rt.coordinate.lat;
					}
					if (minlat > rt.coordinate.lat) {
						minlat = rt.coordinate.lat;
					}
					if (maxlng < rt.coordinate.lng) {
						maxlng = rt.coordinate.lng;
					}
					if (minlng > rt.coordinate.lng) {
						minlng = rt.coordinate.lng;
					}
				}
			});
			var center1 = new LatLng(maxlat, maxlng),
				center2 = new LatLng(minlat, minlng);
			var bounds = new LatLngBounds(center1, center2);
			this.map.fitBounds(bounds, { padding: [80, 80], maxZoom: maxZoom });
		}
	}

	offsetMap(arr: any, zoom: number, x: number, y: number) {
		this.map.setZoom(zoom);
		var centerPoint = this.map.latLngToContainerPoint([arr.lat, arr.lng]);
		var newPoint = new L.Point([centerPoint.x + x, centerPoint.y + y]);
		var targetLatLng = this.map.containerPointToLatLng(newPoint);
		this.map.panTo(targetLatLng);
	}

	getPathBetweenTwoPoints(): Observable<any> {
		let headers = new HttpHeaders({ Accept: 'application/json' });

		headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
		// Request methods you wish to allow
		headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');

		// Request headers you wish to allow
		headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');

		headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

		// Set to true if you need the website to include cookies in the requests sent
		// to the API (e.g. in case you use sessions)
		headers.append('Access-Control-Allow-Credentials', 'true');

		return this._http.get(
			'https://maps.googleapis.com/maps/api/directions/json?origin=Disneyland&destination=Universal+Studios+Hollywood4&key=' +
			token_google,
			{ headers: headers }
		);
	}

	plotActivity() {
		var demoTracks = {
			type: 'Feature',
			geometry: {
				type: 'MultiPoint',
				coordinates: []
			},
			properties: {
				time: []
			}
		};

	}
	displayPointPolygon(pointInterest: PointInterest, idGroupPoi: number) {
		this.removeMarkersFromMap();
		this.removeMarkersPoiFromMap();
		this.removePolygonsPoiFromMap();

		let popup =
			"<span class='leaflet-pelias-layer-icon-container'><div class='leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point' title='layer: venue'></div></span> Nom : <strong>" +
			pointInterest.name +
			"</strong><br><hr><b>Adresse : " +
			pointInterest.address +
			"</b>";
		let marker: any;

		if (pointInterest.type == 'POLYGON') {
			let polygon = new Polygon(pointInterest.decode);
			this.addPolygonPoi(polygon);
		}

		marker = new Marker(pointInterest.coordinate);

		marker.on('click', () => {
			this.map.setView(pointInterest.coordinate, 17);
		});

		marker.on('mouseover', () => {
			marker.openPopup();
		});

		marker.on('add', () => {
			marker.openPopup();
		});

		marker.bindPopup(popup);
		marker.openPopup();
		marker.setIcon(
			new Icon({
				iconUrl: this.imagesPOIDir + pointInterest.imageUri,
				iconAnchor: [-2, 10],
				popupAnchor: [10, -25]
			})
		);
		this.addMarkerPoi(marker, idGroupPoi);
		var mapCenterMarker = new LatLng(
			pointInterest.coordinate.lat + 0.05,
			pointInterest.coordinate.lng
		);
		var mapCenterPolygin = new LatLng(
			pointInterest.coordinate.lat + 0.001,
			pointInterest.coordinate.lng
		);

		if (pointInterest.type == 'POLYGON') {
			this.map.setView(mapCenterPolygin, 17);
		} else {
			this.map.setView(mapCenterMarker, 12);
		}
	}


	drawPointInterests() {
		this.removeMarkersPoiFromMap();
		this.removePolygonsPoiFromMap();
		this.removeCirclesFromMap();
		this.removePoiClusters();

		if (this.poisInMap) {
			this.poisInMap = false;
		} else {
			this.intPoiClusters();
			for (let i = 0; i < this.dataManagementService.groupsPoi.length; i++) {
				for (let j = 0; j < this.dataManagementService.groupsPoi[i].pointInterests.length; j++) {
					try {
						this.drawPointInterest(this.dataManagementService.groupsPoi[i].pointInterests[j], this.dataManagementService.groupsPoi[i].id);
					} catch (e) { }
				}
			}
			try {
				for (let k = 0; k < this.dataManagementService.pointInterests.length; k++) {
					let found = false;
					for (let i = 0; i < this.dataManagementService.groupsPoi.length && !found; i++) {
						for (let j = 0; j < this.dataManagementService.groupsPoi[i].pointInterests.length && !found; j++) {
							if (this.dataManagementService.groupsPoi[i].pointInterests[j].idPointInterest == this.dataManagementService.pointInterests[k].idPointInterest) {
								found = true;
							}
						}
					}
					if (!found)
						this.drawPointInterest(this.dataManagementService.pointInterests[k], 0);
				}
				this.poisInMap = true;
			} catch (e) {
				console.log(e);
			}
		}
	}

	drawPointInterest(pointInterest: PointInterest, idGroupPoi: number) {
		let popup =
			"<span class='leaflet-pelias-layer-icon-container'><div class='leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point' title='layer: venue'></div></span> Nom : <strong>" +
			pointInterest.name +
			'</strong><br><hr><b>Adresse : ' +
			pointInterest.address +
			'</b>';
		let marker: any;

		if (pointInterest.type == 'POLYGON') {
			//console.log(pointInterest.decode);
			let polygon = new Polygon(pointInterest.decode);
			//this.mapService.addPolygon(polygon);
			this.addPolygonPoi(polygon);
		}

		marker = new Marker(pointInterest.coordinate);
		marker.on('click', () => {
			this.map.setView(pointInterest.coordinate, 17);
		});

		marker.on('mouseover', () => {
			marker.openPopup();
		});

		marker.bindPopup(popup);
		marker.setIcon(
			new Icon({
				iconUrl: this.imagesPOIDir + pointInterest.imageUri,
				iconAnchor: [15, 30],
				popupAnchor: [10, -25],
				iconSize: [30, 30]
			})
		);

		this.addMarkerPoi(marker, idGroupPoi);
	}

	decode(points: any[]): string {
		let temp = '';
		points.forEach((point, i) => {
			if (i != points.length) temp += point.lat + ',' + point.lng + ';';
			else temp += point.lat + ',' + point.lng;
		});
		return temp;
	}

	centerMapMarker(lat: any, lng: any) {
		this.map.panTo(new P.LatLng(lat, lng));
	}

	addPolylineDecorator(angleMarker: any) {
		this.map.addLayer(angleMarker);
	}

	removePolylineDecorator(angleMarker: any) {
		this.map.removeLayer(angleMarker);
	}


	async addPoiPopover(lat: any, lng: any) {
		const popover = await this.popoverCtrl.create({
			component: PointInteretComponent,
			cssClass: 'addPoiPopover',
			componentProps: {
				latPoi: lat,
				lngPoi: lng
			}

		});

		await popover.present();
	}


	fitBounds(bounds: any) {
		this.map.fitBounds(bounds, { padding: [50, 50] });
	}


	makeAnimatedMarker(line, trajetsPoints, iconName) {
		var simulationIcon = P.icon({
			iconUrl: 'assets/img/' + iconName + '.png',
			iconSize: [40, 40],
			iconAnchor: [20, 40],
			popupAnchor: [0, -30],
		});
		this.animatedMarker = L.animatedMarker(line.getLatLngs(),
			{
				icon: simulationIcon,
				distance: 1000,  // meters
				interval: 1000, // milliseconds
				autoStart: false,
				onEnd: () => {
					this.map.removeLayer(this.animatedMarker);
					this.simulation = false;
					this.showSimulation = false
					this.makeAnimatedMarker(line, trajetsPoints, iconName)
				}
			})

		var popup = P.popup({
			keepInView: false,
		})
		this.animatedMarker.on('move', (e) => {
			var markerLat = e.target._latlng.lat
			var markerLon = e.target._latlng.lng
			var date: any;
			var vitesse: any;
			for (let i = 0; i < trajetsPoints.length; i++) {
				if (trajetsPoints[i].lng == markerLon && trajetsPoints[i].lat == markerLat) {
					vitesse = trajetsPoints[i].speed
					date = this.datePipe.transform(trajetsPoints[i].date, "dd/MM/yyyy HH:mm:ss", 'en-US')

				}
			}
			console.log("animatedMarker")
			popup.setContent('<b>Date:</b> ' + date + '<br> <b>Vitesse:</b> ' + vitesse + ' km/h')
		})
		this.animatedMarker.bindPopup(popup)
	}


}
