import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Vehicule } from 'src/app/model/data-managemnt';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { DataManagementService } from '../../data-management/data-management.service';
import * as L from 'leaflet';
import Marker = L.Marker;
import Icon = L.Icon;
import { PopupService } from './popup.service';
import { SigninService } from 'src/app/signin/signin.service';
import { DashboradComponent } from '../dashborad/dashborad.component';
import { ModalController, PopoverController } from '@ionic/angular';
import { InfoDevicePage } from './info-device/info-device.page';

@Component({
  selector: 'app-popup-rt',
  templateUrl: './popup-rt.component.html',
  styleUrls: ['./popup-rt.component.scss'],
})
export class PopupRtComponent implements OnInit {
  vehicule: Vehicule;
  translations: any;
  langChanged: boolean;
  date: Date;
  signal: number;
  kilometrage: number;
  etat: any;
  isHidden: boolean = true;

  constructor(public dataManagementService: DataManagementService, private modalController: ModalController,
    public signinService: SigninService,
    public translate: TranslateService,
    private mapService: MapService,
    private popupService: PopupService,
    private popoverCtrl: PopoverController) {

  }
  ngOnInit() { }

  loadVehicule(vehicule: Vehicule, etat: any, translations: any, marker: any ,isDetails :boolean ) {
    this.vehicule = vehicule;
    this.date = new Date(vehicule.realTimeRecord.recordTime);
    this.signal = Math.round((Number(vehicule.realTimeRecord.signal) / 32 * 100))
    this.kilometrage = Math.round(vehicule.odo)
    this.etat = etat
    this.translations = translations
    this.popupService.marker = marker;
    this.isHidden = !isDetails;
  }

  formatDate(date: any) {
    let minutes = date.getMinutes() + "";
    if (minutes.length === 1) {
      minutes = "0" + date.getMinutes();
    }
    let month = (date.getMonth() + 1);
    if (month < 10) {
      month = "0" + month;
    }

    let day = date.getDate() + "";
    if (day.length === 1) {
      day = "0" + date.getDate();
    }

    let hour = date.getHours() + "";
    if (hour.length === 1) {
      hour = "0" + date.getHours();
    }

    return day +
      "/" +
      month +
      "/" +
      date.getFullYear() +
      " " +
      hour +
      ":" +
      minutes
  }

  voirHistorique() {
    this.popupService.voirHistorique()
  }

  displayTodaysMileage(vehicule: Vehicule) {
    this.popupService.displayTodaysMileage(vehicule)
  }

  arreterDemarrer(ev: any, vehicule: any) {
    this.popupService.arreterDemarrer(ev, vehicule)
  }

  async openDashborad() {
    const modalDash = await this.popoverCtrl.create({
      component: DashboradComponent,
      cssClass: 'my-custom-class'
    });

    return await modalDash.present();
  }


  displayCurrentPath(deviceId: number) {
    if (this.popupService.getVehicule(this.vehicule.idDevice)[0].realTimeRecord.ignition == true) {
      this.popupService.displayCurrentPath(deviceId);
      let lat = this.popupService.getVehicule(this.vehicule.idDevice)[0].realTimeRecord.coordinate.lat
      let lng = this.popupService.getVehicule(this.vehicule.idDevice)[0].realTimeRecord.coordinate.lng
      this.mapService.centerMapMarker(lat, lng);
    }

  }


  initTimerCurrentPath() {
    clearTimeout(this.popupService.liveReloadingRT);
    let idDevice = this.popupService.getVehicule(this.vehicule.idDevice)[0].idDevice
    this.displayCurrentPath(idDevice);
    this.popupService.liveReloadingRT = setInterval(() => {
      if (this.popupService.hideCurrentPath == false) {
        this.displayCurrentPath(idDevice);
      }
    }, 20000);

  }

  drawpath() {
    this.popupService.drawpath(this.vehicule, this.popupService.currentlocationlat, this.popupService.currentlocationlng)
  }

  currentPosition() {
    this.popupService.currentPosition(this.vehicule, this.popupService.currentlocationlat, this.popupService.currentlocationlng)
  }

  async openInfoDevice(ev: any, vehic: any) {
    let vehicule = vehic.idDevice;
    let group = this.dataManagementService.selectedGroup;
    let selectedV = { "group": group, "vehicule": vehicule };

    let popover = await this.popoverCtrl.create({
      component: InfoDevicePage,
      event: ev,
      translucent: false,
      cssClass: 'details'
    });
    return await popover.present();
  }

}