import { Component, OnInit } from '@angular/core';
import { DataManagementService } from 'src/app/pages/data-management/data-management.service';

@Component({
  selector: 'app-info-device',
  templateUrl: './info-device.page.html',
  styleUrls: ['./info-device.page.scss'],
})
export class InfoDevicePage implements OnInit {

  constructor(public dataManagementService: DataManagementService) { }

  ngOnInit() {
    
  }

}
