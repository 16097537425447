import { Injectable, EventEmitter } from '@angular/core';
import { Vehicule, Driver, PointInterest, Group, GroupPOI } from '../../model/data-managemnt';
import { createAuthorizationHeader } from '../../utils/headers';
import { Subscription, Subject } from "rxjs";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/global.config';
import { RealTimeRecord } from 'src/app/model/real-time';
import { Events } from 'src/app/utils/Events';
import { User } from 'src/app/signin/credentials';
import { Router } from '@angular/router';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';

@Injectable({
  providedIn: 'root'
})
export class DataManagementService {

  public currentUser: User | null;
  pointInterests: PointInterest[] = [];
  groups: Group[] = [];
  vehicules: Vehicule[] = [];
  selectedGroup: Group | null;
  selectedVehicule: Vehicule | null;
  selectedGroup$ = new Subject<Group>();
  selectedVehicule$ = new Subject<Vehicule>();
  groupsPoi: GroupPOI[] = Array();

  loading: boolean;
  public GroupsLoaded$: EventEmitter<Group[]> = new EventEmitter();
  public UpdateMarker$: EventEmitter<boolean> = new EventEmitter();
  allGroups: Subscription;

  constructor(private _http: HttpClient, public events: Events, public router: Router,
    private globalService: GlobalService, private geocodingService: GeocodingService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  }

  init() {
    this.loadPoi();
    if (this.groups && this.groups.length > 0) {
      if (!this.selectedGroup) {
        this.selectedGroup = this.groups[0];
        if (this.selectedGroup.vehicules) {
          if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
            this.selectedVehicule = this.selectedGroup.vehicules[0];
          }
        }
      }
      else if (!this.selectedVehicule) {
        this.selectedVehicule = this.vehicules[0];
      }
    } else {
      if (localStorage.getItem('groups') != null) {
        this.groups = JSON.parse(localStorage.getItem('groups') || '{}');

        this.GroupsLoaded$.emit(this.groups);
      }

      this.allGroups = this.getAllGroupsDetails('')
        .subscribe(groupes => {
          this.getCalTravelsData();
          if (groupes.length > 0 && groupes) {
            let emit = 0;
            if (this.groups != groupes) {
              this.groups = groupes;
              emit = 1;
            }
            localStorage.setItem('groups', JSON.stringify(this.groups));
            if (emit)
              this.GroupsLoaded$.emit(this.groups);
          }
        }
          , err => {
            if (err.status === 401) {
              this.currentUser = null;
              localStorage.removeItem('currentUser');
              localStorage.removeItem('token');
              localStorage.removeItem("groups");
              localStorage.removeItem("pointInterests");
              this.router.navigate(['/signin']);
            }
          }
        );
    }
  }

  getCalTravelsData() {
    // if (!this.isGroupLoaded)
    // return;
    this.getCalTravelsWithOptions()
      .subscribe(calTravels => {
        calTravels.forEach(caltravel => {
          let vehicules: Vehicule[];
          vehicules = this.getVehicules(caltravel.deviceId, false);
          if (vehicules && vehicules.length) {
            for (let v of vehicules) {
              //check calTravel changes
              let displayName = this.getVehiculeName(v);

              v.calTravel = caltravel;
              v.odo = v.odoOffset + caltravel.accumOdo;
              v.tfu = caltravel.lastTfu / 2;
              v.tow = caltravel.tow;
              v.fuelLevel = +caltravel.fuelLevel;
            }
          }
        });

      });
  }

  getOdoFromRecord(vehicule: Vehicule, accumodo: number) {
    return Math.floor((accumodo + vehicule.odoOffset) * 100) / 100;
  }

  /**
 * return surnom(if exist) si non retunr matricule !
 * */
  getVehiculeName(vehicule: Vehicule) {
    if (vehicule) {
      if (vehicule.matricule) {
        return vehicule.matricule;
      } else if (vehicule.alias) {
        return vehicule.alias;
      }
    }
    return null;
  }
  getCalTravels(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(this.globalService.dns + 'vehicules/calTravels', { headers: headers });
  }

  getCalTravelsWithOptions(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(this.globalService.dns + 'vehicules/calTravelswithOption', { headers: headers });
  }



  getVehicules(idDevice: number, isReport: boolean): Vehicule[] {
    let foundVehicule = [];
    if (!this.groups)
      return [];
    for (let i = 0; i < this.groups.length; i++) {
      if (!isReport && this.groups[i].reportIsInvisible)
        continue;
      if (this.groups[i].vehicules) {
        for (let j = 0; j < this.groups[i].vehicules.length; j++) {
          if (this.groups[i].vehicules[j].device !== null) {
            if (this.groups[i].vehicules[j].device.idDevice === idDevice) {
              foundVehicule.push(this.groups[i].vehicules[j] as never);
            }
          }
        }
      }
    }
    return foundVehicule;

  }
  /**
 * return nom complet du chauffeur
 * */
  getDriverName(driver: Driver) {
    if (driver) {
      if (driver.firstName && driver.lastName) {
        return driver.firstName + ' ' + driver.lastName;
      } else if (driver.firstName) {
        return driver.firstName;
      } else if (driver.lastName) {
        return driver.lastName;
      } else return 'anonyme';
    } else return '--';
  }



  getVehiculeById(idVehicule: number): Vehicule {
    let foundVehicule = new Vehicule();
    if (this.groups) {
      for (let i = 0; i < this.groups.length; i++) {
        if (this.groups[i].vehicules) {
          for (let j = 0; j < this.groups[i].vehicules.length; j++) {
            if (this.groups[i].vehicules[j].idVehicule === idVehicule) {
              foundVehicule = this.groups[i].vehicules[j];
              break;
            }
          }
        }
      }
    }
    return foundVehicule;
  }

  // must exe only if pointInterests not NULL !!!
  fillRtWithRelativePosition(rt: RealTimeRecord, lat: number, lng: number) {
    rt.relativePosition;
    rt.geocoding;
    let mindistance = 1000000;
    if (!this.pointInterests)
      return;
    this.pointInterests.forEach(pointInterest => {
      let distance = this.distance(
        lat,
        lng,
        pointInterest.coordinate.lat,
        pointInterest.coordinate.lng
      );
      if (distance < mindistance) {
        if (distance <= pointInterest.ray / 1000) {
          rt.relativePosition = pointInterest.name;
          rt.geocoding = pointInterest.address;
        } else if (distance > pointInterest.ray / 1000 && distance < 0.2) {
          let distanceStr = distance.toString().substr(0, 4);
          rt.relativePosition = 'à ' + distanceStr + ' Km de ' + pointInterest.name;
          rt.geocoding = pointInterest.address;
        }
        mindistance = distance;
      }

    });
  }

  // must exe only if pointInterests not NULL !!!
  getRelativePosition(lat: number | null, lng: number | null): string | null {
    if (!this.pointInterests)
      return null;
    
    let relativePosition = '';
    let mindistance = 1000000;
   
    this.pointInterests.forEach(pointInterest => {
      let distance = this.distance(
        lat,
        lng,
        pointInterest.coordinate.lat,
        pointInterest.coordinate.lng
      );
      if (distance < mindistance) {
        if (distance <= pointInterest.ray / 1000) {
          relativePosition = pointInterest.name;
        } else if (distance > pointInterest.ray / 1000 && distance < 0.2) {
          let distanceStr = distance.toString().substr(0, 4);
          relativePosition = 'à ' + distanceStr + ' Km de ' + pointInterest.name;
        }
        mindistance = distance;
      }

    });
    if(relativePosition=='')
      return null;
    return relativePosition;
  }
  distance(lat1: any, lon1: any, lat2: any, lon2: any) {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    var distance = 12742 * Math.asin(Math.sqrt(a));
    return distance;
  }

  getAllGroupsDetails(keyword: string): Observable<any> {
    let headers = createAuthorizationHeader();
    let parms = '';

    /*let currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    if (!currentUser.isRoot) {
      parms = '&id=' + currentUser.id;
    }*/
    return this._http
      .get(this.globalService.dns + 'groupes/details?keyword=' + keyword + parms, {
        headers: headers
      });
  }
  getAllGroups(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(this.globalService.dns + 'groupes/minify', { headers: headers });
  }

  loadAllGroups() {
    this.getAllGroupsDetails('').subscribe(groups => {
      this.groups = groups;
      if (this.groups.length > 0 && this.groups) {
        /*this.selectedGroup = this.groups[0];
        if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }*/
      }
    });
  }

  // valid
  getOne(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(this.globalService.dns + "vehicules/" + id, { headers: headers });
  }

  /*** vehicules service */
  getAllVehicules(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(this.globalService.dns + 'vehicules/minify', { headers: headers });
  }

  getAllPointInterests(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(this.globalService.dns + 'pointInterests', { headers: headers });
  }

  getGroupsPoi(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(this.globalService.dns + 'groupPointInterset', { headers: headers });
  }


  loadPoi() {
    if (this.pointInterests != null) {
      if (localStorage.getItem('pointInterests') != null)
        this.pointInterests = JSON.parse(localStorage.getItem('pointInterests') || '{}');
      this.getAllPointInterests()
        .subscribe(pointInterests => {
          this.pointInterests = pointInterests;
          localStorage.setItem('pointInterests', JSON.stringify(this.pointInterests));
        });
    }

  }

  n2(n: number) {
    return n > 9 ? "" + n : "0" + n;
  }

  toDuration(n: number) {
    return this.n2(Math.floor(n / 3600)) + ":" + this.n2(Math.floor(n % 3600 / 60)) + ":" + this.n2(Math.floor(n % 60));
  }

  filterVehicules(term) {
    if (!term)
      return null;
    let searchTerm: string = term.toString();
    if (searchTerm != null)
      return this.getAllVehiculesFromGroups().filter(item => {
        return item.matricule.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) > -1;
      });
    return null;
  }
  getAllVehiculesFromGroups(): Vehicule[] {
    let vs: Vehicule[] = [];
    this.groups.forEach(g => {
      g.vehicules.forEach(v => {
        vs.push(v);
      });
    });
    return vs;
  }

  distanceFun(lat1: any, lon1: any, lat2: any, lon2: any) {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    var distance = 12742 * Math.asin(Math.sqrt(a));
    return distance;
  }



  getGroupByVehicule(idVehicule: number): Group {
    let foundGroup = new Group();
    if (this.groups) {
      for (let i = 0; i < this.groups.length; i++) {
        for (let j = 0; j < this.groups[i].vehicules.length; j++) {
          if (this.groups[i].vehicules[j].idVehicule === idVehicule) {
            foundGroup = this.groups[i];
            break;
          }
        }
      }
    }
    return foundGroup;
  }

  getGeocodingPosition(object: any, type: string, id_device: number) {
    let lat;
    let lon;

    if (type == "TechnicalData") {
      lat = object.latitude;
      lon = object.longitude;
    }
    if (type == "STOP") {
      lat = object.stopLatitude;
      lon = object.stopLongitude;
    }
    if (type == "RT") {
      lat = object.coordinate.lat;
      lon = object.coordinate.lng;
    }
    let ret = this.getRelativePosition(lat, lon);

    if (ret != null) {
      if (type == "TechnicalData" || type == "STOP")
        object.address = ret;

      if (type == "RT") {
        object.geocoding = ret;
        object.geocodingDetails = ret;
      }
      
      return;
    }



    if (type == "RT" || type == "STOP") {
      //console.log(object.address);
      if (object.address != null) {
        try {
          let add = JSON.parse(object.address);

          object.geocoding = add.gc;
          object.geocodingDetails = add.gc;
          return;
        } catch (e) { }
      }
    }
    if (object.address == null) {
      this.geocodingService
        .inverseGeocondinPromise(
          lat,
          lon,
          17
        )
        .then(
          adress => {
            let ad = this.geocodingService.proccessingNomitamAddress(
              adress
            );
            //console.log("add=>" + ad);

            if (type == "TechnicalData" || type == "STOP")
              object.address = ad;

            if (type == "RT") {
              object.geocoding = this.geocodingService.proccessingNomitamAddress(adress)
              let tmp: any = adress;
              object.geocodingDetails = tmp.display_name;

              let add: any = {
                gc: "",
                gcd: ""
              };
              add.gc = object.geocoding;
              add.gcd = object.geocodingDetails;
              this.updateRtAddress(id_device, JSON.stringify(add)).subscribe(res => {
                //console.log(res);
              });

            }
          },
          err => {

            if (type == "TechnicalData" || type == "STOP")
              object.address = "";
            if (type == "RT") {
              object.geocoding = "";
              object.geocodingDetails = "";
            }
          }
        );

    } else {

      let add = JSON.parse(object.address);

      if (add != null) {
        object.geocoding = add.gc;
        object.geocodingDetails = add.gc;
      }
    }
  }


  updateRtAddress(id_device, address: string) {
    let headers = createAuthorizationHeader();
    return this._http
      .post(this.globalService.dns + "realTimeRecords/rt/" + id_device, address, { headers: headers });
  }

}
