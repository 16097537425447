import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { GlobalService } from 'src/app/global.config';
import { PointInterest } from 'src/app/model/data-managemnt';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { LoadingService } from 'src/app/utils/loading/loadingService';
import { DataManagementService } from '../data-management.service';
import { PointInteretService } from './point-interet.service';

@Component({
  selector: 'app-point-interet',
  templateUrl: './point-interet.component.html',
  styleUrls: ['./point-interet.component.scss'],
})
export class PointInteretComponent implements OnInit {

  @Input() latPoi
  @Input() lngPoi
  @Input() adresse

  poi:PointInterest=new PointInterest();
  imagesPOIDir = this.globalService.getimagesPOIDir();

  typePois = [
  {"name" : "marker1", "imageUri" : "marker1.png","imagesrc" :this.imagesPOIDir+"marker1.png"},
  {"name" : "marker2","imageUri" : "marker2.png","imagesrc" :this.imagesPOIDir+"marker2.png"},
  {"name" : "marker3","imageUri" : "marker3.png","imagesrc" :this.imagesPOIDir+"marker3.png"},
  {"name" : "marker4","imageUri" : "marker4.png","imagesrc" :this.imagesPOIDir+"marker4.png"},
  {"name" : "marker5","imageUri" : "marker5.png","imagesrc" :this.imagesPOIDir+"marker5.png"},
  {"name" : "marker6","imageUri" : "marker6.png","imagesrc" :this.imagesPOIDir+"marker6.png"},
  {"name" : "marker7","imageUri" : "marker7.png","imagesrc" :this.imagesPOIDir+"marker7.png"},
  {"name" : "marker8","imageUri" : "marker8.png","imagesrc" :this.imagesPOIDir+"marker8.png"},
  {"name" : "marker9","imageUri" : "marker9.png","imagesrc" :this.imagesPOIDir+"marker9.png"},
  {"name" : "marker10","imageUri" : "marker10.png","imagesrc" :this.imagesPOIDir+"marker10.png"},
  {"name" : "marker11","imageUri" : "marker11.png","imagesrc" :this.imagesPOIDir+"marker11.png"},
  {"name" : "flag1","imageUri" : "flag1.png","imagesrc" :this.imagesPOIDir+"flag1.png"},
  {"name" : "flag2","imageUri" : "flag2.png","imagesrc" :this.imagesPOIDir+"flag2.png"},
  {"name" : "house1","imageUri" : "house1.png","imagesrc" :this.imagesPOIDir+"house1.png"},
  {"name" : "house2","imageUri" : "house2.png","imagesrc" :this.imagesPOIDir+"house2.png"},
    ];

  constructor(private poiService:PointInteretService,
              private popoverController:PopoverController,
              public geocodingService: GeocodingService,
              private globalService:GlobalService,
              private dataManagementService:DataManagementService,
              private loadingService:LoadingService) {}

  ngOnInit() {
    this.poi.coordinate.lat=this.latPoi;
    this.poi.coordinate.lng=this.lngPoi;
    this.poi.imageUri='marker1.png';
    this.poi.ray=100;
    this.poi.address=this.adresse
    this.getAdresse(this.latPoi,this.lngPoi,17)
    this.loadingService.setLoading(false)
  }

  getAdresse(lat,lng,zoom){
    this.geocodingService.inverseGeoconding(lat,lng,zoom).subscribe(
      res=>{
        this.poi.address=res.display_name
      },
      error=>{
        console.log(error)
      }
    )
  }

  loadPoiImages() {
    setTimeout (() => {
     var radios= document.getElementsByClassName('alert-radio-label');
		for (let index = 0; index < radios.length; index++) {
      let singrad = radios[index];
		  singrad.innerHTML = singrad.innerHTML.concat('<img src='+this.typePois[index].imagesrc+' style="width:30px; position:absolute; right:20px;"/>');
	    (singrad as HTMLElement).style.fontSize  = "15px";
    }
    }, 200);
  }


  ajouterPointInteret(poi){
    this.poiService.ajouterPointInteret(poi).subscribe(
      res=>{
        this.dataManagementService.pointInterests.push(poi)
        this.popoverController.dismiss();
      },
      error=>{
        console.log(error)
      }
    )
  }

}
