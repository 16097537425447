import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';

/**imports* */
import { AppRoutingModule } from './app-routing.module';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
declare var require: any;
import { MomentModule } from 'ngx-moment';
import { NgxPopperModule } from 'ngx-popper';
import { IonicPullupModule } from "ionic-pullup";

/************providers************* */

import { SigninService } from './signin/signin.service';
import { GlobalService } from '../app/global.config';
import { TranslateService, TranslateStore } from '@ngx-translate/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AuthGuard } from './utils/auth.guard';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { MapService } from './utils/leaflet/service/map.service';
import { GeocodingService } from './utils/leaflet/service/geocoding.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LoadingService } from './utils/loading/loadingService';
import { LoadingInterceptor } from './utils/loading/interceptor';
import { PointInteretService } from './pages/data-management/point-interet/point-interet.service';

import { SigninComponent } from './signin/signin.component';
import { RealtimeComponent } from './pages/realtime/realtime.component';
import { RealTimeWorkerService } from './pages/realtime/realtime-worker.service';
import { RealTimeService } from './pages/realtime/realtime.service';
import { HeaderComponent } from './header/header.component';
import { HistoricalComponent } from './pages/historical/historical.component';
import { PathComponent } from './pages/historical/path/path.component';
import { StopComponent } from './pages/historical/stop/stop.component';
import { OverspeedComponent } from './pages/historical/overspeed/overspeed.component';
import { MileageComponent } from './pages/historical/mileage/mileage.component';
import { PathModalComponent } from './pages/historical/path/path-modal/path-modal.component';
import { CalendarModalComponent } from './pages/historical/calendar-modal/calendar-modal.component';
import { StopModalComponent } from './pages/historical/stop/stop-modal/stop-modal.component';
import { MileageModalComponent } from './pages/historical/mileage/mileage-modal/mileage-modal.component';
import { OverspeedModalComponent } from './pages/historical/overspeed/overspeed-modal/overspeed-modal.component';
import { ExpandableComponent } from './utils/expandable/expandable.component';
import { PromptComponent } from './pages/realtime/prompt/prompt.component';
import { DataManagementComponent } from './pages/data-management/data-management.component';
import { NotificationsComponent } from './header/notifications/notifications.component';
import { DashboradComponent } from './pages/realtime/dashborad/dashborad.component';
import { AddServerComponent } from './signin/add-server/add-server.component';
import { PointInteretComponent } from './pages/data-management/point-interet/point-interet.component';
import { PopupRtComponent } from './pages/realtime/popup-rt/popup-rt.component';
import { AlertManagementComponent } from './pages/alert-management/alert-management.component';
/************************pipes*************** */

import { OrderByPipe } from './pipes/order-by.pipe';
import { CapitalizeFirstPipe } from './pipes/capitalizefirst.pipe';
import { GroupFilterPipe } from './pipes/group-filter.pipe';
import { RoundPipe } from './pipes/round.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { NoValuePipe } from './pipes/no-value.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { FilterByPipe } from './pipes/filet-by.pipe';

import { fas } from '@fortawesome/free-solid-svg-icons';

import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
//import { FCM } from 'plugins/cordova-plugin-fcm-with-dependecy-updated/ionic/ngx/FCM'
import { ShowHidePasswordComponent } from './signin/show-hide-password/show-hide-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertNotificationComponent } from "./pages/alert-management/alert-notification/alert-notification.component";


declare var require: any;

export function highchartsFactory() {
  const hc = require('highcharts');
  const sg = require('highcharts/modules/solid-gauge');
  const hm = require('highcharts/highcharts-more');
  sg(hc);
  hm(hc);
  return hc;
}

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    HeaderComponent,
    RealtimeComponent,
    HistoricalComponent,
    PathComponent,
    StopComponent,
    OverspeedComponent,
    MileageComponent,
    PathModalComponent,
    CalendarModalComponent,
    StopModalComponent,
    MileageModalComponent,
    OverspeedModalComponent,
    ExpandableComponent,
    PromptComponent,
    DataManagementComponent,
    NotificationsComponent,
    AlertManagementComponent,
    AlertNotificationComponent,
    DashboradComponent,
    AddServerComponent,
    PointInteretComponent,
    PopupRtComponent,
    OrderByPipe,
    CapitalizeFirstPipe,
    GroupFilterPipe,
    RoundPipe,
    ReversePipe,
    NoValuePipe,
    TruncatePipe,
    FilterByPipe,
    ShowHidePasswordComponent,
  ],
  entryComponents: [
    PathModalComponent,
    MileageModalComponent,
    StopModalComponent,
    OverspeedModalComponent,
    PromptComponent,
    NotificationsComponent,
    ExpandableComponent,
    DashboradComponent,
    AddServerComponent,
    PointInteretComponent,
    PopupRtComponent,
    ShowHidePasswordComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FontAwesomeModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    NgxPopperModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MomentModule,
    IonicPullupModule,
    BrowserAnimationsModule
  ],
  providers: [
    //FCM,
    SigninService,
    GlobalService,
    MapService,
    StatusBar,
    SplashScreen,
    RealTimeWorkerService,
    RealTimeService,
    GeocodingService,
    TranslateService,
    TranslateStore,
    PointInteretService,
    AndroidPermissions,
    DecimalPipe,
    DatePipe,
    Geolocation,
    AuthGuard,
    FaConfig,
    FaIconLibrary,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    LoadingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
