import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/global.config';
import { PointInterest } from 'src/app/model/data-managemnt';
import { createAuthorizationHeader } from 'src/app/utils/headers';

@Injectable({
  providedIn: 'root'
})
export class PointInteretService {

  headers = createAuthorizationHeader();
  
  constructor(private _http: HttpClient,
              private globalService: GlobalService) { }

    ajouterPointInteret(poi:PointInterest):Observable<PointInterest>{
      return this._http.post<PointInterest>(this.globalService.dns+'pointInterests',poi,{ headers: this.headers })
    }
}
