import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PointInterest, Group, Vehicule } from 'src/app/model/data-managemnt';
import { RealTimeRecord } from 'src/app/model/real-time';
import { IonPullUpFooterState } from 'ionic-pullup';
import { AlertController, PopoverController, NavController, ModalController, Platform } from '@ionic/angular';
import { PromptComponent } from './prompt/prompt.component';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as L from 'leaflet';
import { Router } from "@angular/router";
import { HeaderService } from 'src/app/header/header.service';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { Map } from "leaflet";
import { DashboradComponent } from './dashborad/dashborad.component';
import { LoadingService } from 'src/app/utils/loading/loadingService';
import { PopupService } from './popup-rt/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { version } from 'src/app/global.config';
import { RealTimeService } from './realtime.service';
import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator';
import { RealTimeWorkerService } from './realtime-worker.service';
import { SigninService } from 'src/app/signin/signin.service';


@Component({
  selector: 'app-realtime',
  templateUrl: './realtime.component.html',
  styleUrls: ['./realtime.component.scss'],
})
export class RealtimeComponent implements OnInit {
  @ViewChild('myTable', { static: false }) table: any;
  footerState: IonPullUpFooterState;
  pointInterest: PointInterest = new PointInterest();
  engine = true;
  id1: any;
  id2: any;
  currentlocationlat: any;
  currentlocationlng: any;
  isClicked: boolean = false;
  positionMarker: any;
  selectedDevice: any;
  circle: any;
  rtMap: Map;
  isClickPop: boolean = false;
  tooltipEvent: 'click' | 'press' = 'click';
  showArrow: boolean = true;
  duration: number = 3000;
  backButtonSubscription: any;
  searchVehicule: string = '';
  liveReloadingRT: any;
  reloadGetRealTimePath: boolean;
  hideRTPath: boolean;
  hideCurrentPath: boolean;
  latBegin: number;
  lngBegin: number;
  clickTrackDate: Date;
  trameRtTrack = [];
  loading = false;
  version: string;
  navigator: Navigator;

  constructor(public realTimeWorker: RealTimeWorkerService, private alertCtrl: AlertController,
    private popoverCtrl: PopoverController, private geolocation: Geolocation,
    public mapService: MapService, private navCtrl: NavController,
    private router: Router, private headerService: HeaderService, private modalController: ModalController,
    private platform: Platform,
    public loadingService: LoadingService,
    public popupService: PopupService,
    private translate: TranslateService,
    public signinService:SigninService) {
    this.footerState = IonPullUpFooterState.Collapsed;
    this.headerService.selectedTab = "realtime";
    this.version = version;
  }

  ngOnInit() {
    try {
      this.realTimeWorker.init();
      this.geolocation.getCurrentPosition().then((resp) => {
        this.popupService.currentlocationlat = resp.coords.latitude;
        this.popupService.currentlocationlng = resp.coords.longitude;
      }).catch((error) => {
        console.log('Error getting location', error);
      });
    } catch (ee) { }
    console.log("ngOnInit is here")

    //his.realTimeWorker.init();
  }

  ionViewDidEnter() {
    this.mapService.initMap(this.rtMap, "rtMap");
    this.realTimeWorker.loadFocus();
    this.realTimeWorker.initTimer();
    this.mapService.rtMarkers=[];
  }

  ionViewWillLeave() {
    console.log("ionViewWillLeave");
    this.mapService.map.remove();
  }

  ionViewWillEnter() {
    this.translate.setDefaultLang(this.translate.currentLang)
    this.realTimeWorker.dataManagementService.selectedVehicule = null;
    this.backButtonSubscription = this.platform.backButton.subscribe(async () => {
      this.navigator['app'].exitApp();
    });
  }

  onFocus(event: any): void {
    this.realTimeWorker.loadFocus();
    this.realTimeWorker.initTimer();
  }

  ionViewDidLeave() {
    clearTimeout(this.liveReloadingRT);
    clearTimeout(this.realTimeWorker.liveReloadingRT);
    this.backButtonSubscription.unsubscribe();
  }

  ngOnDestroy() {
    try {
      if (this.realTimeWorker.allRealTimeRecords) {
        this.realTimeWorker.allRealTimeRecords.unsubscribe();
      }
    } catch (e) {

    }
    if (this.realTimeWorker.geocodingSubscription) {
      this.realTimeWorker.geocodingSubscription.unsubscribe();
    }
    if (this.realTimeWorker.markerWasAddedSubscription) {
      this.realTimeWorker.markerWasAddedSubscription.unsubscribe();
    }
    if (this.realTimeWorker.allGroups) {
      this.realTimeWorker.allGroups.unsubscribe();
    }
  }

  addPOI(realTimeRecord: RealTimeRecord) {
    this.pointInterest = new PointInterest;
    this.pointInterest.type = 0;
    this.pointInterest.coordinate = realTimeRecord.coordinate;
    this.pointInterest.address = realTimeRecord.geocodingDetails;
  }
  refreshFiles() {
    return new Date();
  }
  footerExpanded() {
    console.log('Footer expanded!');
  }

  footerCollapsed() {
    console.log('Footer collapsed!');
  }

  toggleFooter() {
    this.realTimeWorker.rtRows = [...this.realTimeWorker.rtRows];
    this.footerState = this.footerState === IonPullUpFooterState.Collapsed ? IonPullUpFooterState.Expanded : IonPullUpFooterState.Collapsed;
  }
  toggleExpandGroup(group: any) {
    this.table.groupHeader.toggleExpandGroup(group);
  }

  onDetailToggle(event: any) {
    console.log('Detail Toggled', event);
  }

  expandItem(group: Group) {
    group.isOpen = !group.isOpen;
  }

  expandAllGroups() {
    this.realTimeWorker.dataManagementService.groups.forEach(group => {
      group.isOpen = true;
    })
  }

  goToRealTimeRecord(group: Group, vehicule: Vehicule) {
    this.realTimeWorker.goToRealTimeRecord(group, vehicule);
    this.footerState = IonPullUpFooterState.Collapsed;
  }

  async openDashborad() {
    const modal = await this.modalController.create({
      component: DashboradComponent,
      cssClass: 'details'
    });

    return await modal.present();
  }

  async arreterDemarrer(ev: any) {
    let vehicule = this.realTimeWorker.dataManagementService.selectedVehicule!.idDevice;
    let group = this.realTimeWorker.dataManagementService.selectedGroup;
    let selectedV = { "group": group, "vehicule": vehicule, "indexGroup": this.id1, "indexVehicule": this.id2 };

    let popover = await this.popoverCtrl.create({
      component: PromptComponent,
      event: ev,
      translucent: false,
      cssClass: 'marcheArret'
    });
    return await popover.present();
  }

  currentPosition() {
    let vehicules = this.realTimeWorker.getVehicule(this.realTimeWorker.dataManagementService.selectedVehicule!.idDevice);

    if (!this.isClicked) {
      this.mapService.map.flyTo(L.latLng(this.popupService.currentlocationlat, this.currentlocationlng), 15);
      this.positionMarker = L.marker([this.popupService.currentlocationlat, this.currentlocationlng]).addTo(this.mapService.map);

      this.circle = L.circle([this.popupService.currentlocationlat, this.popupService.currentlocationlng], {
        fillOpacity: 0.2,
        radius: 500
      }).addTo(this.mapService.map);

      this.isClicked = !this.isClicked;
    }
    else {
      this.mapService.map.removeLayer(this.positionMarker);
      this.mapService.map.removeLayer(this.circle);
      this.mapService.map.setView(L.latLng(vehicules[0].realTimeRecord.coordinate.lat, vehicules[0].realTimeRecord.coordinate.lng), 8);
      this.isClicked = !this.isClicked;
    }


  }
  drawpath() {
    if (this.realTimeWorker.dataManagementService.selectedVehicule!) {
      let vehicules = this.realTimeWorker.getVehicule(this.realTimeWorker.dataManagementService.selectedVehicule!.idDevice);
      if (this.popupService.currentlocationlat && this.popupService.currentlocationlng) {
        let options: LaunchNavigatorOptions = {
          start: [this.popupService.currentlocationlat, this.popupService.currentlocationlng],
          app: LaunchNavigator.APP.GOOGLE_MAPS

        };
        LaunchNavigator.navigate([vehicules[0].realTimeRecord.coordinate.lat,
        vehicules[0].realTimeRecord.coordinate.lng], options)
          .then(
            success => console.log('Launched navigator'),
            error => this.handleError("Veuillez installer Google Maps ")
          );
      } else {
        this.handleError("Veuillez activer GPS ")

      }
    } else {
      this.handleError("Veuillez choisir un véhicule ")
    }

  }
  async handleError(mess: String) {
    let alert = await this.alertCtrl.create({
      message: '' + mess,
      buttons: ['Ok']
    });
    await alert.present();
  }
  voirHistorique() {
    this.hideCurrentPath = true
    this.hideRTPath = true
    this.router.navigate(['/path']);
  }

  displayTodaysMileage(vehicule: Vehicule) {
    this.hideCurrentPath = true
    this.hideRTPath = true
    this.realTimeWorker.displayTodaysMileage(vehicule);
  }

  displayCurrentPath(deviceId: number) {
    this.hideRTPath = true
    this.hideCurrentPath = false
    if (this.realTimeWorker.getVehicule(this.realTimeWorker.dataManagementService.selectedVehicule!.idDevice)[0].realTimeRecord.ignition == true) {
      this.realTimeWorker.displayCurrentPath(deviceId);
      let lat = this.realTimeWorker.getVehicule(this.realTimeWorker.dataManagementService.selectedVehicule!.idDevice)[0].realTimeRecord.coordinate.lat
      let lng = this.realTimeWorker.getVehicule(this.realTimeWorker.dataManagementService.selectedVehicule!.idDevice)[0].realTimeRecord.coordinate.lng
      this.mapService.centerMapMarker(lat, lng);
    }

  }

  closePopupSide() {
    let vehicules = this.realTimeWorker.getVehicule(this.realTimeWorker.dataManagementService.selectedVehicule!.idDevice);
    this.trameRtTrack = []
    this.realTimeWorker.dataManagementService.selectedVehicule = null;
    this.popupService.hideCurrentPath = true
    this.popupService.showBackButton = false
    this.mapService.map.closePopup()
    if (this.popupService.isClicked) {
      this.mapService.map.removeLayer(this.popupService.positionMarker);
      this.mapService.map.removeLayer(this.popupService.circle);
      this.mapService.map.setView(L.latLng(vehicules[0].realTimeRecord.coordinate.lat, vehicules[0].realTimeRecord.coordinate.lng), 8);
      this.popupService.isClicked = !this.popupService.isClicked;
    }

    try {
      this.mapService.centerMap(this.realTimeWorker.allRealTimeRecords, 17);
    } catch (e) {

    }
    this.mapService.removePolylinesFromMap();
    this.mapService.removeMarkersFromMap();
  }

  returnToSelectedVehicule() {
    let vehicule = this.realTimeWorker.dataManagementService.selectedVehicule;
    this.popupService.showBackButton = false
    this.popupService.hideCurrentPath = true
    this.mapService.removePolylinesFromMap();
    this.mapService.removeMarkersFromMap();
    if (this.popupService.isClicked) {
      this.mapService.map.removeLayer(this.popupService.positionMarker);
      this.mapService.map.removeLayer(this.popupService.circle);
      this.mapService.map.setView(L.latLng(vehicule!.realTimeRecord.coordinate.lat, vehicule!.realTimeRecord.coordinate.lng), 8);
      this.popupService.isClicked = !this.popupService.isClicked;
    }
    this.mapService.map.setView(this.realTimeWorker.dataManagementService.selectedVehicule!.realTimeRecord.coordinate, 17);
  }

  getRealTimePath() {
    this.hideRTPath = false;
    this.hideCurrentPath = true;
    if (this.realTimeWorker.dataManagementService.selectedVehicule) {
      let idDevice = this.realTimeWorker.dataManagementService.selectedVehicule!.idDevice

      let vehicule = this.realTimeWorker.getVehicule(idDevice)[0]
      let speed = vehicule.realTimeRecord.speed
      if (vehicule.realTimeRecord.ignition == true
        && this.lngBegin && this.latBegin && this.clickTrackDate) {
        this.realTimeWorker.displayRTPath(idDevice, this.latBegin, this.lngBegin, this.clickTrackDate, speed);
      }

    }
  }

  getCurrentVehiculePosition() {
    let vehicule = this.realTimeWorker.getVehicule(this.realTimeWorker.dataManagementService.selectedVehicule!.idDevice)[0]

    this.latBegin = vehicule.realTimeRecord.coordinate.lat;
    this.lngBegin = vehicule.realTimeRecord.coordinate.lng;
    this.clickTrackDate = new Date()
    console.log("date : " + this.clickTrackDate)

  }

  initTimerCurrentPath() {
    clearTimeout(this.liveReloadingRT);
    let idDevice = this.realTimeWorker.getVehicule(this.realTimeWorker.dataManagementService.selectedVehicule!.idDevice)[0].idDevice
    this.displayCurrentPath(idDevice);
    this.liveReloadingRT = setInterval(() => {
      if (this.hideCurrentPath == false) {
        this.displayCurrentPath(idDevice);
      }
    }, 20000);

  }





}
