//export var version: string = 'finek';
//export var version: string = 'fleet';
//export var version: string = 'traci';
//export var version: string = 'digitecom';
//export var version: string = 'slama';
//export var version: string =  'dhcom';
//export var version: string = 'tchycotrack';
export var version: string = 'fasttrack';
//export var version: string = 'click';

export var template: string = 'traci';
//export var template: string = 'fleet';

//export var mobile: string = "true";
export var mobile: string = "false";

import { Injectable } from '@angular/core';

export var token_google = 'AIzaSyCKznGfI2X7tF1K1NsLHVTcKrf9-HmMwBI';

@Injectable()
export class GlobalService {
	dns: string;
	imagesCarsDir: string;
	nominatim_dns: string;
	imagesPOIDir: string;
	imagesMODELDir: string;
	imagesDir: string;
	filesDir: string;
	imagesAssetDir : string;
	time_difference: number;
	servername: string = 'fasttrack.tn';
	proto: string = "https://";
	policy: string;
	addok_dns: string;
	fcmservername: string = 'fasttrack';

	constructor() {
		this.updateUrl();
	}

	getservername() {
		return this.servername;
	}

	updateUrl() {
		this.dns = this.proto + this.servername + '/ws_rimtrack_all/';
		this.policy = this.proto + this.servername + '/if3/policy/TRACI.html';
		this.imagesDir = this.proto + this.servername + '/images/';
		this.imagesAssetDir = this.proto + this.servername + '/images/assets/';
		this.imagesCarsDir = this.proto + this.servername + '/images/cars/';
		this.imagesMODELDir = this.proto + this.servername + '/images/models/';
		this.filesDir = this.proto + this.servername + '/';
		this.nominatim_dns = this.proto + this.servername + '/nominatim';
		this.time_difference = new Date().getTimezoneOffset();
		this.imagesPOIDir = this.proto + this.servername + '/images/poi/';
		this.addok_dns = this.proto + this.servername + '/addok/';
		this.fcmservername = this.fcmservername;
	}
	setservername(servername: string) {
		this.servername = servername;
		this.imagesDir = this.proto + servername + '/images/';
		this.imagesCarsDir = this.proto + servername + '/images/cars/';
		this.nominatim_dns = this.proto + servername + '/nominatim';
		this.time_difference = new Date().getTimezoneOffset();
		this.imagesPOIDir = this.proto + servername + '/images/poi/';
		this.updateUrl();
		this.addok_dns = this.proto + servername + '/addok/';
	}
	getnominatim_dns() {
		return this.nominatim_dns;
	}
	getimagesPOIDir() {
		return this.imagesPOIDir;
	}
	getimagesDir() {
		return this.imagesDir;
	}
	gettime_difference() {
		return this.time_difference;
	}
	getimagescar() {
		return this.imagesCarsDir;
	}

	getFileDir() {
		return this.filesDir;
	}

	getimagesMODELDir() {
		return this.imagesMODELDir;
	}

	getimagesCarsDir() {
		return this.imagesCarsDir;
	}

	getaddok_dns() {
		return this.addok_dns;
	}

	getimagesAssetDir() {
		return this.imagesAssetDir;
	}

}
