import { Component } from '@angular/core';
import { Platform, ToastController, ActionSheetController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { GlobalService, version } from '../app/global.config';
import { Router } from "@angular/router";
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Location } from '@angular/common';

import { User } from './signin/credentials';
import { Subscription } from 'rxjs';
//import { FCM } from 'plugins/cordova-plugin-fcm-with-dependecy-updated/ionic/ngx/FCM';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  version: String = version;
  showSplash = true; // <-- show animation
  currentUser: User;
  token: any;
  backButtonSubscription: Subscription;
  interval: any;


  constructor(private toastController: ToastController,
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public actionSheetCtrl: ActionSheetController,
    private androidPermissions: AndroidPermissions,
    private location: Location,
    public alertController: AlertController,
    private globalService: GlobalService,
    //private fcm: FCM
  ) {
    this.checkPreviousAuthorization();
    this.interval = setInterval(() => {
      this.showSplash = false;
    }, 1000);

    //this.initializeApp();
  }



  //verification d'authentification
  async checkPreviousAuthorization() {
    let toast = await this.toastController.create({
      message: 'Session expirée!',
      duration: 4000
    });
    return await toast.present();
  }

/*  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
        result => console.log('Has permission?', result.hasPermission),
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION)
      );

      this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION, this.androidPermissions.PERMISSION.GET_ACCOUNTS]);
      this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(0, async () => {
        if (this.location.isCurrentPathEqualTo('/signin')
          || this.location.isCurrentPathEqualTo('/client')) {
          this.presentAlert()
        }
        else {
          this.router.navigateByUrl('/client')
        }

      });

      this.currentUser = JSON.parse(localStorage.getItem("currentUser") || '{}')
      if (this.currentUser.username) {
        this.fcm.subscribeToTopic(this.globalService.fcmservername + '_' + this.currentUser.username);
        this.getToken();

        this.fcm.onNotification().subscribe(data => {
          if (data.wasTapped) {
            console.log("Received in background" + data);
          } else {
            console.log("Received in foreground" + data);
          };
        });

        this.fcm.onTokenRefresh().subscribe(token => {
          console.log(token);
        });
      }

    });

  }

  getToken() {
    this.fcm.getToken().then(token => {
      console.log('token  is here : ' + token);
    });
  }*/


  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Quitter',
      message: "Vous voulez vraiment quitter l'application?",
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          handler: () => {
            this.alertController.dismiss()
          }
        }, {
          text: 'Quitter',
          handler: () => {
            navigator['app'].exitApp();
          }
        }
      ]
    });
    await alert.present();
  }

}

