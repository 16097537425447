import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { createAuthorizationHeader } from '../../utils/headers';
import { DateInterval } from 'src/app/model/date-interval.model';
import { Path, Overspeed, Stop, Mileage } from 'src/app/model/historical';
import { GlobalService } from 'src/app/global.config';
import { DataManagementService } from '../data-management/data-management.service';
import { ToastController } from '@ionic/angular';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { version } from '../../global.config';

@Injectable({
  providedIn: 'root'
})
export class HistoricalService {

  pathModal: any;

  paths: Path[] = new Array();

  overspeeds: Overspeed[] = new Array();

  stops: Stop[] = new Array();

  mileages: Mileage[] = new Array();
  startDate: Date = new Date();

  endDate: Date = new Date();

  selectedTab: String;
  loading: boolean;
  version: string;

  constructor(private _http: HttpClient,
    private globalService: GlobalService,
    public dataManagementService: DataManagementService,
    public geocodingService: GeocodingService,
    public toastr: ToastController,) {
    this.endDate.setHours(23, 59, 59, 0);
    this.startDate.setTime(this.startDate.getTime() - 3 * 86400000);
    this.startDate.setHours(0, 0, 0, 0);

    this.selectedTab = "path";
    this.version = version;
  }

  getCurrentPath(deviceId: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(this.globalService.dns + "paths/currentPath/" + deviceId, null, { headers: headers });
  }

  getAllPaths(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(this.globalService.dns + "paths/" + deviceId, dateInterval, { headers: headers });
  }
  getPathDetails(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(this.globalService.dns + "paths/details/" + deviceId, dateInterval, { headers: headers });
  }

  getAllStops(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(this.globalService.dns + "stops/" + deviceId, dateInterval, { headers: headers });
  }

  getAllOverspeeds(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(this.globalService.dns + "rep_overspeed/" + deviceId, dateInterval, { headers: headers });
  }

  getOverspeedDetails(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(this.globalService.dns + "rep_overspeed/details/" + deviceId, dateInterval, {
        headers: headers
      });
  }

  getAllMileages(deviceId: number, dateInterval: any): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(this.globalService.dns + "mileage/" + deviceId, dateInterval, { headers: headers });
  }

  getMileageDetails(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(this.globalService.dns + "mileage/details/" + deviceId, dateInterval, {
        headers: headers
      });
  }

  getTodaysMileage(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(this.globalService.dns + "mileage/todayDetails/" + deviceId, dateInterval, {
        headers: headers
      });
  }

  getAllPathRoutine() {
    this.loading = true
    this.paths = [];
    this
      .getAllPaths(this.dataManagementService.selectedVehicule!.device.idDevice, {
        startDate: this.startDate,
        endDate: this.endDate
      })
      .subscribe(
        paths => {
          this.loading = false
          if (paths.length) {
            this.paths = paths;
            this.dataManagementService.selectedGroup = this.dataManagementService.selectedGroup;
            this.dataManagementService.selectedVehicule = this.dataManagementService.selectedVehicule;
            let lastLat = paths[0].beginPathLatitude;
            let lastlng = paths[0].beginPathLongitude;
            let i = 0;
            this.paths.forEach(path => {
              let startHour: any = path.beginPathTime;
              let startTime: any = startHour;


              path.pathDurationStr = this.transformTime(path.pathDuration)

              path.displayBeginPathTime = startTime;

              if (path.endPathTime != null) {
                let endHour: any = path.endPathTime;
                let endTime: any = endHour;
                path.displayEndPathTime = endTime;

              }
              path.beginPathGeocodingDetails = this.dataManagementService.getRelativePosition(
                path.beginPathLatitude,
                path.beginPathLongitude
              );
              path.beginPathGeocoding = path.beginPathGeocodingDetails;
              if (path.beginPathGeocodingDetails == null || path.beginPathGeocodingDetails == '') {
                /*if (version == 'click') {
                  this.geocodingService
                    .inverseGeocondingAddok(
                      path.beginPathLatitude,
                      path.beginPathLongitude
                    )
                    .subscribe(
                      adress => {
                        path.beginPathGeocoding = adress.features[0].properties.label;
                        path.beginPathGeocodingDetails = this.geocodingService.proccessingNomitamAddress(
                          adress
                        );
                      },
                      err => {
                        path.beginPathGeocodingDetails = null;
                        path.beginPathGeocoding = null;
                      }
                    );
                } else {*/
                this.geocodingService
                  .inverseGeoconding(
                    path.beginPathLatitude,
                    path.beginPathLongitude,
                    17
                  )
                  .subscribe(
                    adress => {
                      path.beginPathGeocoding = this.geocodingService.proccessingNomitamAddress(
                        adress
                      );
                      path.beginPathGeocodingDetails = this.geocodingService.proccessingNomitamAddress(
                        adress
                      );
                    },
                    err => {
                      path.beginPathGeocodingDetails = null;
                      path.beginPathGeocoding = null;
                    }
                  );
                // }
              }
              path.endPathGeocodingDetails = this.dataManagementService.getRelativePosition(
                path.endPathLatitude,
                path.endPathLongitude
              );
              path.endPathGeocoding = path.endPathGeocodingDetails;
              if (path.endPathGeocodingDetails == null || path.endPathGeocodingDetails == '') {
                /*if (version == 'click') {
                  this.geocodingService
                    .inverseGeocondingAddok(
                      path.endPathLatitude,
                      path.endPathLongitude
                    )
                    .subscribe(
                      adress => {
                        path.endPathGeocoding = adress.features[0].properties.label;
                        path.endPathGeocodingDetails = this.geocodingService.proccessingNomitamAddress(
                          adress
                        );
                      },
                      err => {
                        path.endPathGeocodingDetails = null;
                        path.endPathGeocoding = null;
                      }
                    );
                } else {*/
                this.geocodingService
                  .inverseGeoconding(
                    path.endPathLatitude,
                    path.endPathLongitude,
                    17
                  )
                  .subscribe(
                    adress => {
                      path.endPathGeocoding = this.geocodingService.proccessingNomitamAddress(
                        adress
                      );
                      path.endPathGeocodingDetails = this.geocodingService.proccessingNomitamAddress(
                        adress
                      );
                    },
                    err => {
                      path.endPathGeocodingDetails = null;
                      path.endPathGeocoding = null;
                    }
                  );
              }
              //}
              i++;
              lastLat = path.beginPathLatitude;
              lastlng = path.beginPathLongitude;
            });
          }
        },
        err => {
        }
      );
  }


  transformTime(time: any) {

    let sec = time % 60
    let min = Math.trunc(time / 60)
    let h = Math.trunc(min / 60)
    let j = Math.trunc(h / 24)

    if (j != 0) {
      return j + ' j ' + h + ' h ' + min + ' min ' + sec + ' sec'
    }
    if (j == 0 && h != 0) {
      return h + ' h ' + min + ' min ' + sec + ' sec'
    }
    if (j == 0 && h == 0 && min != 0) {
      return min + ' min ' + sec + ' sec'
    }
    if (j == 0 && h == 0 && min == 0) {
      return sec + ' sec'
    }
    return null;
  }


  getAllOverSpeedRoutine() {
    this.loading = true
    let endDate = this.endDate;
    let startDate = this.startDate;
    let idDevice = this.dataManagementService.selectedVehicule!.device.idDevice
    this.getAllOverspeeds(idDevice, { startDate, endDate }).subscribe(
      overspeeds => {
        this.loading = false
        this.overspeeds = overspeeds
        this.overspeeds.forEach(overspeed => {
          let startHour: any = overspeed.beginPathTime;
          let startTime: any = startHour;

          overspeed.displayBeginPathTime = startTime;
          let endHour: any = overspeed.endPathTime;
          let endTime: any = endHour;
          overspeed.displayEndPathTime = endTime;

          let date1: any = startHour;
          let date2: any = endHour;
          let diffInMs: number = date2 - date1;
          let diffInSec: number = diffInMs / 1000;
          let diffInMinute: number = Math.floor(diffInSec / 60);

          overspeed.pathDurationStr = diffInMinute + "m " + (diffInSec - 60 * diffInMinute) + "s";
          overspeed.beginPathGeocodingDetails = this.dataManagementService.getRelativePosition(
            overspeed.beginPathLatitude,
            overspeed.beginPathLongitude
          );
          overspeed.beginPathGeocoding = overspeed.beginPathGeocodingDetails;
          //if (overspeed.beginPathGeocodingDetails == null) {

          this.geocodingService
            .inverseGeoconding(
              overspeed.beginPathLatitude,
              overspeed.beginPathLongitude,
              17
            )
            .subscribe(
              adress => {
                overspeed.beginPathGeocoding = this.geocodingService.proccessingNomitamAddress(
                  adress
                );
                overspeed.beginPathGeocodingDetails = this.geocodingService.proccessingNomitamAddress(
                  adress
                );
              },
              err => {
                overspeed.beginPathGeocodingDetails = null;
                overspeed.beginPathGeocoding = null;
              }
            );

          //}
          overspeed.endPathGeocodingDetails = this.dataManagementService.getRelativePosition(
            overspeed.endPathLatitude,
            overspeed.endPathLongitude
          );
          overspeed.endPathGeocoding = overspeed.endPathGeocodingDetails;
          //if (overspeed.endPathGeocodingDetails == null) {
          this.geocodingService
            .inverseGeoconding(
              overspeed.endPathLatitude,
              overspeed.endPathLongitude,
              17
            )
            .subscribe(
              adress => {
                overspeed.endPathGeocoding = this.geocodingService.proccessingNomitamAddress(
                  adress
                );
                overspeed.endPathGeocodingDetails = this.geocodingService.proccessingNomitamAddress(
                  adress
                );
              },
              err => {
                overspeed.endPathGeocodingDetails = null;
                overspeed.endPathGeocoding = null;
              }
            );
          //}
        });
      }
    )

  }


}
