import { Group } from '../model/data-managemnt';

export class Credentials {
  username: string;
  password: string;

  constructor() {
    this.username = '';
    this.password = '';
  }
}

export class User {

  id: number;
  displayName: string;
  username: string;
  email: string;
  enabled: boolean;
  token: string;
  isRoot: boolean;
  password: string;
  server: string;
  authorities: Authority[];
  groups: Group[];
  options: Option[];
  expirationDate: string;
  fcmservername:string;

  constructor() {
    this.username = '';
    this.email = '';
    this.server = '';
    this.authorities = [];
    this.groups = [];
    this.enabled = true;
    this.isRoot = false;
    this.options = [];
  }
}

export class Action {
  id: number;
  type: 'CONNECT' | 'DISCONNECT';
  details: string;
  createdAt: number;

  constructor() { }
}

export type AuthorityName =
  | 'ROLE_ROOT'
  | 'ROLE_RT'
  | 'ROLE_PATH'
  | 'ROLE_DM'
  | 'ROLE_PM'
  | 'ROLE_AM'
  | 'ROLE_RM'
  | 'ROLE_OV'
  | 'ROLE_ED'
  | 'ROLE_SM'
  | 'ROLE_OV'
  | 'ROLE_PM_CD'
  | 'ROLE_PM_FUEL'
  | 'ROLE_PM_VIDANGE'
  | 'ROLE_PM_MAINTENANCE'
  | 'ROLE_PM_LOSSES'
  | 'ROLE_PM_LEASING'
  | 'ROLE_PM_VARIOUS'
  | 'ROLE_PM_DASHBOARD'
  | 'ROLE_POI';

export class Authority {
  id: string;
  name: AuthorityName;
  label: string;

  constructor() { }
}

export class Option {
  idOption: number;
  description: string;
}