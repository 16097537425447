import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Vehicule } from 'src/app/model/data-managemnt';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { DataManagementService } from '../../data-management/data-management.service';
import { HistoricalService } from '../../historical/historical.service';
import * as L from 'leaflet';
import Marker = L.Marker;
import Icon = L.Icon;
import { DatePipe } from '@angular/common';
import { GlobalService } from 'src/app/global.config';
import { PromptComponent } from '../prompt/prompt.component';
import { AlertController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  id1: any;
  id2: any;
  liveReloadingRT: any
  currentPathClickedDeviceId: number;
  isCurrentPathClicked: boolean;
  positionMarker: any;
  circle: any;
  isClicked: boolean = false;
  hideCurrentPath: boolean = true
  currentlocationlat: any;
  currentlocationlng: any;
  marker: any
  showBackButton: boolean = false
  constructor(
    private router: Router,
    private dataManagementService: DataManagementService,
    private mapService: MapService,
    private historicalService: HistoricalService,
    private globalService: GlobalService,
    private popoverCtrl: PopoverController,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private datePipe:DatePipe) { }


  getVehicule(idDevice: number): Vehicule[] {
    let foundVehicule :any[] =[];
    if (!this.dataManagementService.groups)
      return [];
    for (let i = 0; i < this.dataManagementService.groups.length; i++) {
      for (let j = 0; j < this.dataManagementService.groups[i].vehicules.length; j++) {
        if (this.dataManagementService.groups[i].vehicules[j].device != null &&
          this.dataManagementService.groups[i].vehicules[j].device.idDevice === idDevice) {
          foundVehicule.push(this.dataManagementService.groups[i].vehicules[j] as never);
        }
      }
    }
    return foundVehicule;

  }

  voirHistorique() {
    this.hideCurrentPath = true
    this.router.navigate(['/path']);
  }


  displayTodaysMileage(vehicule: Vehicule) {
    this.showBackButton = true
    this.hideCurrentPath = true
    this.mapService.removePolylinesFromMap();
    this.mapService.removeMarkersFromMap();
    let today = new Date();
    this.historicalService
      .getAllMileages(vehicule.idDevice, {
        startDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          0,
          0,
          0
        ),
        endDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          23,
          59,
          59
        )
      })
      .subscribe(
        mileages => {
          vehicule.mileage = this.historicalService.mileages[mileages.length - 1];
        },
        () => { }
      );

    let date = new Date();
    date.setHours(0, 0, 0, 0);
    let startHour: any = date.getTime();
    this.historicalService
      .getTodaysMileage(vehicule.device.idDevice, {
        startDate: startHour,
        endDate: startHour
      })
      .subscribe(
        mileage => {
          let points = mileage.points;
          let stops = mileage.stops;
          let polyline = L.polyline(points, { color: "#0031D9", weight: 3 });

          points.forEach((coordinate: any, i: any) => {
            let marker = new Marker(coordinate);
            if (i != 0 && i != points.length - 1) {
              marker.setIcon(
                new Icon({
                  iconUrl: this.globalService.imagesDir + "green-point.png",
                  iconAnchor: [2, 2]
                })
              );
              //let pointDatePipe = new DatePipe(coordinate.date);
              let popup =
                "<b>Heure:</b> " +
                this.datePipe.transform(
                  coordinate.date, "dd/MM/yyyy HH:mm:ss", `en-US`) +
                "<b><br>vitesse:</b> " +
                coordinate.speed +
                " Km/h";
              marker.bindPopup(popup);
              // marker.on("click", () => {
              //   this.mapService.map.setView(coordinate, 17);
              // });
              this.mapService.addMarker(marker);
            }
          });

          stops.forEach((stop: any, i: any) => {
            let marker = new Marker({
              lat: stop.stopLatitude,
              lng: stop.stopLongitude
            });
           // let pointDatePipe = new DatePipe(stop.beginStopTime);
            let popup =
              "<b>Date debut d'arrêt: </b>" +
              this.datePipe.transform(
                stop.beginStopTime, "dd/MM/yyyy HH:mm:ss", `en-US`) +
              "<br><b>Durée d'arrêt: </b>" +
              stop.stopDurationStr;
            marker.setIcon(
              new Icon({
                iconUrl: this.globalService.imagesDir + "stop_smal.png",
                iconSize: [20, 20],
                iconAnchor: [10, 10],
                popupAnchor: [10, -25]
              })
            );
            marker.bindPopup(popup);
            // marker.on("click", () => {
            //   this.mapService.map.setView(
            //     { lat: stop.stopLatitude, lng: stop.stopLongitude },
            //     17
            //   );
            // });
            this.mapService.addMarker(marker);
            this.mapService.fitBounds(points);
          });
          this.mapService.addPolyline(polyline);

          
          //let middle = points[Math.round((points.length - 1) / 2)];

          //map center offset(axis y)
          //this.mapService.offsetMap(middle, 12, 0, -120);
          

        });
  }


  async arreterDemarrer(ev: any, vehic: any) {
    this.hideCurrentPath = true
    let vehicule = vehic.idDevice;
    let group = this.dataManagementService.selectedGroup;
    let selectedV = { "group": group, "vehicule": vehicule, "indexGroup": this.id1, "indexVehicule": this.id2 };

    let popover = await this.popoverCtrl.create({
      component: PromptComponent,
      event: ev,
      translucent: false,
      cssClass: 'marcheArret'
    });
    return await popover.present();
  }


  displayCurrentPath(deviceId: number) {
    this.showBackButton = true
    this.hideCurrentPath = false
    this.mapService.removePolylinesFromMap();
    this.mapService.removeMarkersFromMap();
    this.historicalService.getCurrentPath(deviceId).subscribe(
      currentPath => {
        if (currentPath != null) {
          let polyline = L.polyline(currentPath.coordinates, {
            color: "#0031D9",
            weight: 3
          });
          currentPath.coordinates.forEach((coordinate: any, i: any) => {
            let marker = new Marker(coordinate);
            if (i != 0 && i != currentPath.coordinates.length - 1) {
              marker.setIcon(
                new Icon({
                  iconUrl: this.globalService.imagesDir + "green-point.png",
                  iconAnchor: [2, 2]
                })
              );
              /*let pointDatePipe = new DatePipe(
                coordinate.date + +this.globalService.time_difference + this.globalService.time_difference
              );*/
              let heure;
              this.translate.get('REALTIME.heure').subscribe((res: string) => {
                heure = res;
              });
              let speed;
              this.translate.get('REALTIME.vitesse').subscribe((res: string) => {
                speed = res;
              });
              let popup =
                "<b>" + heure + ":</b> " +
                this.datePipe.transform(
                  coordinate.date,
                  "dd/MM/yyyy HH:mm:ss", `en-US`
                ) +
                "<b><br>" + speed + "</b> " +
                coordinate.speed +
                " Km/h";
              marker.bindPopup(popup);
              // marker.on("click", () => {
              //   this.mapService.map.setView(coordinate, 17);
              // });
              this.mapService.addMarker(marker);
            }
          });
          let startMarker = new Marker({
            lat: currentPath.beginPathLatitude,
            lng: currentPath.beginPathLongitude
          });
          let startTime: any =
            currentPath.beginPathTime + this.globalService.time_difference + this.globalService.time_difference;
         // let startDatePipe = new DatePipe(startTime);
          let time;
          this.translate.get('REALTIME.tempsdedebutdutrajet').subscribe((res: string) => {
            time = res;
          });
          startMarker.bindPopup(
            "<b> <i>[lat: </b>" +
            currentPath.beginPathLatitude +
            "<b> ,long: </b>" +
            currentPath.beginPathLongitude +
            "<b>]</i><br>" + time + " </b>" +
            this.datePipe.transform(startTime, "dd/MM/yyyy HH:mm:ss", `en-US`)
          );
          startMarker.setIcon(
            new Icon({
              iconUrl: this.globalService.imagesDir + "startMarker.png",
              iconAnchor: [-2, 30],
              popupAnchor: [10, -25]
            })
          );
          this.mapService.addMarker(startMarker);
          this.mapService.addPolyline(polyline);
          this.isCurrentPathClicked = true;
          this.currentPathClickedDeviceId = deviceId;
          this.mapService.fitBounds(currentPath.coordinates)
        }
      },
      err => {
      }
    );
  }



  currentPosition(vehicule:any, currentlocationlat:any, currentlocationlng:any) {
    this.showBackButton = true
    this.hideCurrentPath = true
    let vehicules = this.getVehicule(vehicule.idDevice);

    if (!this.isClicked) {
      this.mapService.map.flyTo(L.latLng(currentlocationlat, currentlocationlng), 15);
      this.positionMarker = L.marker([currentlocationlat, currentlocationlng]).addTo(this.mapService.map);
      this.circle = L.circle([currentlocationlat, currentlocationlng], {
        fillOpacity: 0.2,
        radius: 500
      }).addTo(this.mapService.map);

      this.isClicked = !this.isClicked;

    }
    else {
      this.mapService.map.removeLayer(this.positionMarker);
      this.mapService.map.removeLayer(this.circle);
      this.mapService.map.setView(L.latLng(vehicules[0].realTimeRecord.coordinate.lat, vehicules[0].realTimeRecord.coordinate.lng), 8);
      this.isClicked = !this.isClicked;
    }


  }
  drawpath(vehicule:any, currentlocationlat:any, currentlocationlng:any) {
    this.hideCurrentPath = true
    if (vehicule) {
      let vehicules = this.getVehicule(vehicule.idDevice);

      if (currentlocationlat && currentlocationlng) {
        let options: LaunchNavigatorOptions = {
          start: [currentlocationlat, currentlocationlng],
          app: LaunchNavigator.APP.GOOGLE_MAPS

        };
        LaunchNavigator.navigate([vehicules[0].realTimeRecord.coordinate.lat,
        vehicules[0].realTimeRecord.coordinate.lng], options)
          .then(
            success => console.log('Launched navigator'),
            error => this.handleError("Veuillez installer Google Maps ")
          );
      } else {
        this.handleError("Veuillez activer GPS ")

      }
    } else {
      this.handleError("Veuillez choisir un véhicule ")
    }

  }

  async handleError(mess: String) {
    let alert = await this.alertCtrl.create({
      message: '' + mess,
      buttons: ['Ok']
    });
    await alert.present();
  }


}
