import { Component, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Subscription, Observable, timer } from 'rxjs';
import { RealTimeRecord, CalTravel, RealTimeDisplay, RtState } from "../../model/real-time";
import { DatePipe, DecimalPipe } from "@angular/common";
import {
  Group,
  Vehicule
} from "../../model/data-managemnt";
import { DataManagementService } from "../data-management/data-management.service";
import * as L from 'leaflet';
import Marker = L.Marker;
import Icon = L.Icon;
import { User } from "../../signin/credentials";
import { TranslateService } from '@ngx-translate/core';
import { SigninService } from '../../signin/signin.service';
import { ToastController } from '@ionic/angular';
import { HistoricalService } from '../historical/historical.service';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { GlobalService, version } from 'src/app/global.config';
import 'leaflet-polylinedecorator';
import { PopupRtComponent } from './popup-rt/popup-rt.component';
import { PopupService } from './popup-rt/popup.service';
import { Router } from '@angular/router';
import { RealTimeService } from './realtime.service';
@Injectable()
export class RealTimeWorkerService {
  // Subscription Http & Observable
  allRealTimeRecords: Subscription;
  allGroups: Subscription;
  allVehicules: Subscription;
  geocodingSubscription: Subscription;
  markerWasAddedSubscription: Subscription;

  // Declaration vars !!!
  newRealTimeRecords: RealTimeRecord[] = [];
  public currentUser: User = new User();

  isCurrentPathClicked = false;
  searchWord = "";
  groupAutre: Group = new Group();
  currentPathClickedDeviceId: number;
  isSelectedVehicule: boolean = false;
  liveReloadingRT: any;

  imagesMODELDir = this.globalService.getimagesMODELDir();
  url: string = this.globalService.getimagesDir();
  imagesDir = this.globalService.getimagesDir();
  imagesCarsDir = this.globalService.getimagesCarsDir();
  imagesPOIDir = this.globalService.getimagesPOIDir();
  filesDir = this.globalService.getFileDir();
  time_difference = this.globalService.gettime_difference();
  angles: Map<number, number> = new Map();
  isGroupLoaded = false;
  trackingMode = false;


  carDivName: string = "dashboard";

  calTravels: CalTravel[] = new Array();

  translations: any;
  langChanged: boolean = false;
  adress: any;

  rtRows: RealTimeDisplay[] = new Array();

  filteredVehicules: Vehicule[];
  trameRtTrack = [];
  popupOptions: { className: string; };
  etat: any;
  version: string;
  firstCalTravel = true;

  constructor(
    private realTimeService: RealTimeService,
    private geocodingService: GeocodingService,
    private mapService: MapService,
    private historicalService: HistoricalService,
    public dataManagementService: DataManagementService,

    private pipe: DecimalPipe,
    public toastr: ToastController,
    public translate: TranslateService,
    private signinService: SigninService,
    private globalService: GlobalService,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private popupService: PopupService,
    private router: Router,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    translate.onLangChange.subscribe(lang => {
      this.langChanged = true;
      this.translations = lang.translations;
      try {
        this.getAllRealTimeRecords();
      } catch (e) {

      }
    });
    this.version = version;
  }

  init() {
    this.getTranslation();
    this.mapService.removeAllRtMarkers();
    this.mapService.initCluster();
    this.dataManagementService.GroupsLoaded$.subscribe(groups => this.onGroupLoaded(groups));
    this.dataManagementService.UpdateMarker$.subscribe(hidden => this.updateMarker(this.dataManagementService.selectedVehicule as never, false, !hidden));

    this.dataManagementService.init();
    for (let group of this.dataManagementService.groups)
      for (let vehicule of group.vehicules) {
        vehicule.oldRealTimeRecord = new RealTimeRecord();
        vehicule.realTimeRecord = new RealTimeRecord();
      }
  }



  onGroupLoaded(groups: Group[]) {
    if (this.dataManagementService.groups) {
      this.isGroupLoaded = true;
      this.setFilteredVehchiles(null);
      this.initTimer();
    }
  }

  initTimer() {
    if (this.isGroupLoaded) {
      if (this.liveReloadingRT) {
        clearTimeout(this.liveReloadingRT);
      }
      this.getAllRealTimeRecords();
      this.liveReloadingRT = setInterval(() => {
        this.getAllRealTimeRecords();
      }, 20000);
    }
  }

  loadFocus() {
    if (this.dataManagementService.groups)
      for (let group of this.dataManagementService.groups) {
        for (let vehicule of group.vehicules) {
          if (vehicule && vehicule.realTimeRecord) {
            vehicule.realTimeRecord.forceReload = true;
            vehicule.realTimeRecord.state = RtState.UNINITILIZED;
          }
        }
      }
  }

  getTranslation() {
    if (localStorage.getItem('lang') == null) {
      this.translate.currentLang = 'fr';
      this.translate.setDefaultLang("fr")
      localStorage.setItem('lang', 'fr')
      this.translate.getTranslation("fr").subscribe(res => {
        this.translations = res;
      });
    } else {
      let lang = localStorage.getItem('lang') || '{}';
      this.translate.setDefaultLang(lang)
      this.translate.currentLang = lang;
      this.translate.getTranslation(lang).subscribe(res => {
        this.translations = res;
      });
    }

  }


  getAllRealTimeRecords() {
    this.getCalTravels();

    this.allRealTimeRecords = this.realTimeService
      .getAllRealTimeRecords()
      .subscribe(realTimeRecords => {

        let i = 0;
        //for zoom 
        this.allRealTimeRecords = realTimeRecords;

        realTimeRecords.forEach((realTimeRecord: any) => {
          let vehicules: Vehicule[];
          vehicules = this.getVehicule(realTimeRecord.idRealTimeRecord);
          if (vehicules && vehicules.length) {
            for (let vehicule of vehicules) {
              if (vehicule.device.opt) {
                this.trackRealTimeRecord(vehicule, realTimeRecord);
              }
              if (vehicule.realTimeRecord.state != RtState.UNINITILIZED) {

              }
            }
          }

          //============================================> /RT Notification MANAGER
        });
        this.langChanged = false;
      });
  }

  trackRealTimeRecord(vehicule: Vehicule, realTimeRecord: RealTimeRecord) {
    realTimeRecord.recordTime += this.globalService.time_difference;
    if (!vehicule.realTimeRecord || vehicule.realTimeRecord.recordTime != realTimeRecord.recordTime || vehicule.realTimeRecord.forceReload || !vehicule.realTimeRecord.geocoding || vehicule.realTimeRecord.state != RtState.FILLED) {
      //Update data in structures
      this.realTimeRoutine(vehicule, realTimeRecord);
    }
    if (vehicule.realTimeRecord.state != RtState.UNINITILIZED) {

    }

  }


  /**
   * return number of vehicules
   * */
  getCountVehicule() {
    let count = 0;
    let element: any[] = [];
    if (this.dataManagementService.groups)
      this.dataManagementService.groups.forEach(group => {
        group.vehicules.forEach(vehicule => {
          if (vehicule.device != null)
            element.push(vehicule.device.idDevice);
        });
      });
    var uniqueElements = element.filter(function (item, i, ar) {
      return ar.indexOf(item) === i;
    });
    count = uniqueElements.length;
    return count;
  }

  //To display google adress
  displayGoogleAdress(vehicule: Vehicule) {
    if (vehicule && vehicule.realTimeRecord) {
      this.geocodingService
        .inverseGeocondingGoogle(
          vehicule.realTimeRecord.coordinate.lat,
          vehicule.realTimeRecord.coordinate.lng
        )
        .subscribe(response => {
          if (response && response.results[0]) {
            vehicule.realTimeRecord.geocoding =
              response.results[0].formatted_address;
            response.results.forEach((res: any) => {
              vehicule.realTimeRecord.geocodingDetails +=
                res.formatted_address + " ,"

            });
          }
        });
    }

  }

  SelectGroup(item: Group) {
    this.dataManagementService.selectedGroup = item;
  }

  isInStopFromCalTravel(calTravel: CalTravel): boolean {
    if (!calTravel || calTravel.state == 0 || calTravel.state == 1 || calTravel.state == 2)
      return true;
    return false;
  }

  getCalTravels() {
    if (!this.isGroupLoaded)
      return;
    this.dataManagementService
      .getCalTravelsWithOptions()
      .subscribe(calTravels => {
        calTravels.forEach(caltravel => {
          let vehicules: Vehicule[];
          vehicules = this.dataManagementService.getVehicules(caltravel.deviceId, false);
          if (vehicules && vehicules.length) {
            for (let v of vehicules) {
              //check calTravel changes
              let displayName = this.dataManagementService.getVehiculeName(v);
              if (!this.firstCalTravel && v.calTravel) {
                if (this.isInStopFromCalTravel(v.calTravel) && !this.isInStopFromCalTravel(caltravel)) {
                  this.translate.get('REALTIME.ademarre', ' REALTIME.info').subscribe((res: string) => {
                    //this.toastr.dismiss(displayName + res);
                  });
                }
                if (!this.isInStopFromCalTravel(v.calTravel) && this.isInStopFromCalTravel(caltravel)) {
                  this.translate.get('REALTIME.sestarrete', ' REALTIME.info').subscribe((res: string) => {
                    //this.toastr.dismiss(displayName + res);
                  });
                }
              }
              v.calTravel = caltravel;
              v.odo = v.odoOffset + caltravel.accumOdo;
              v.tfu = caltravel.lastTfu / 2;
              v.tow = caltravel.tow;
              v.fuelLevel = +caltravel.fuelLevel;
            }
          }
          this.firstCalTravel = false;
        });

      });
  }


  compareTwoCoordinate(p1: any, p2: any) {
    if (p1.lat === p2.lat && p1.lng == p2.lng) {
      return true;
    }
    return false;
  }




  // Load dynamiq data
  updateSpecificGroups(realTimeRecord: RealTimeRecord) {
    if (this.dataManagementService.groups) {
      for (let i = 0; i < this.dataManagementService.groups.length; i++) {
        for (let j = 0; j < this.dataManagementService.groups[i].vehicules.length; j++) {
          if (
            this.dataManagementService.groups[i].vehicules[j].device != null && this.dataManagementService.groups[i].vehicules[j].device.idDevice ===
            realTimeRecord.idRealTimeRecord
          ) {
            this.dataManagementService.groups[i].vehicules[j].realTimeRecord = realTimeRecord;
            break;
          }
        }
      }
    }
  }

  distance(lat1: any, lon1: any, lat2: any, lon2: any) {
    let p = 0.017453292519943295; // Math.PI / 180
    let c = Math.cos;
    let a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    let distance = 12742 * Math.asin(Math.sqrt(a));
    return distance;
  }



  getVehicule(idDevice: number): Vehicule[] {
    let foundVehicule: any[] = [];
    if (!this.dataManagementService.groups)
      return [];
    for (let i = 0; i < this.dataManagementService.groups.length; i++) {
      for (let j = 0; j < this.dataManagementService.groups[i].vehicules.length; j++) {
        if (this.dataManagementService.groups[i].vehicules[j].device != null &&
          this.dataManagementService.groups[i].vehicules[j].device.idDevice === idDevice) {
          foundVehicule.push(this.dataManagementService.groups[i].vehicules[j] as never);
        }
      }
    }
    return foundVehicule;

  }

  getGroupimage(idDevice: number) {
    let img = "";
    for (let i = 0; i < this.dataManagementService.groups.length; i++) {
      for (let j = 0; j < this.dataManagementService.groups[i].vehicules.length; j++) {
        if (this.dataManagementService.groups[i].vehicules[j].device.idDevice === idDevice) {
          img = this.dataManagementService.groups[i].imageUrl;
          break;
        }
      }
    }
    return img;
  }

  loadVehiculeById(id: any): string {
    let avatar: any;
    this.dataManagementService.getOne(id).subscribe(
      (vehicule: any) => {
        avatar = vehicule.avatarUri
      }, () => {
        avatar = "";
      });
    return avatar;
  }

  goToRealTimeRecord(group: Group, vehicule: Vehicule) {

    let selectedRTMarker = this.mapService.getRTMarker(vehicule.device.idDevice);

    if (selectedRTMarker && selectedRTMarker.value) {
      selectedRTMarker.value.openPopup();
    }

    if (this.checkLatLon(vehicule.realTimeRecord)) {
      this.mapService.map.setView(vehicule.realTimeRecord.coordinate, 17);
    }

    if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedVehicule.device.idDevice === vehicule.device.idDevice && this.isSelectedVehicule) {
      this.dataManagementService.selectedVehicule = null;
      this.trackingMode = false;
    } else {
      this.dataManagementService.selectedVehicule = vehicule;
      this.isSelectedVehicule = !this.isSelectedVehicule;
      if (group !== null) {
        this.dataManagementService.selectedGroup = group;
      }
      this.dataManagementService.selectedVehicule = vehicule;
      this.trackingMode = true;
    }
  }

  checkValidity(rt: RealTimeRecord): boolean {
    /*if (rt.validity == false)
      return false;*/
    if (rt.coordinate.lat == 0 || rt.coordinate.lng == 0)
      return false;

    return true;
  }
  checkLatLon(rt: RealTimeRecord): boolean {
    if (rt.coordinate.lat == 0 || rt.coordinate.lng == 0)
      return false;

    return true;
  }

  checkMouvement(v: Vehicule): boolean {
    if (v.oldRealTimeRecord.coordinate.lat == v.realTimeRecord.coordinate.lat &&
      v.oldRealTimeRecord.coordinate.lng == v.realTimeRecord.coordinate.lng &&
      v.oldRealTimeRecord.recordTime == v.realTimeRecord.recordTime)
      return false;
    return true;
  }

  realTimeRoutine(vehicule: Vehicule, realTimeRecord: RealTimeRecord) {
    if (!vehicule.realTimeRecord)
      vehicule.realTimeRecord = new RealTimeRecord();
    switch (vehicule.realTimeRecord.state) {
      case RtState.UNINITILIZED:

        vehicule.realTimeRecord = realTimeRecord;
        vehicule.realTimeRecord.state = RtState.WAIT_FOR_GEOCODING;

      case RtState.FILLED:
        vehicule.oldRealTimeRecord = vehicule.realTimeRecord;
        vehicule.realTimeRecord = realTimeRecord;
        vehicule.realTimeRecord.state = RtState.WAIT_FOR_GEOCODING;
        //anyway we have to copy oldgeocoding 
        vehicule.realTimeRecord.relativePosition = vehicule.oldRealTimeRecord.relativePosition;
        vehicule.realTimeRecord.geocoding = vehicule.oldRealTimeRecord.geocoding;
        vehicule.realTimeRecord.geocodingDetails = vehicule.oldRealTimeRecord.geocodingDetails;

      case RtState.WAIT_FOR_GEOCODING:
        vehicule.realTimeRecord.state = RtState.FILLED;
        if (vehicule.realTimeRecord.geocoding &&
          vehicule.realTimeRecord.geocoding != "" &&
          vehicule.realTimeRecord.geocoding != 'Chargement..' &&
          !this.checkMouvement(vehicule) && !vehicule.realTimeRecord.forceReload) {
          //display marker waiting for geocoding
          this.updateMarker(vehicule, false, false);
        } else {
          if (this.checkValidity(vehicule.realTimeRecord)) {
            vehicule.realTimeRecord.relativePosition = null;
            /*this.dataManagementService.fillRtWithRelativePosition(vehicule.realTimeRecord,
              realTimeRecord.coordinate.lat,
              realTimeRecord.coordinate.lng);*///Implemented in the GeocodingPromise
            //display marker waiting fot geocoding
            this.updateMarker(vehicule, true, false);
            vehicule.realTimeRecord.forceReload = false;
            if (!vehicule.realTimeRecord.relativePosition) {
              {
                this.dataManagementService.getGeocodingPosition(vehicule.realTimeRecord, "RT", vehicule.device.idDevice);

                this.updateMarker(vehicule, false, false);

              }

            }
          }
        }
        break;
    }
  }


  updateMarker(vehicule: Vehicule, isNewPoint: boolean, isdetails: boolean) {
    if (vehicule.realTimeRecord.coordinate.lat == 0 || vehicule.realTimeRecord.coordinate.lng == 0)
      return;
    // Declaration of vars !!!
    let angle = 0;
    let date = new Date(vehicule.realTimeRecord.recordTime);
    let minutes = date.getMinutes() + "";
    if (minutes.length === 1) {
      minutes = "0" + date.getMinutes();
    }

    let icon;
    let popup = "";
    let marker;
    if (vehicule.oldRealTimeRecord && vehicule.realTimeRecord.type === "AA") {
      angle = vehicule.realTimeRecord.rotationAngle * 8;
    }

    // ****************************** init angle for gprmc *****************************
    if (vehicule.oldRealTimeRecord && vehicule.realTimeRecord.type === "GPRMC") {
      angle =
        (Math.atan2(
          vehicule.realTimeRecord.coordinate.lng - vehicule.oldRealTimeRecord.coordinate.lng,
          vehicule.realTimeRecord.coordinate.lat - vehicule.oldRealTimeRecord.coordinate.lat
        ) *
          180) /
        Math.PI;
    }

    // ****************************** init driver avatar *****************************
    if (vehicule.driver && vehicule.driver.avatarUri) {
      popup =
        '<img width="40px" class="img-circle" src="' + this.globalService.filesDir + vehicule.driver.avatarUri + '"/>';
    }

    // ******************************  init the RT marker !!! ******************************
    marker = new Marker(vehicule.realTimeRecord.coordinate);
    marker.on("click", () => {
      this.mapService.map.setView(vehicule.realTimeRecord.coordinate, 17);
      this.dataManagementService.selectedVehicule = vehicule;
      this.dataManagementService.selectedGroup = this.dataManagementService.getGroupByVehicule(vehicule.idVehicule);
      this.mapService.map.invalidateSize(true);
    });
    if (this.dataManagementService.selectedVehicule && this.popupService.showBackButton == false && this.router.url === '/client') {
      this.mapService.map.setView(this.dataManagementService.selectedVehicule.realTimeRecord.coordinate, 17);

    }
    // init the RT marker icon !!!
    if (version == 'fasttrack' || version == 'finek') {
      if (vehicule.realTimeRecord.realTimeRecordStatus == 'VALID') {
        //if (deviceopt.useIgnition != 0) {
        if (vehicule.realTimeRecord.ignition == true) {
          if (vehicule.realTimeRecord.speed > 0) {
            if (vehicule.icon == null || vehicule.icon == 'cabriolet' || vehicule.icon == 'automobile.png' || vehicule.icon == "bus2.png") {
              icon = new L.Icon({
                iconUrl: this.globalService.imagesCarsDir + "c1x" + Math.abs(Math.round(angle / 45) * 45) + ".png",
                iconSize: [30, 30],
                iconAnchor: [15, 15],
                popupAnchor: [10, -25]
              });
              if (vehicule.icon == "bus2.png") {
                icon = new L.Icon({
                  iconUrl: this.globalService.imagesCarsDir + "cbus1.png",
                  iconSize: [30, 30],
                  iconAnchor: [15, 15],
                  popupAnchor: [10, -25]
                });
              }
            } else if (!vehicule.icon) {
              icon = new Icon({
                iconUrl: this.globalService.imagesDir + this.getGroupimage(vehicule.device.idDevice),
                shadowUrl: this.globalService.imagesDir + "directionx" + Math.abs(Math.round(angle / 45) * 45) + ".png",
                iconSize: [30, 30],
                shadowSize: [40, 40],
                iconAnchor: [20, 20],
                shadowAnchor: [20, 20],
                popupAnchor: [10, -10]
              });
            } else {
              icon = new Icon({
                iconUrl: this.globalService.imagesCarsDir + vehicule.icon,
                shadowUrl: this.globalService.imagesCarsDir + "directionx" + Math.abs(Math.round(angle / 45) * 45) + ".png",
                iconSize: [40, 40],
                shadowSize: [40, 40],
                iconAnchor: [20, 20],
                shadowAnchor: [20, 20],
                popupAnchor: [10, -10]
              });
            }
            popup = '<ion-badge class="pull-right" style="background: green !important;">' + this.translations.REALTIME.working + '</ion-badge><br>'
              + popup;
            //this.popupOptions = { className: "greenPopup" };
            this.etat = 'green'
          } else {
            if (vehicule.icon == null || vehicule.icon == 'cabriolet' || vehicule.icon == 'automobile.png') {
              icon = new L.Icon({
                iconUrl: this.globalService.imagesCarsDir + "c4x" + Math.abs(Math.round(angle / 45) * 45) + ".png",
                iconSize: [30, 30],
                iconAnchor: [15, 15],
                popupAnchor: [10, -25]
              });
            } else if (vehicule.icon == "bus2.png") {
              icon = new L.Icon({
                iconUrl: this.globalService.imagesCarsDir + "cbus1.png",
                iconSize: [30, 30],
                iconAnchor: [15, 15],
                popupAnchor: [10, -25]
              });
            }
            else if (!vehicule.icon) {
              icon = new L.Icon({
                iconUrl: this.globalService.imagesDir + this.getGroupimage(vehicule.device.idDevice),
                iconSize: [40, 40],
                iconAnchor: [20, 20],
                popupAnchor: [10, -10]
              });
            } else {
              icon = new L.Icon({
                iconUrl: this.globalService.imagesCarsDir + vehicule.icon,
                iconSize: [40, 40],
                iconAnchor: [20, 20],
                popupAnchor: [10, -10]
              });
            }
            popup = '<ion-badge class="pull-right" style="background: blue !important;">' + this.translations.REALTIME.arretprovisoirement
              + '</ion-badge><br>' + popup;
            //this.popupOptions = { className: "bluePopup" };
            this.etat = 'blue'
          }
        } else {
          if (vehicule.icon == null || vehicule.icon == 'cabriolet' || vehicule.icon == 'automobile.png') {
            icon = new L.Icon({
              iconUrl: this.globalService.imagesCarsDir + "c2x" + Math.abs(Math.round(angle / 45) * 45) + ".png",
              iconSize: [30, 30],
              iconAnchor: [15, 15],
              popupAnchor: [10, -10]
            });
          } else if (vehicule.icon == "bus2.png") {
            icon = new L.Icon({
              iconUrl: this.globalService.imagesCarsDir + "cbus1.png",
              iconSize: [30, 30],
              iconAnchor: [15, 15],
              popupAnchor: [10, -25]
            });
          } else if (vehicule.icon.substring(0, 4) == "user") {
            icon = new L.Icon({
              iconUrl: this.filesDir + "/files/" + this.currentUser.id + "/images/cars/" + vehicule.icon.substring(0, vehicule.icon.length - 4) + "_arret.png",
              shadowUrl: this.globalService.imagesDir + "directionx" + Math.abs(Math.round(angle / 45) * 45) + ".png",
              iconSize: [40, 40],
              shadowSize: [40, 40],
              iconAnchor: [20, 20],
              shadowAnchor: [20, 20],
              popupAnchor: [10, -10]
            });
          }
          else if (!vehicule.icon) {
            icon = new L.Icon({
              iconUrl: this.globalService.imagesDir + this.getGroupimage(vehicule.device.idDevice),
              iconSize: [40, 40],
              iconAnchor: [20, 20],
              popupAnchor: [10, -10]
            });
          } else {
            icon = new L.Icon({
              iconUrl: this.globalService.imagesCarsDir + vehicule.icon,
              iconSize: [40, 40],
              iconAnchor: [20, 20],
              popupAnchor: [10, -10]
            });
          }
          popup = '<ion-badge class="pull-right" style="background: red !important;">' + this.translations.OVERVIEW.enarret + '</ion-badge><br>'
            + popup;
          //this.popupOptions = { className: "redPopup" };
          this.etat = 'red'

        }
      }

      if (vehicule.realTimeRecord.realTimeRecordStatus == 'TECHNICAL_ISSUE') {
        if (vehicule.icon == null || vehicule.icon == 'cabriolet' || vehicule.icon == 'automobile.png') {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesCarsDir + "c3x" + Math.abs(Math.round(angle / 45) * 45) + ".png",
            iconSize: [30, 30],
            iconAnchor: [15, 15],
            popupAnchor: [10, -25]
          });
        }
        else if (!vehicule.icon) {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesDir + this.getGroupimage(vehicule.device.idDevice),
            shadowUrl: this.globalService.imagesDir + "danger.png",
            iconSize: [40, 40],
            shadowSize: [40, 40],
            iconAnchor: [20, 20],
            shadowAnchor: [20, 20],
            popupAnchor: [10, -10]
          });
        } else {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesCarsDir + vehicule.icon,
            shadowUrl: this.globalService.imagesCarsDir + "danger.png",
            iconSize: [40, 40],
            shadowSize: [40, 40],
            iconAnchor: [20, 20],
            shadowAnchor: [20, 20],
            popupAnchor: [10, -10]
          });
        }
        popup = '<ion-badge class="pull-right" style="background: #8e8e8e !important;">' + this.translations.REALTIME.panne + '</ion-badge><br>'
          + popup;
        //this.popupOptions = { className: "issuePopup" };
        this.etat = 'panne'
      }

      if (vehicule.realTimeRecord.realTimeRecordStatus == 'NON_VALID') {
        if (vehicule.icon == null || vehicule.icon == 'cabriolet' || vehicule.icon == 'automobile.png') {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesCarsDir + "c3x" + Math.abs(Math.round(angle / 45) * 45) + ".png",
            iconSize: [30, 30],
            iconAnchor: [15, 15],
            popupAnchor: [10, -25]
          });
        }
        else if (!vehicule.icon) {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesDir + this.getGroupimage(vehicule.device.idDevice),
            shadowUrl: this.globalService.imagesDir + "danger.png",
            iconSize: [30, 30],
            shadowSize: [40, 40],
            iconAnchor: [20, 20],
            shadowAnchor: [20, 20],
            popupAnchor: [10, -10]
          });
        } else {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesCarsDir + vehicule.icon,
            shadowUrl: this.globalService.imagesCarsDir + "danger.png",
            iconSize: [40, 40],
            shadowSize: [40, 40],
            iconAnchor: [20, 20],
            shadowAnchor: [20, 20],
            popupAnchor: [10, -10]
          });
        } popup = '<span class="badge bg-yellow pull-right" style="background: #8e8e8e !important;">' + this.translations.OVERVIEW.enarret
          + '</span><br>' + popup;
        this.etat = 'red'
      }

    } else {
      if (vehicule.realTimeRecord.realTimeRecordStatus == 'VALID') {
        //if (deviceopt.useIgnition != 0) {
        if (vehicule.realTimeRecord.ignition == true) {
          if (vehicule.realTimeRecord.speed > 0) {
            if (vehicule.icon == null || vehicule.icon == 'cabriolet' || vehicule.icon == 'automobile.png' || vehicule.icon == "bus2.png") {
              icon = new L.Icon({
                iconUrl: this.globalService.imagesCarsDir + "c1x" + Math.abs(Math.round(angle / 45) * 45) + ".png",
                iconSize: [30, 30],
                iconAnchor: [15, 15],
                popupAnchor: [10, -25]
              });
              if (vehicule.icon == "bus2.png") {
                icon = new L.Icon({
                  iconUrl: this.globalService.imagesCarsDir + "cbus1.png",
                  iconSize: [30, 30],
                  iconAnchor: [15, 15],
                  popupAnchor: [10, -25]
                });
              }
            } else if (vehicule.icon.substring(0, 4) == "user") {
              icon = new L.Icon({
                iconUrl: this.filesDir + "/files/" + this.currentUser.id + "/images/cars/" + vehicule.icon,
                shadowUrl: this.globalService.imagesDir + "directionx" + Math.abs(Math.round(angle / 45) * 45) + ".png",
                iconSize: [40, 40],
                shadowSize: [40, 40],
                iconAnchor: [20, 20],
                shadowAnchor: [20, 20],
                popupAnchor: [10, -10]
              });
            } else if (!vehicule.icon) {
              icon = new Icon({
                iconUrl: this.globalService.imagesDir + this.getGroupimage(vehicule.device.idDevice),
                shadowUrl: this.globalService.imagesDir + "directionx" + Math.abs(Math.round(angle / 45) * 45) + ".png",
                iconSize: [30, 30],
                shadowSize: [40, 40],
                iconAnchor: [20, 20],
                shadowAnchor: [20, 20],
                popupAnchor: [10, -10]
              });
            } else {
              icon = new Icon({
                iconUrl: this.globalService.imagesCarsDir + vehicule.icon,
                shadowUrl: this.globalService.imagesCarsDir + "directionx" + Math.abs(Math.round(angle / 45) * 45) + ".png",
                iconSize: [40, 40],
                shadowSize: [40, 40],
                iconAnchor: [20, 20],
                shadowAnchor: [20, 20],
                popupAnchor: [10, -10]
              });
            }
            popup = '<ion-badge class="pull-right" style="background: green !important;">' + this.translations.REALTIME.working + '</ion-badge><br>'
              + popup;
            //this.popupOptions = { className: "greenPopup" };
            this.etat = 'green'
          } else {
            if (vehicule.icon == null || vehicule.icon == 'cabriolet' || vehicule.icon == 'automobile.png') {
              icon = new L.Icon({
                iconUrl: this.globalService.imagesCarsDir + "c4x" + Math.abs(Math.round(angle / 45) * 45) + ".png",
                iconSize: [30, 30],
                iconAnchor: [15, 15],
                popupAnchor: [10, -25]
              });
            } else if (vehicule.icon == "bus2.png") {
              icon = new L.Icon({
                iconUrl: this.globalService.imagesCarsDir + "cbus1.png",
                iconSize: [30, 30],
                iconAnchor: [15, 15],
                popupAnchor: [10, -25]
              });
            }
            else if (!vehicule.icon) {
              icon = new L.Icon({
                iconUrl: this.globalService.imagesDir + this.getGroupimage(vehicule.device.idDevice),
                iconSize: [40, 40],
                iconAnchor: [20, 20],
                popupAnchor: [10, -10]
              });
            } else {
              icon = new L.Icon({
                iconUrl: this.globalService.imagesCarsDir + vehicule.icon,
                iconSize: [40, 40],
                iconAnchor: [20, 20],
                popupAnchor: [10, -10]
              });
            }
            popup = '<ion-badge class="pull-right" style="background: blue !important;">' + this.translations.REALTIME.arretprovisoirement
              + '</ion-badge><br>' + popup;
            //this.popupOptions = { className: "bluePopup" };
            this.etat = 'blue'
          }
        } else {
          if (vehicule.icon == null || vehicule.icon == 'cabriolet' || vehicule.icon == 'automobile.png') {
            icon = new L.Icon({
              iconUrl: this.globalService.imagesCarsDir + "c2x" + Math.abs(Math.round(angle / 45) * 45) + ".png",
              iconSize: [30, 30],
              iconAnchor: [15, 15],
              popupAnchor: [10, -10]
            });
          } else if (vehicule.icon == "bus2.png") {
            icon = new L.Icon({
              iconUrl: this.globalService.imagesCarsDir + "cbus1.png",
              iconSize: [30, 30],
              iconAnchor: [15, 15],
              popupAnchor: [10, -25]
            });
          } else if (vehicule.icon.substring(0, 4) == "user") {
            icon = new L.Icon({
              iconUrl: this.filesDir + "/files/" + this.currentUser.id + "/images/cars/" + vehicule.icon.substring(0, vehicule.icon.length - 4) + "_arret.png",
              shadowUrl: this.globalService.imagesDir + "directionx" + Math.abs(Math.round(angle / 45) * 45) + ".png",
              iconSize: [40, 40],
              shadowSize: [40, 40],
              iconAnchor: [20, 20],
              shadowAnchor: [20, 20],
              popupAnchor: [10, -10]
            });
          }
          else if (!vehicule.icon) {
            icon = new L.Icon({
              iconUrl: this.globalService.imagesDir + this.getGroupimage(vehicule.device.idDevice),
              iconSize: [40, 40],
              iconAnchor: [20, 20],
              popupAnchor: [10, -10]
            });
          } else {
            icon = new L.Icon({
              iconUrl: this.globalService.imagesCarsDir + vehicule.icon,
              iconSize: [40, 40],
              iconAnchor: [20, 20],
              popupAnchor: [10, -10]
            });
          }
          popup = '<ion-badge class="pull-right" style="background: red !important;">' + this.translations.OVERVIEW.enarret + '</ion-badge><br>'
            + popup;
          //this.popupOptions = { className: "redPopup" };
          this.etat = 'red'

        }
      }

      if (vehicule.realTimeRecord.realTimeRecordStatus == 'TECHNICAL_ISSUE') {
        if (vehicule.icon == null || vehicule.icon == 'cabriolet' || vehicule.icon == 'automobile.png') {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesCarsDir + "c3x" + Math.abs(Math.round(angle / 45) * 45) + ".png",
            iconSize: [30, 30],
            iconAnchor: [15, 15],
            popupAnchor: [10, -25]
          });
        } else if (vehicule.icon.substring(0, 4) == "user") {
          icon = new L.Icon({
            iconUrl: this.filesDir + "/files/" + this.currentUser.id + "/images/cars/" + vehicule.icon.substring(0, vehicule.icon.length - 4) + "_panne.png",
            shadowUrl: this.globalService.imagesDir + "directionx" + Math.abs(Math.round(angle / 45) * 45) + ".png",
            iconSize: [40, 40],
            shadowSize: [40, 40],
            iconAnchor: [20, 20],
            shadowAnchor: [20, 20],
            popupAnchor: [10, -10]
          });
        }
        else if (!vehicule.icon) {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesDir + this.getGroupimage(vehicule.device.idDevice),
            shadowUrl: this.globalService.imagesDir + "danger.png",
            iconSize: [40, 40],
            shadowSize: [40, 40],
            iconAnchor: [20, 20],
            shadowAnchor: [20, 20],
            popupAnchor: [10, -10]
          });
        } else {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesCarsDir + vehicule.icon,
            shadowUrl: this.globalService.imagesCarsDir + "danger.png",
            iconSize: [40, 40],
            shadowSize: [40, 40],
            iconAnchor: [20, 20],
            shadowAnchor: [20, 20],
            popupAnchor: [10, -10]
          });
        }
        popup = '<ion-badge class="pull-right" style="background: #8e8e8e !important;">' + this.translations.REALTIME.panne + '</ion-badge><br>'
          + popup;
        //this.popupOptions = { className: "issuePopup" };
        this.etat = 'panne'
      }

      if (vehicule.realTimeRecord.realTimeRecordStatus == 'NON_VALID') {
        if (vehicule.icon == null || vehicule.icon == 'cabriolet' || vehicule.icon == 'automobile.png') {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesCarsDir + "c3x" + Math.abs(Math.round(angle / 45) * 45) + ".png",
            iconSize: [30, 30],
            iconAnchor: [15, 15],
            popupAnchor: [10, -25]
          });
        }
        else if (!vehicule.icon) {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesDir + this.getGroupimage(vehicule.device.idDevice),
            shadowUrl: this.globalService.imagesDir + "danger.png",
            iconSize: [30, 30],
            shadowSize: [40, 40],
            iconAnchor: [20, 20],
            shadowAnchor: [20, 20],
            popupAnchor: [10, -10]
          });
        } else {
          icon = new L.Icon({
            iconUrl: this.globalService.imagesCarsDir + vehicule.icon,
            shadowUrl: this.globalService.imagesCarsDir + "danger.png",
            iconSize: [40, 40],
            shadowSize: [40, 40],
            iconAnchor: [20, 20],
            shadowAnchor: [20, 20],
            popupAnchor: [10, -10]
          });
        } popup = '<span class="badge bg-yellow pull-right" style="background: #8e8e8e !important;">' + this.translations.REALTIME.pasdesignalGPS
          + '</span><br>' + popup;
        this.etat = 'gray'
      }
    }
    // update the RT marker 
    popup =
      popup +
      "<hr><b>" +
      vehicule.realTimeRecord.geocoding +
      "</b><br>";
    if (vehicule.oldRealTimeRecord?.relativePosition) {
      popup =
        popup +
        '<hr><center><span class="leaflet-pelias-layer-icon-container"><div class="leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point"></div></span><strong>' +
        vehicule.realTimeRecord.relativePosition +
        "</strong></center>";
    }
    this.popupOptions = { className: "realTimePopup" };
    let component = this.resolver.resolveComponentFactory(PopupRtComponent).create(this.injector);
    component.instance.loadVehicule(vehicule, this.etat, this.translations, marker, isdetails);
    component.changeDetectorRef.detectChanges();
    marker.bindPopup(component.location.nativeElement, this.popupOptions);

    try {
      this.mapService.updateRtMarker(
        {
          id: vehicule.realTimeRecord.idRealTimeRecord,
          value: marker,
          icon: icon,
          angle: 0
        },
        false

        /*
         this.popupService.isCurrentPathClicked,
         this.trackingMode,
         isNewPoint*/
      );
    } catch (e) { }
  }

  drawPointInterests() {
    this.mapService.removeMarkersFromMap();
    this.dataManagementService.pointInterests.forEach(pointInterest => {
      let popup =
        "Nom : " +
        pointInterest.name +
        "<br><hr><b>Adresse : " +
        pointInterest.address +
        "</b>";
      let marker = new Marker(pointInterest.coordinate);
      marker.bindPopup(popup);
      marker.setIcon(
        new Icon({
          iconUrl: pointInterest.imageUri,
          iconAnchor: [-2, 30],
          popupAnchor: [10, -25]
        })
      );
      if (pointInterest.name.substring(0, 4) == "user") {
        marker.setIcon(
          new Icon({
            iconUrl: pointInterest.imageUri,
            iconAnchor: [-2, 30],
            popupAnchor: [10, -25]
          })
        );
      }
      marker.on("click", () => {
        this.mapService.map.setView(pointInterest.coordinate, 18);
      });
      this.mapService.addMarker(marker);
    });
  }

  displayCurrentPath(deviceId: number) {
    this.mapService.removePolylinesFromMap();
    this.mapService.removeMarkersFromMap();
    this.historicalService.getCurrentPath(deviceId).subscribe(
      currentPath => {
        if (currentPath != null) {
          let polyline = L.polyline(currentPath.coordinates, {
            color: "#0031D9",
            weight: 3
          });
          currentPath.coordinates.forEach((coordinate: any, i: any) => {
            let marker = new Marker(coordinate);
            if (i != 0 && i != currentPath.coordinates.length - 1) {
              marker.setIcon(
                new Icon({
                  iconUrl: this.globalService.imagesDir + "green-point.png",
                  iconAnchor: [2, 2]
                })
              );
              let pointDatePipe = new DatePipe(
                coordinate.date + +this.time_difference + this.time_difference
              );
              let heure;
              this.translate.get('REALTIME.heure').subscribe((res: string) => {
                heure = res;
              });
              let speed;
              this.translate.get('REALTIME.vitesse').subscribe((res: string) => {
                speed = res;
              });
              let popup =
                "<b>" + heure + ":</b> " +
                pointDatePipe.transform(
                  coordinate.date,
                  "dd/MM/yyyy HH:mm:ss", `en-US`
                ) +
                "<b><br>" + speed + "</b> " +
                coordinate.speed +
                " Km/h";
              marker.bindPopup(popup);
              // marker.on("click", () => {
              //   this.mapService.map.setView(coordinate, 17);
              // });
              this.mapService.addMarker(marker);
            }
          });
          let startMarker = new Marker({
            lat: currentPath.beginPathLatitude,
            lng: currentPath.beginPathLongitude
          });
          let startTime: any =
            currentPath.beginPathTime + this.time_difference + this.time_difference;
          let startDatePipe = new DatePipe(startTime);
          let time;
          this.translate.get('REALTIME.tempsdedebutdutrajet').subscribe((res: string) => {
            time = res;
          });
          startMarker.bindPopup(
            "<b> <i>[lat: </b>" +
            currentPath.beginPathLatitude +
            "<b> ,long: </b>" +
            currentPath.beginPathLongitude +
            "<b>]</i><br>" + time + " </b>" +
            startDatePipe.transform(startTime, "dd/MM/yyyy HH:mm:ss", `en-US`)
          );
          startMarker.setIcon(
            new Icon({
              iconUrl: this.globalService.imagesDir + "startMarker.png",
              iconAnchor: [-2, 30],
              popupAnchor: [10, -25]
            })
          );
          this.mapService.addMarker(startMarker);
          this.mapService.addPolyline(polyline);
          this.isCurrentPathClicked = true;
          this.currentPathClickedDeviceId = deviceId;
        }
      },
      err => {
      }
    );
  }

  clearPolylines() {
    this.mapService.removePolylinesFromMap();
    this.mapService.removeMarkersFromMap();
    this.isCurrentPathClicked = false;
  }

  displayTodaysMileage(vehicule: Vehicule) {
    this.mapService.removePolylinesFromMap();
    this.mapService.removeMarkersFromMap();
    let today = new Date();
    this.historicalService
      .getAllMileages(this.dataManagementService.selectedVehicule!.idDevice, {
        startDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          0,
          0,
          0
        ),
        endDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          23,
          59,
          59
        )
      })
      .subscribe(
        mileages => {
          vehicule.mileage = this.historicalService.mileages[mileages.length - 1];
        },
        () => { }
      );

    let date = new Date();
    date.setHours(0, 0, 0, 0);
    let startHour: any = date.getTime();
    this.historicalService
      .getTodaysMileage(vehicule.device.idDevice, {
        startDate: startHour,
        endDate: startHour
      })
      .subscribe(
        mileage => {
          let points = mileage.points;
          let stops = mileage.stops;
          let polyline = L.polyline(points, { color: "#0031D9", weight: 3 });

          points.forEach((coordinate: any, i: any) => {
            let marker = new Marker(coordinate);
            if (i != 0 && i != points.length - 1) {
              marker.setIcon(
                new Icon({
                  iconUrl: this.globalService.imagesDir + "green-point.png",
                  iconAnchor: [2, 2]
                })
              );
              let pointDatePipe = new DatePipe(coordinate.date);
              let popup =
                "<b>Heure:</b> " +
                pointDatePipe.transform(
                  coordinate.date, "dd/MM/yyyy HH:mm:ss", `en-US`) +
                "<b><br>vitesse:</b> " +
                coordinate.speed +
                " Km/h";
              marker.bindPopup(popup);
              // marker.on("click", () => {
              //   this.mapService.map.setView(coordinate, 17);
              // });
              this.mapService.addMarker(marker);
            }
          });

          stops.forEach((stop: any, i: any) => {
            let marker = new Marker({
              lat: stop.stopLatitude,
              lng: stop.stopLongitude
            });
            let pointDatePipe = new DatePipe(stop.beginStopTime);
            let popup =
              "<b>Date debut d'arrêt: </b>" +
              pointDatePipe.transform(
                stop.beginStopTime, "dd/MM/yyyy HH:mm:ss", `en-US`) +
              "<br><b>Durée d'arrêt: </b>" +
              stop.stopDurationStr;
            marker.setIcon(
              new Icon({
                iconUrl: this.globalService.imagesDir + "stop_smal.png",
                iconSize: [20, 20],
                iconAnchor: [10, 10],
                popupAnchor: [10, -25]
              })
            );
            marker.bindPopup(popup);
            // marker.on("click", () => {
            //   this.mapService.map.setView(
            //     { lat: stop.stopLatitude, lng: stop.stopLongitude },
            //     17
            //   );
            // });
            this.mapService.addMarker(marker);
          });
          this.mapService.addPolyline(polyline);
          let middle = points[Math.round((points.length - 1) / 2)];

          //map center offset(axis y)
          this.mapService.offsetMap(middle, 12, 0, -120);

        });
  }



  startEngine(deviceId: any) {
    if (confirm("es vous sur ? (Lancement moteur)")) {
      this.realTimeService.startEngine(deviceId).subscribe(cmdSended => {
        this.translate.get('REALTIME.commandearretmoteurenvoye').subscribe((res: string) => {
          alert(res);
        });
      });
    }
  }

  stopEngine(deviceId: any) {
    if (confirm("es vous sur ? (Arrêt moteur)")) {
      this.realTimeService.stopEngine(deviceId).subscribe(cmdSended => {
        this.translate.get('REALTIME.commandearretmoteurenvoye').subscribe((res: string) => {
          alert(res);
        });
      });
    }
  }

  //Decimale Pipe function
  PipeLngLat(value: any) {
    return this.pipe.transform(value, "2.2-6");
  }

  isValidPoint(point: { lat: number; lng: number }) {
    if (point && point.lat !== 0 && point.lng !== 0) {
      return true;
    } else
      return false;
  }


  //To Close the Dashboard of vehicule
  closeDashboard() {
    document!.getElementById("container-car")!.style.display = 'none';

  }

  setFilteredVehchiles(searchVehicule: any) {
    if (!searchVehicule) {
      this.filteredVehicules = this.dataManagementService.getAllVehiculesFromGroups();
    }
    this.filteredVehicules = this.dataManagementService.filterVehicules(searchVehicule) || [];

    console.log(this.filteredVehicules.length);
  }

  isInFilter(v: Vehicule): boolean {
    if (!this.filteredVehicules || this.filteredVehicules.length == 0)
      return true;
    for (let i = 0; i < this.filteredVehicules.length; i++)
      if (v.idVehicule == this.filteredVehicules[i].idVehicule)
        return true;
    return false;
  }


  displayRTPath(deviceId: number, latBegin: any, lngBegin: any, timeBegin: any, speed: number) {
    let vehicule = this.getVehicule(deviceId)[0]
    let lat = vehicule.realTimeRecord.coordinate.lat
    let lng = vehicule.realTimeRecord.coordinate.lng
    this.mapService.centerMapMarker(lat, lng);
    this.mapService.removePolylinesFromMap();
    this.mapService.removeMarkersFromMap();
    this.historicalService.getCurrentPath(deviceId).subscribe(
      currentPath => {
        if (currentPath != null) {

          let beginPointsIndex = currentPath.coordinates.findIndex((e: any) => e.lat == latBegin && e.lng == lngBegin)

          let trameRtTrack = currentPath.coordinates.slice(beginPointsIndex)

          let polyline = L.polyline(trameRtTrack,
            {
              color: "#13CC00",
              weight: 3
            }).addTo(this.mapService.map);
          this.mapService.addPolyline(polyline);

          let decorator = L.polylineDecorator(polyline,
            {
              patterns: [
                {
                  offset: '100%',
                  repeat: 0,
                  symbol: L.Symbol.dash({
                    pixelSize: 10,
                  })
                }
              ]
            }

          );
          this.mapService.addPolyline(decorator);
          let time;
          this.translate.get('Temps de debut du trajet').subscribe((res: string) => {
            time = res;
          });


          let startTrackMarker = new Marker({
            lat: latBegin,
            lng: lngBegin
          });
          let startTrackDatePipe = new DatePipe(timeBegin);
          startTrackMarker.bindPopup(
            "<b> <i>[Speed: </b>" +
            speed +
            // "<b> ,long: </b>" +
            // lngBegin +
            "<b>]</i><br>" + time + " </b>" +
            startTrackDatePipe.transform(timeBegin, "dd/MM/yyyy HH:mm:ss", `en-US`)
          );
          startTrackMarker.setIcon(
            new Icon({
              iconUrl: this.globalService.imagesDir + "startMarker.png",
              iconAnchor: [-2, 30],
              popupAnchor: [10, -25]
            })
          );

          this.mapService.addMarker(startTrackMarker);
          this.isCurrentPathClicked = true;
          this.currentPathClickedDeviceId = deviceId;
        }

      },
      err => {
      }
    );
  }

}
