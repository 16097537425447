import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { DataManagementService } from '../../../data-management/data-management.service';
import { Subscription } from 'rxjs';
import { Vehicule, Group } from 'src/app/model/data-managemnt';
import { RealTimeRecord } from 'src/app/model/real-time';
import { HistoricalService } from '../../historical.service';
import { CalendarModalComponent } from '../../calendar-modal/calendar-modal.component';
import { RealTimeService } from 'src/app/pages/realtime/realtime.service';

@Component({
  selector: 'app-path-modal',
  templateUrl: './path-modal.component.html',
  styleUrls: ['./path-modal.component.scss']
})
export class PathModalComponent implements OnInit {

  @Input() showContent:any;
  modalTitle: string;
  modelId: number;
  //groups: Group[];
 // selectedGroup: Group = new Group();
  //selectedVehicule: Vehicule |null;
  AllGroups: Subscription;
  AllPaths: Subscription;

  @ViewChild(CalendarModalComponent, { static: false }) calendarModalComponent: CalendarModalComponent;

  constructor(
    private popoverController: PopoverController,
    private navParams: NavParams, public dataManagementService: DataManagementService, private realTimeService: RealTimeService,
    public historicalService: HistoricalService
  ) { }

  ngOnInit() {
    this.modelId = this.navParams.data.paramID;
    this.modalTitle = this.navParams.data.paramTitle;

    if (this.dataManagementService.groups) {
      this.dataManagementService.groups = this.dataManagementService.groups.filter(
        group => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        if (this.dataManagementService.selectedVehicule) {
          this.dataManagementService.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.dataManagementService.selectedGroup.vehicules.length > 0) {
          this.dataManagementService.selectedVehicule = this.dataManagementService.selectedGroup.vehicules[0];
        }
      } else {
        if (this.dataManagementService.groups.length > 0 && this.dataManagementService.groups) {
          this.dataManagementService.selectedGroup = this.dataManagementService.groups[0];
        }
      }
      this.dataManagementService.selectedVehicule = this.dataManagementService.selectedVehicule;
    } else {
      this.AllGroups = this.dataManagementService
        .getAllGroupsDetails("")
        .subscribe(groups => {
          this.dataManagementService.groups = groups;
          this.dataManagementService.groups.forEach(group => {
            group.vehicules.forEach(vehicule => {
              vehicule.realTimeRecord = new RealTimeRecord();
              this.realTimeService.getRealTimeRecord(vehicule.idDevice).subscribe(x => {

                vehicule.realTimeRecord = x;

              }, (err) => {

              });
            });
          });

          this.dataManagementService.groups = groups.filter((group:any) => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          if (this.dataManagementService.groups.length > 0 && this.dataManagementService.groups) {
            this.dataManagementService.selectedGroup = this.dataManagementService.groups[0];
            if (this.dataManagementService.selectedGroup && this.dataManagementService.selectedGroup.vehicules.length > 0) {
              this.dataManagementService.selectedVehicule = this.dataManagementService.selectedGroup.vehicules[0];
         
            }
          }
        });
    }
  }


  chooseVehicule(vehicule:any) {
    this.dataManagementService.selectedVehicule = vehicule;
  }

  chooseGroup(group:any) {
    this.dataManagementService.selectedGroup = group;
    if (this.dataManagementService.selectedGroup!.vehicules.length > 0 && this.dataManagementService.selectedGroup) {
      this.dataManagementService.selectedVehicule = this.dataManagementService.selectedGroup.vehicules[0];
    }
  }

  getAllPaths() {
    this.historicalService.startDate = new Date(this.calendarModalComponent.startDate);
    this.historicalService.endDate = new Date(this.calendarModalComponent.endDate);
    this.historicalService.getAllPathRoutine();
    this.showContent = true
  }

  async closePopover() {
    //  const onClosedData: string = "";
    await this.popoverController.dismiss('cancel');
  }

}
