import { Injectable } from '@angular/core';
import { DateInterval } from 'src/app/model/date-interval.model';
import { Observable } from 'rxjs';
import { createAuthorizationHeader } from 'src/app/utils/headers';
import { GlobalService } from 'src/app/global.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SigninService } from 'src/app/signin/signin.service';

@Injectable({
  providedIn: 'root'
})
export class AlertManagementService {

  constructor(private signinService : SigninService,
    private _http: HttpClient, 
    private globalService:GlobalService) { }

  getNotifications(dateInterval: DateInterval): Observable<any> {

    let headers = createAuthorizationHeader();
    return this._http.post( this.globalService.dns + 'alerts/notifications/'+this.signinService.isRootAdmin()+"/"+this.signinService.getUserId(), dateInterval, {headers: headers});
  }
}
