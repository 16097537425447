import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-calendar-modal',
  templateUrl: './calendar-modal.component.html',
  styleUrls: ['./calendar-modal.component.scss'],
})
export class CalendarModalComponent implements OnInit {
  @Input()
  start :Date ;
  startDate : string;
 
  @Input()
  end :Date ;
  endDate : string;

  constructor(
  ) {

  }

  ngOnInit() {
    this.startDate =   this.start.toISOString();
    this.endDate = this.end.toISOString();
   }

  
}