import { Component, OnInit } from '@angular/core';
import { NavController, ToastController, LoadingController, PopoverController } from '@ionic/angular';
import { SigninService } from '../signin/signin.service';
import { GlobalService, version, mobile } from '../global.config';
import { RealTimeRecord } from '../model/real-time';
import { Group } from '../model/data-managemnt';
import { User } from '../signin/credentials';
import { Credentials } from '../signin/credentials';
import { Router } from '@angular/router';
import { AddServerComponent, NewServer } from './add-server/add-server.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  version: String = version;
  mobile: string = mobile;

  token: any;
  buttonColor: string = 'linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%)';
  loader: any;
  showing: boolean;
  groups: Group[];
  login: String;
  mdp: String;
  currentuser: User;
  allGroups: Subscription;
  newRealTimeRecords: RealTimeRecord[];
  oldRealTimeRecords: RealTimeRecord[];
  allRealTimeRecords: Subscription;
  searchWord: string = '';
  loginTest: String;
  mdpTest: String;
  server: string = '';
  credentials: Credentials;
  loading: boolean;
  photo: String;
  newServers: NewServer[] = [];
  isActiveToggleTextPassword: Boolean = true;
  
  constructor(
    public loadingCtrl: LoadingController,
    public navCtrl: NavController,
    private signinService: SigninService,
    private toastController: ToastController,
    private globalService: GlobalService,
    public router: Router,
    private popoverController: PopoverController,
    public translate: TranslateService) {
    this.currentuser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.credentials = new Credentials();
    if (this.currentuser && this.currentuser.username) {
      this.credentials.username = this.currentuser.username;
      this.credentials.password = this.currentuser.password;
      this.server = localStorage.getItem('server') || '{}';
      this.globalService.setservername(this.server);
      this.Signing();
    }

  }

  ngOnInit() {
    if (localStorage.getItem('lang') == null) {
      this.translate.currentLang = 'fr';
      this.translate.setDefaultLang("fr")
      localStorage.setItem('lang', 'fr')
      this.translate.use('fr');
    } else {
      let lang = localStorage.getItem('lang') || '{}'
      this.translate.setDefaultLang(lang)
      this.translate.currentLang = lang;
      this.translate.use(lang);
    }
  }
  switchLanguage(lang: any) {
    this.translate.use(lang);
    if (lang == "fr") {
      this.languageConfirmationMessage("Langue Français séléctionné");
      localStorage.setItem('lang', 'fr');
    }
    if (lang == "en") {
      this.languageConfirmationMessage("Language English selected");
      localStorage.setItem('lang', 'en');
    }
    if (lang == "ar") {
      this.languageConfirmationMessage("اللغة العربية المختارة");
      localStorage.setItem('lang', 'ar');
    }
  }

  async languageConfirmationMessage(message: any) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }


  getMyStyles() {
    let myStyles;
    if (this.version == 'finek') {
      myStyles = {
        'background-image': 'url(https://finek.com.tn/images/mobile/road.jpg)',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': ' center'
      };
    } else if (this.version == 'fleet') {
      myStyles = {
        'background-image': 'url(https://fleet.tn/images/mobile/fleetLogin.jpg)',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': ' center'
      };
    } else if (this.version == 'traci') {
      myStyles = {
        'background-image': 'url(https://traci.tn/images/mobile/6.jpg)',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': ' center'
      };
    } else if (this.version == 'click') {
      myStyles = {
        'background-image': 'url(https://traci.tn/images/mobile/6.jpg)',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': ' center'
      };
    }
    else if (this.version == 'tomtom') {
      myStyles = {
        'background-image': 'url(https://traci.tn/images/tomtombg.png)',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': ' center'
      };
    }
    else if (this.version == 'digitecom') {
      myStyles = {
        'background-image': 'url(https://traci.tn/images/mobile/6.jpg)',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': ' center'
      };
    }
    else if (this.version == 'slama') {
      myStyles = {
        'background-image': 'url(https://slama.fleet.tn/images/mobile/fleetLogin.jpg)',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': ' center'
      };
    } else if (this.version == 'mekatech') {
      myStyles = {
        'background-image': 'url(https://mekatech.tn/images/mobile/mekateckback.jpg)',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': ' center'
      };
    } else if (this.version == 'tchycotrack') {
      myStyles = {
        'background-image': 'url(https://fleet.tn/images/mobile/fleetLogin.jpg)',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': ' center'
      };
    } else if (this.version == 'fasttrack') {
      myStyles = {
        'background-image': 'url(https://traci.tn/images/login_fastrack.png)',
        'background-size': 'cover',
        'background-repeat': 'round',
        'background-position': ' center'
      };
    } else if (this.version == 'dreamgps') {
      myStyles = {
        'background-image': 'url(https://fleet.tn/images/mobile/fleetLogin.jpg)',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': ' center'
      };
    }
    return myStyles;
  }

  Signing() {
    this.loading = true;

    if (localStorage.getItem('id_token')) {
      localStorage.removeItem('id_token');
    }
    this.signinService.login(this.credentials).subscribe(
      (currentUser: any) => {
        if (currentUser == null) {
          this.displayError("Vous n'êtes pas autorisé!");
        } else {
          this.loading = false;
          localStorage.setItem('id_token', currentUser.token);
          currentUser.password = this.credentials.password;
          localStorage.setItem('currentUser', JSON.stringify(currentUser) || '{}');
          localStorage.setItem('server', this.globalService.getservername());
          this.router.navigate(['client']);
          this.signinService.authState = true;
          this.credentials.username = "";
          this.credentials.password = "";
        }
      },
      (error: any) => {
        if (error.status == 401) {
          if (this.version == 'slama') {
            this.displayStatus("Compte Expiré! Merci de contacter +21623307266");
          } else if (this.version == 'fleet') {
            this.displayStatus("Compte Expiré! Merci de contacter +21623307266");
          } else if (this.version == 'traci') {
            this.displayStatus("Compte Expiré! Merci de contacter votre adminstrateur");
          } else if (this.version == 'click') {
            this.displayStatus("Compte Expiré! Merci de contacter votre adminstrateur");
          } else if (this.version == 'tomtom') {
            this.displayStatus("Compte Expiré! Merci de contacter votre adminstrateur");
          } else if (this.version == 'finek') {
            this.displayStatus("Compte Expiré! Merci de contacter votre adminstrateur");
          } else if (this.version == 'digitecom') {
            this.displayStatus("Compte Expiré! Merci de contacter votre adminstrateur");
          } else if (this.version == 'mekatech') {
            this.displayStatus("Compte Expiré! Merci de contacter votre adminstrateur");
          } else if (this.version == 'tchycotrack') {
            this.displayStatus("Compte Expiré! Merci de contacter votre adminstrateur");
          } else if (this.version == 'fasttrack') {
            this.displayStatus("Compte Expiré! Merci de contacter votre adminstrateur");
          }
        } else {
          if (this.credentials.username === "" && this.credentials.password === "") {
            this.displayError("Login and password must be filled !");
          } else {
            if (this.credentials.password === "") {
              this.displayError("Password must be filled !");
            } else if (this.credentials.username === "") {
              this.displayError("UserName Must be filled !");
            } else {
              this.displayError("Login ou mot de passe non valid !");
            }
          }
        }
        this.credentials.username = "";
        this.credentials.password = "";
        this.loading = false;
      }
    );
  }

  async displayError(message: string) {
    let toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    await toast.present();
  }

  async displayStatus(message: string) {
    let toast = await this.toastController.create({
      message: message,
    });
    await toast.present();
  }

  updateservername() {
    localStorage.setItem('server', this.server)
    this.globalService.setservername(this.server);
  }

  async addServer(ev: any) {
    const popover = await this.popoverController.create({
      component: AddServerComponent,
      cssClass: 'myPopoverStyle',
      //event: ev,
      translucent: true,
      id: "addServerPopover"
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  addServerOnClick() {
    if (localStorage.getItem('newServers'))
      this.newServers = JSON.parse(localStorage.getItem('newServers') || '{}')
  }

    public toggleTextPassword(): void{
      this.isActiveToggleTextPassword = (this.isActiveToggleTextPassword==true)?false:true;
  }
  public getType() {
      return this.isActiveToggleTextPassword ? 'password' : 'text';
  }

}


