import { RealTimeRecord, CalTravel } from '../model/real-time';
import { DateInterval } from '../model/date-interval.model';
import { DeviceOpt, DeviceCarb, DeviceTemp } from '../model/technical-data';
import { User } from '../signin/credentials';
import { Mileage } from './historical';


export class PointInterest {

  idPointInterest: number;
  name: string;
  address: string;
  ray: number;
  zoomLevel: number;
  imageUri: string;
  coordinate: PointInterestCoordinate =new PointInterestCoordinate();
  type: 'MARKER' | 'POLYGON' | 'LINE' | number;
  decode: any[];
  hash: string;
  user: User[];
  codeClient:string;

  constructor() {
    this.ray = 100;
    this.decode = [];
    this.user = [];
  }

}

export class PointInterestCoordinate { 
  lat: number;
  lng: number 
}

export class GroupPOI {
  id: number;
  name: string;
  markerUrl: string;
  pointInterests: PointInterest[];
  idPointInterests: number[];
  user: User[];

  constructor() {
    this.pointInterests = [];
    this.user = [];
  }
}

export class GroupMinifyDto {
  idGroupe: number;
  nom: string;

  constructor() {
    this.idGroupe = 0;
    this.nom = '';
  }
}

export class VehiculesMinifyDto {
  idVehicule: number;
  matricule: string;
  mark: string;
  icon: string;
  idDevice: number;

  constructor() {
    this.idVehicule = 0;
    this.idDevice = 0;
    this.matricule = '';
    this.mark = '';
    this.icon = '';
  }
}

export class GroupsVehicules {
  vehicules: VehiculesMinifyDto[];
  groups: GroupMinifyDto[];

  constructor() {
    this.vehicules = [];
    this.groups = [];
  }
}

export class GroupDto {
  idGroupe: number;
  nom: string;
  imageUrl: string;
  vehicules: VehiculesDto[];

  constructor() {
    this.vehicules = [];
  }
}

export class VehiculesDto {
  idVehicule: number;
  matricule: string;
  mark: string;
  icon: string;
  idDevice: number;

  constructor() {

  }
}

export class RapportPayloadDto {

  idVehicules: number[];
  idGroups: number[];
  allVehicules: boolean;
  byVehicules: boolean;
  byGroups: boolean;
  global: boolean;

  constructor() {
    this.idVehicules = [];
    this.idGroups = [];
    this.allVehicules = false;
    this.byVehicules = false;
    this.byGroups = false;
    this.global = false;
  }
}

export class RapportDto {

  format: string;
  type: string;
  rapportPayloadDto: RapportPayloadDto;
  dateInterval: DateInterval;
  timeDiff: number;
  vMax: number;
  timeOff: number;

  constructor() {
    this.format = 'EXCEL';
    this.type = '';
    this.rapportPayloadDto = new RapportPayloadDto();
    this.dateInterval = new DateInterval();
  }
}



export class ReportAuto {
  id: number;
  title : string;
  emails: string;
  period: string;
  url: string;
  payload: string;
  signinUrl: string;
}

export class ImportFileDto {

  provideName: string;
  type: string;
  file: FormData ;
  constructor() {

  }
}

export class VehiculesDtoWithGroup {
  idVehicule: number;
  matricule: string;
  mark: string;
  icon: string;
  idDevice: number;
  maxSpeed: number;
  idGroup: number;
  emails: string;

  constructor() {

  }
}

export class Group {
  idGroupe: number;
  nom: string;
  isOpen: boolean;
  imageUrl: string;
  enbaleGlobalAlert: boolean;
  idVehicules: number[];
  vehicules: Vehicule[];
  reportIsInvisible: boolean;
  extendedName: string;
  parentName: string;
}
export class Vehicule {
  idVehicule: number;
  matricule: string;
  idDevice: number;
  mark: string;
  icon: string;
  driverName: string;
  maxSpeed: number;
  alias: string;
  pathGrayCard: string;
  emails: string;
  idGroup: number;
  odoOffset: number;
  lastOdoUpdate: Date | number;
  mileage: Mileage;
  odo: number;
  oldRealTimeRecord: RealTimeRecord;
  realTimeRecord: RealTimeRecord;
  circulationAt: number | Date;
  subMark: string;
  lastName: string;
  idDriver: number;
  telephone: any;
  grayCardUri: string;
  avatarUri: string;
  status: string;
  tfu: number;
  tow: number;
  fuelLevel: number;

  driver: Driver;
  device: Device;

  avatar: File;
  grayCard: File;


  calTravel: CalTravel;

  constructor() {
    this.realTimeRecord = new RealTimeRecord();
    this.device = new Device();
    this.driver = new Driver();
    this.oldRealTimeRecord = new RealTimeRecord();
    //this.calTravel= new CalTravel();
  }

}


export class Driver {
  idDriver: number;
  code: string;
  firstName: string;
  lastName: string;
  telephone: string;
  cin: string;
  email: string;
  avatar: File;
  public bloodGroup: string;
  avatarUri: string;

  constructor() {
  }
}

export class Device {
  idDevice: number;
  deviceNumber: number;
  opt: DeviceOpt;
  carb: DeviceCarb;
  temp: DeviceTemp;

}
export class CalculatedPathDto {

  deviceId: number;
  accumOdo: number;

  constructor() {
  }
}

export class Mission {
  id: number;
  label: string;
  served: boolean;
  travelDistance: number;
  travelDuration: number;
  createAt: Date;
  routeLegs: RouteLegs[];
  pointOfInterests?: number[];

  constructor() {
    this.routeLegs = [];
    this.pointOfInterests = [];
  }
}

export class RouteLegs {
  id: number;
  sequence: number;
  travelDistance: number;
  travelDuration: number;
  idPOISource: number;
  labelPOISource: string;
  latPOISource: number;
  lngPOISource: number;
  idPOITarget: number;
  labelPOITarget: string;
  latPOITarget: number;
  lngPOITarget: number;
  selected: boolean;

  constructor() { }
}

export class TrackingMission {

  mission: Mission;
  realTimeRecord: RealTimeRecord;
  minDistance: number;
  accordingDistance: number;
  progressPercent: number;
  first: boolean;
  last: boolean;
  firstNearest: boolean;

  constructor() {
    this.mission = new Mission();
    this.realTimeRecord = new RealTimeRecord();
  }
}

 export class Widgets{
   id : number;
   name : string;
   enabled : boolean;
   
 }
