import { Component, OnInit } from '@angular/core';
import { ActionSheetController, PopoverController } from '@ionic/angular';
import { Router } from "@angular/router";
import { HeaderService } from './header.service';
import { NotificationsComponent } from './notifications/notifications.component';
import { AlertManagementService } from '../pages/alert-management/alert-management.service';
import { DateInterval } from '../model/date-interval.model';
import { version } from '../global.config';
import { DataManagementService } from '../pages/data-management/data-management.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';
import { SigninService } from '../signin/signin.service';
import { User } from '../signin/credentials';
import { RealTimeWorkerService } from '../pages/realtime/realtime-worker.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  searchfield = false;
  charged: boolean = false;
  PathArray = [];
  notifications: Notification[] = [];

  dateInterval: DateInterval = new DateInterval();
  currentDate: Date;
  expirationDatePopup: boolean = false;
  daysLeft: number;
  version: String = version;
  public currentUser: User = new User();
  liveReloadingNotif: any;
  constructor(
    private signinService: SigninService,
    public actionSheetCtrl: ActionSheetController,
    public headerService: HeaderService,
    public popoverCtrl: PopoverController,
    private alertManagementService: AlertManagementService,
    public dataManangementService: DataManagementService,
    private router: Router,
    private realTimeWorker: RealTimeWorkerService,
    public translate: TranslateService,
    private toastController: ToastController
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || '{}');
  }

  ngOnInit() {
    this.initTimer();
    this.expirationDate();
    if (localStorage.getItem('lang') == null) {
      this.translate.currentLang = 'fr';
      this.translate.setDefaultLang("fr")
      localStorage.setItem('lang', 'fr')
      this.translate.use('fr')
    } else {
      let lang = localStorage.getItem('lang') || '{}';
      this.translate.setDefaultLang(lang)
      this.translate.currentLang = lang;
      this.translate.use(lang)
    }

  }


  //alert deconnexion
  async logoutconfirm() {
    let lang = localStorage.getItem('lang')
    let actionSheet: any;
    if (lang === 'fr' || lang === 'fr') {
      actionSheet = await this.actionSheetCtrl.create({
        header: 'Souhaitez-vous vraiment vous déconnecter?',
        buttons: [
          {
            text: 'Annuler',
            role: 'cancel',
            handler: () => { }
          },
          {
            text: 'Déconnexion',
            cssClass: 'Logout',
            handler: () => {
              this.logout();
            }
          }
        ]
      });
    }

    if (lang === 'en' || lang === 'en') {
      actionSheet = await this.actionSheetCtrl.create({
        header: 'Are you sure you want to log out?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => { }
          },
          {
            text: 'Logout',
            cssClass: 'Logout',
            handler: () => {
              this.logout();
            }
          }
        ]
      });
    }

    await actionSheet.present();
  }

  logout() {
    this.dataManangementService.pointInterests = [];
    this.dataManangementService.selectedGroup = null;
    this.dataManangementService.selectedVehicule = null;

    this.dataManangementService.groups = [];
    this.dataManangementService.pointInterests = [];
    this.notifications = [];
    localStorage.removeItem("id_token");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("groups");
    localStorage.removeItem("pointInterests");
    this.router.navigate(['signin']);
    this.signinService.authState = false;

    clearTimeout(this.liveReloadingNotif);

  }

  async clickNotifications(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: NotificationsComponent,
      event: ev,
      animated: true,
      showBackdrop: true,
      cssClass: 'notifPopover',
      componentProps: { notifications: this.notifications },

    });
    return await popover.present();
  }

  loadNotification() {
    this.alertManagementService
      .getNotifications(this.dateInterval)
      .subscribe(notifications => {
        this.notifications = notifications;
      });
  }

  initTimer() {
    clearTimeout(this.liveReloadingNotif);
    this.loadNotification();
    this.liveReloadingNotif = setInterval(() => {
      this.loadNotification();
    }, 20000);
  }

  expirationDate() {
    let userExpirationDate = new Date(this.currentUser.expirationDate);
    if (this.currentUser.isRoot)
      this.daysLeft = Math.round((userExpirationDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    if (this.daysLeft <= 15) {
      this.expirationDatePopup = true;
    }

  }


  cleanHistory() {
    !this.realTimeWorker.dataManagementService.selectedVehicule;
    this.realTimeWorker.dataManagementService.selectedGroup = this.realTimeWorker.dataManagementService.groups[0]
    this.realTimeWorker.dataManagementService.selectedGroup.nom = this.realTimeWorker.dataManagementService.groups[0].nom
    this.realTimeWorker.dataManagementService.selectedVehicule = this.realTimeWorker.dataManagementService.selectedGroup.vehicules[0]
    this.realTimeWorker.dataManagementService.selectedVehicule.matricule = this.realTimeWorker.dataManagementService.selectedGroup.vehicules[0].matricule
  }

  switchLanguage = (lang: any) => {
    this.translate.use(lang);
    if (lang == "fr") {
      this.languageConfirmationMessage("Langue Français séléctionné");
      localStorage.setItem('lang', 'fr');
    }
    if (lang == "en") {
      this.languageConfirmationMessage("Language English selected");
      localStorage.setItem('lang', 'en');
    }
    if (lang == "ar") {
      this.languageConfirmationMessage("اللغة العربية المختارة");
      localStorage.setItem('lang', 'ar');
    }
  }

  async languageConfirmationMessage(message: any) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

  async chooseLanguge() {
    let actionSheet: any;
    if (this.translate.currentLang === 'fr' || this.translate.defaultLang === 'fr') {
      actionSheet = await this.actionSheetCtrl.create({
        header: 'Selectionné une langue',
        buttons: [
          {
            text: 'Annuler',
            role: 'cancel',
            handler: () => { }
          },
          {
            text: 'Anglais',
            handler: () => {
              console.log("English")
              this.switchLanguage("en")
            }
          },
          {
            text: 'Français',
            cssClass: 'Logout',
            handler: () => {
              this.switchLanguage("fr")
              console.log("Frensh")
            }
          }
        ]
      });
    }

    if (this.translate.currentLang === 'en' || this.translate.defaultLang === 'en') {
      actionSheet = await this.actionSheetCtrl.create({
        header: 'Select Languge',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => { }
          },
          {
            text: 'English',
            handler: () => {
              console.log("English")
              this.switchLanguage("en")
            }
          },
          {
            text: 'Frensh',
            cssClass: 'Logout',
            handler: () => {
              this.switchLanguage("fr")
              console.log("Frensh")
            }
          }
        ]
      });
    }

    await actionSheet.present();
  }

}
