import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export class NewServer {
  serverName: string;
  serverLink: string;
}

@Component({
  selector: 'app-add-server',
  templateUrl: './add-server.component.html',
  styleUrls: ['./add-server.component.scss'],
})
export class AddServerComponent implements OnInit {


  //serversList=['fleet.tn','gps216.fleet.tn','dh.fleet.tn','164.132.206.220','slama.fleet.tn','traci.tn','www.digitecom.tn']
  newServer = new NewServer;
  newServers: NewServer[];
  translate: TranslateService;

  constructor(private popoverController: PopoverController,  translate: TranslateService) {
    this.translate = translate;
  }

  ngOnInit() { }

  ajouter() {
    if (localStorage.getItem('newServers')) {
      this.newServers = JSON.parse(localStorage.getItem('newServers') || '{}')
      this.newServers.push(this.newServer)
      localStorage.setItem('newServers', JSON.stringify(this.newServers))
      this.popoverController.dismiss();
    } else {
      this.newServers = []
      this.newServers.push(this.newServer)
      localStorage.setItem('newServers', JSON.stringify(this.newServers))
      this.popoverController.dismiss();
    }

  }

  acceptServer() {
    //if(this.serversList.includes(this.newServer.serverLink) && this.newServer.serverName)
    if (this.newServer.serverLink && this.newServer.serverName)
      return true;
    else
      return false;
  }

}
