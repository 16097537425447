import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { token_google, GlobalService, version } from '../../../global.config';

@Injectable()
export class GeocodingService {
  geocodingSubscription: Subscription;

  version: string;
  constructor(private _http: HttpClient, private globalService: GlobalService) {
    this.version = version;
  }

  distance(lat1: any, lon1: any, lat2: any, lon2: any) {
    let p = 0.017453292519943295; // Math.PI / 180
    let c = Math.cos;
    let a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    let distance = 12742 * Math.asin(Math.sqrt(a));
    return distance;
  }

  inverseGeoconding(
    latitude: number | null,
    longitude: number | null,
    zoom: number
  ): Observable<any> {

    if (version == 'click') {
      return this._http
        .get(
          this.globalService.addok_dns + "reverse/?lat=" +
          latitude +
          '&lon=' +
          longitude
        );

    } else {
      return this._http
        .get(
          this.globalService.nominatim_dns +
          '/reverse.php?format=json&lat=' +
          latitude +
          '&lon=' +
          longitude +
          '&zoom=' +
          zoom +
          '&accept-language=fr&addressdetails=1'
        )
    }
  }

  inverseGeocondingAddok(
    latitude: number | null,
    longitude: number | null
  ): Observable<any> {
    return this._http
      .get(
        this.globalService.addok_dns + "reverse/?lat=" +
        latitude +
        '&lon=' +
        longitude
      );
  }

  inverseGeocondinPromise(
    latitude: number,
    longitude: number,
    zoom: number
  ) {
    return this._http
      .get(
        this.globalService.nominatim_dns  +
        '/reverse.php?format=json&lat=' +
        latitude +
        '&lon=' +
        longitude +
        '&zoom=' +
        zoom +
        '&accept-language=fr&addressdetails=1'
      ).toPromise()


  }

  inverseGeocondingGoogle(
    latitude: number,
    longitude: number
  ): Observable<any> {
    return this._http
      .get(
        'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
        latitude +
        ',' +
        longitude +
        '&key=' +
        token_google
      );
  }

  /*getMyIpAdress(): Observable<{ ip: string }> {
   return this._http
   .get('http://api.ipify.org/?format=json')
   .map(res => res.json());
   }*/

  getMyLocation(): Observable<any> {
    return this._http.get('http://ip-api.com/json');
  }
  /**proccessing on nominatim response */
  proccessingNomitamAddress(addressWasReturned: any) {
    let address = null;
    /**addressWasReturned : get address as json object*/
    let pattern = /[^\x00-\x7F|'é'|'è'|'ù'|'à'|'Ê'|'â'|'ô'|'î'|'û']\^ +| +$|( )+/g;
    /**
     *  don't replace any char that appear in this (|'é'|'è'|'ù'|'à'|'Ê'|'â'|'ô'|'î'|'û')
     * ^\x00-\x7F replace all non asccii char
     *  replace any char in pattern with signe space
     * ^ + : any sequence of spaces at the beginning of the string
     *  +$ : any sequence of spaces at the end of the string
     *  () any sequence of spaces that matches none of the above
     */
    if (addressWasReturned.address) {
      let road;
      let city;
      let suburb;
      let postcode;
      try {
        road = addressWasReturned.address.road.replace(pattern, ' ');
      } catch (error) { }
      try {
        city = addressWasReturned.address.city.replace(pattern, ' ');
      } catch (error) { }
      try {
        suburb = addressWasReturned.address.suburb.replace(pattern, ' ');
      } catch (error) { }
      try {
        postcode = addressWasReturned.address.postcode.replace(pattern, ' ');
      } catch (error) { }
      if (road && city && suburb && postcode) {
        return road + ',' + city + ',' + suburb + ',' + postcode;
      }
      if (road && city && postcode) {
        return road + ',' + city + ',' + postcode;
      }
      let district;
      try {
        district = addressWasReturned.address.state_district.replace(
          pattern,
          ' '
        );
      } catch (error) { }
      if (road && district && postcode) {
        return road + ',' + district + ',' + postcode;
      }
    }
    if (addressWasReturned.display_name) {
      let display_name = addressWasReturned.display_name.split(',');
      let i = 1;
      let iterate = true;
      let city = '';
      try {
        let road = display_name[0].replace(pattern, ' ');
        city = display_name[1].replace(pattern, ' ');
        let country = display_name[display_name.length - 1].replace(
          pattern,
          ' '
        );
        if (road && city && country) return road + ',' + city + ',' + country;
      } catch (error) { }
    }
    if (addressWasReturned.display_name) {
      let truncatedDisplayName = addressWasReturned.display_name;
      let countCommas = 0;
      for (
        var i = 0, len = addressWasReturned.display_name.length;
        i < len;
        i++
      ) {
        if (addressWasReturned.display_name[i] == ',') {
          countCommas = countCommas + 1;
        } else if (countCommas >= 5) {
          try {
            truncatedDisplayName = addressWasReturned.display_name.substring(
              0,
              i - 1
            );
            return truncatedDisplayName.replace(pattern, ' ');
          } catch (error) {
            if (truncatedDisplayName) return truncatedDisplayName;
          }
        }
      }
    }
  }
  /**proccessing on nominatim response */
  proccessingNomitamAddressClick(addressWasReturned: any) {
    let address = null;
    /**addressWasReturned : get address as json object*/
    let pattern = /[^\x00-\x7F|'é'|'è'|'ù'|'à'|'Ê'|'â'|'ô'|'î'|'û']\^ +| +$|( )+/g;

    if (addressWasReturned.address) {
      let road;
      let village;
      let numero;
      let city;
      let country;
      let postcode;

      try {
        road = addressWasReturned.address.road;
      } catch (error) { }
      try {
        village = addressWasReturned.address.village;
      } catch (error) { }
      if (!village) {
        try {
          village = addressWasReturned.address.town;
        } catch (error) { }
      }
      if (!village) {
        try {
          village = addressWasReturned.address.municipality;
        } catch (error) { }
      }

      try {
        numero = addressWasReturned.address.numero;
      } catch (error) { }
      try {
        country = addressWasReturned.address.country;
      } catch (error) { }
      try {
        city = addressWasReturned.address.city;
      } catch (error) { }
      if (!city) {
        try {
          city = addressWasReturned.address.state;
        } catch (error) { }
      }
      try {
        postcode = addressWasReturned.address.postcode;
      } catch (error) { }
      if (city && country && postcode) {
        return (numero ? (numero + ' ') : '') + (road ? (road + ',') : '') + village + ',' + city + ',' + country + ',' + postcode;
      }
      let district;
      try {
        district = addressWasReturned.address.state_district.replace(
          pattern,
          ' '
        );
      } catch (error) { }
      if (city && country && postcode) {
        return road + ',' + village + ',' + city + ',' + country + ',' + postcode;
      }
    }
    /*if (addressWasReturned.display_name) {
      let display_name = addressWasReturned.display_name.split(',');
      let i = 1;
      let iterate = true;
      let city = '';
      try {
       let road = display_name[0];
        city = display_name[1];
        let country = display_name[8];
        let village =display_name[2];
        let postcode = display_name[5]
        if (road && village && city && country && postcode) {
          return road + ',' + village + ',' + city + ',' + country + ',' + postcode;
        }
      } catch (error) { }
    }*/
    if (addressWasReturned.display_name) {
      let truncatedDisplayName = addressWasReturned.display_name;
      let countCommas = 0;
      for (
        var i = 0, len = addressWasReturned.display_name.length;
        i < len;
        i++
      ) {
        if (addressWasReturned.display_name[i] == ',') {
          countCommas = countCommas + 1;
        } else if (countCommas >= 5) {
          try {
            truncatedDisplayName = addressWasReturned.display_name.substring(
              0,
              i
            );
            return truncatedDisplayName.replace(pattern, ' ');
          } catch (error) {
            if (truncatedDisplayName) return truncatedDisplayName;
          }
        }
      }
    }
  }
}
