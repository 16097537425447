import { Component, OnInit, ViewChild } from '@angular/core';
import { IonPullUpFooterState } from 'ionic-pullup';
import { HistoricalService } from '../historical.service';
import { Subscription } from 'rxjs';
import { Mileage } from 'src/app/model/historical';
import { Group, Vehicule } from 'src/app/model/data-managemnt';
import * as L from 'leaflet';
import Marker = L.Marker;
import Icon = L.Icon;
import { DatePipe } from "@angular/common";
import { MileageModalComponent } from './mileage-modal/mileage-modal.component';
import { Platform, PopoverController } from '@ionic/angular';
import { DataManagementService } from '../../data-management/data-management.service';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { Map } from "leaflet";
import { GlobalService } from 'src/app/global.config';
import { LoadingService } from 'src/app/utils/loading/loadingService';

@Component({
  selector: 'app-mileage',
  templateUrl: './mileage.component.html',
  styleUrls: ['./mileage.component.scss'],
})
export class MileageComponent implements OnInit {

  @ViewChild(MileageModalComponent, { static: false }) mileageModalComponent: MileageModalComponent;

  footerState: IonPullUpFooterState;
  AllMileages: Subscription;
  totalMileage: Mileage;
  mileageClicked: Mileage;

  groups: Group[];
  //selectedGroup: Group = new Group();
  isResultLoaded: boolean = true;
  mileages: Mileage[] = [];
  mileagesExport: Mileage[] = [];
  selectedVehicule: Vehicule = new Vehicule();
  MileageArray = [];
  mileageMap: Map;
  backButtonSubscription: Subscription;
  loading: boolean;
  constructor(public historicalService: HistoricalService, private mapService: MapService,
    private popoverController: PopoverController, public dataManagementService: DataManagementService,
    private globalService: GlobalService, public dataPipe: DatePipe,
    public loadingService: LoadingService) {

    this.footerState = IonPullUpFooterState.Collapsed;
    this.historicalService.selectedTab = "mileage";
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
    if (this.dataManagementService.selectedVehicule) {
      this.loading = true;
      let endDate = this.historicalService.endDate;
      let startDate = this.historicalService.startDate;
      let idDevice = this.dataManagementService.selectedVehicule.idDevice
      this.historicalService.getAllMileages(idDevice, { startDate, endDate }).subscribe(
        mileages => {
          this.loading = false
          this.historicalService.mileages = mileages
        }
      )
    }
  }

  ionViewDidEnter() {
    this.mapService.initMap(this.mileageMap, "mileageMap");
  }

  ionViewWillLeave() {
    this.mapService.map.remove();
  }

  footerExpanded() {
    console.log('Footer expanded!');
  }

  footerCollapsed() {
    console.log('Footer collapsed!');
  }

  toggleFooter() {

    this.footerState = this.footerState === IonPullUpFooterState.Collapsed ? IonPullUpFooterState.Expanded : IonPullUpFooterState.Collapsed;
  }

  ngOnDestroy() {
    if (this.AllMileages) this.AllMileages.unsubscribe();
  }


  async openPopover() {
    const popover = await this.popoverController.create({
      component: MileageModalComponent,
      cssClass: 'historiquePopovers'
    });

    await popover.present();
  }

  MiileageSelected(mileage: any) {
    return this.MileageArray.indexOf(mileage as never) !== -1;
  }

  addMileageToArray(mileage: any, event: any) {
    if (!event.ctrlKey) {
      this.MileageArray = [];
      this.mapService.removePolylinesFromMap();
      this.mapService.removeMarkersFromMap();
    }

    this.MileageArray.push(mileage as never);
    this.drawMileage(mileage);
    this.footerState = IonPullUpFooterState.Collapsed;
  }

  MileageSelected(mileage: any) {
    return this.MileageArray.indexOf(mileage as never) !== -1;
  }
  drawMileage(mileage: any) {
    if (
      this.mileageClicked == null ||
      this.mileageClicked.startHour != mileage.startHour
    ) {
      this.mileageClicked = new Mileage();
      this.mileageClicked.startHour = mileage.startHour;
      this.mileageClicked.endHour = mileage.endHour;
    }
    /*this.mapService.removePolylinesFromMap();
     this.mapService.removeMarkersFromMap();*/
    let maxSpeed = this.selectedVehicule.maxSpeed;
    let startHour: any = mileage.startHour;
    this.historicalService
      .getMileageDetails(this.dataManagementService.selectedVehicule!.idDevice, {
        startDate: startHour,
        endDate: mileage.endHour
      })
      .subscribe(mileage => {
        let points = mileage.points;
        let stops = mileage.stops;
        let polyline = L.polyline(points, { color: "#0031D9", weight: 3 });
        points.forEach((coordinate: any, i: any) => {
          let marker = new Marker(coordinate);
          if (i != 0 && i != points.length - 1) {
            if (coordinate.speed > maxSpeed) {
              marker.setIcon(
                new Icon({
                  iconUrl: this.globalService.imagesDir + "red-point.png",
                  iconAnchor: [2, 2]
                })
              );
            } else if (coordinate.speed < 5) {
              marker.setIcon(
                new Icon({
                  iconUrl: this.globalService.imagesDir + "blue-point.png",
                  iconAnchor: [2, 2]
                })
              );
            } else {
              marker.setIcon(
                new Icon({
                  iconUrl: this.globalService.imagesDir + "green-point.png",
                  iconAnchor: [2, 2]
                })
              );
            }
            //var pointDatePipe = new DatePipe(coordinate.date);
            let popup =
              "<b>Heure:</b> " +
              this.dataPipe.transform(coordinate.date, "dd/MM/yyyy HH:mm:ss", 'en-US') +
              "<b><br>vitesse:</b> " +
              coordinate.speed +
              " Km/h";
            marker.bindPopup(popup);
            marker.on("click", () => {
              this.mapService.map.setView(coordinate, 17);
            });
            this.mapService.addMarker(marker);
          }
        });
        stops.forEach((stop: any, i: any) => {
          let marker = new Marker({
            lat: stop.stopLatitude,
            lng: stop.stopLongitude
          });
          //var pointDatePipe = new DatePipe(stop.beginStopTime);
          let popup =
            "<b>Date debut d'arrêt: </b>" +
            this.dataPipe.transform(stop.beginStopTime, "dd/MM/yyyy HH:mm:ss", 'en-US') +
            "<br><b>Durée d'arrêt: </b>" +
            stop.stopDurationStr;
          marker.setIcon(
            new Icon({
              iconUrl: this.globalService.imagesDir + "stop_smal.png",
              iconAnchor: [-2, 30],
              popupAnchor: [10, -25]
            })
          );
          marker.bindPopup(popup);
          marker.on("click", () => {
            this.mapService.map.setView(
              { lat: stop.stopLatitude, lng: stop.stopLongitude },
              17
            );
          });
          this.mapService.addMarker(marker);
        });
        this.mapService.addPolyline(polyline);
        this.mapService.map.fitBounds(polyline.getBounds());
      });
  }

}
