import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Vehicule } from 'src/app/model/data-managemnt';

import * as moment from 'moment';
import { SigninService } from 'src/app/signin/signin.service';
import { RealTimeWorkerService } from 'src/app/pages/realtime/realtime-worker.service';
import { Router } from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import { DataManagementService } from 'src/app/pages/data-management/data-management.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {

  hasChauffeur: boolean;

  notifications: Notification[];
  constructor(public navParams: NavParams,
    public translate : TranslateService,
    private router: Router,
    private signinService: SigninService,
    public popoverCtrl: PopoverController,
    private workerService: RealTimeWorkerService,
    public dataManagementService: DataManagementService
  ) {
    this.notifications = this.navParams.get('notifications');
  }


  ngOnInit() {
    this.isHasChauffeur();
    //this.getChauffeurName(700002)
    moment.updateLocale('fr', {
      relativeTime: {
        future: "Dans %s",
        past: "%s",
        s: "Maintenant",
        m: "1 min",
        mm: "%d mins",
        h: "1 heure",
        hh: "%d heures",
        d: "1 jour",
        dd: "%d jours",
        M: "1 mois",
        MM: "%d mois",
        y: "1 an",
        yy: "%d ans"
      }
    });
  }

  isHasChauffeur() {
    if (this.signinService.isHasChauffeurOption()) {
      return this.hasChauffeur = true;
    }
    return false;
  }

  navigate() {
    this.popoverCtrl.dismiss();
    this.router.navigate(['/notification-details'])
  }

  /*getChauffeurName(id: any) {

    let vehicule: Vehicule[] = this.workerService.getVehicule(id)
    if (vehicule[0] && vehicule[0].driver) {
      if (vehicule[0].driver.lastName && vehicule[0].driver.firstName) {
        return vehicule[0].driver.firstName + ' ' + vehicule[0].driver.lastName
      }
      if (vehicule[0].driver.lastName && !vehicule[0].driver.firstName) {
        return vehicule[0].driver.lastName
      }
      if (!vehicule[0].driver.lastName && vehicule[0].driver.firstName) {
        return vehicule[0].driver.firstName
      }
      if (!vehicule[0].driver.lastName && !vehicule[0].driver.firstName) {
        return "-"
      }
    } else {
      return "-"
    }
    return '';
  }*/

  closeNotifications() {
    this.popoverCtrl.dismiss()
  }

  selectedLanguage() {
    let lang = localStorage.getItem('lang')
    if (lang) {
      return lang
    } else return 'fr'
  }







}
