import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManagementService } from '../../data-management/data-management.service';
import { Group, Vehicule } from 'src/app/model/data-managemnt';
import { Subscription } from 'rxjs';
import { Path } from 'src/app/model/historical';
import { HistoricalService } from '../historical.service';
import { Platform, PopoverController } from '@ionic/angular';
import { DatePipe } from "@angular/common";
import * as L from 'leaflet';
import Icon = L.Icon;
import Marker = L.Marker;

import { IonPullUpFooterState } from 'ionic-pullup';
import { PathModalComponent } from './path-modal/path-modal.component';
import { HeaderService } from 'src/app/header/header.service';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { Map } from "leaflet";
import { GlobalService } from 'src/app/global.config';
import '../../../../assets/leaflet-play/dist/LeafletPlayback.js';
import { LoadingService } from 'src/app/utils/loading/loadingService';
import { TranslateService } from '@ngx-translate/core';
declare let P: any;

import { version } from '../../../global.config';

@Component({
  selector: 'app-path',
  templateUrl: './path.component.html',
  styleUrls: ['./path.component.scss'],
})
export class PathComponent implements OnInit {
  pathMap: Map;
  //groups: Group[];
  //selectedGroup: Group = new Group();
  //selectedVehicule: Vehicule | null;
  AllGroups: Subscription;
  PathArray = [];
  AllPaths: Subscription;
  paths: Path[] = [];
  pathClicked: Path;
  isResultLoaded = true;
  typicalPathName: string;
  showSpiderChart = false;
  showDetails = false;
  dataReturned: any;
  footerState: IonPullUpFooterState;
  backButtonSubscription: Subscription;
  @ViewChild(PathModalComponent, { static: false }) pathModalComponent: PathModalComponent;
  playback: any;
  loading: boolean;
  version: string;

  constructor(public dataManagementService: DataManagementService,
    public mapService: MapService,
    private geocodingService: GeocodingService,
    public historicalService: HistoricalService,
    public popoverController: PopoverController,
    private headerService: HeaderService, private platform: Platform,
    private globalService: GlobalService,
    public loadingService: LoadingService,
    public translate: TranslateService, public datepipe: DatePipe
  ) {
    this.footerState = IonPullUpFooterState.Collapsed;
    this.headerService.selectedTab = "historical";
    this.historicalService.selectedTab = "path";
    this.version = version;
  }

  ngOnInit() {
    console.log(this.translate.currentLang)
    // this.dataManagementService.selectedGroup=this.dataManagementService.groups[0]
    // this.dataManagementService.selectedGroup.nom=this.dataManagementService.groups[0].nom
    // this.dataManagementService.selectedVehicule=this.dataManagementService.selectedGroup.vehicules[0]
    // this.dataManagementService.selectedVehicule.matricule=this.dataManagementService.selectedGroup.vehicules[0].matricule

    // if (this.dataManagementService.selectedVehicule) {

    //   this.historicalService.getAllPathRoutine();
    // }
    this.mapService.animatedMarker = null;
  }
  ngOnDestroy() {
    if (this.AllGroups) this.AllGroups.unsubscribe();
    if (this.AllPaths) this.AllPaths.unsubscribe();
  }

  ionViewWillEnter() {

    if (this.dataManagementService.selectedVehicule) {

      this.historicalService.getAllPathRoutine();
    }
  }

  ionViewDidEnter() {
    this.mapService.initMap(this.pathMap, "pathMap");
    this.mapService.animatedMarker = null;
  }
  ionViewWillLeave() {
    this.mapService.map.remove();
  }
  PathSelected(path: any) {
    return this.PathArray.indexOf(path as never) !== -1;
  }

  drawPath(path: Path) {
    if (
      this.pathClicked == null ||
      this.pathClicked.beginPathTime !== path.beginPathTime
    ) {
      this.pathClicked = new Path();
      this.pathClicked = path;
    }

    let maxSpeed = this.dataManagementService.selectedVehicule!.maxSpeed;
    if (this.playback) {
      this.playback.destroy();
    }
    this.historicalService
      .getPathDetails(this.dataManagementService.selectedVehicule!.idDevice, {
        startDate: path.beginPathTime,
        endDate: path.endPathTime
      })
      .subscribe(

        points => {
          let startHour: any = path.beginPathTime;
          let pathStartTime: any = startHour;
          path.displayBeginPathTime = pathStartTime;
          let endHour: any = path.endPathTime;
          let pathEndTime: any = endHour;
          path.displayEndPathTime = pathEndTime;
          let stopMarkers: Marker[] = [];
          points.stops.forEach((stop: any) => {
            let popup =
              "<img src='" +
              this.globalService.imagesDir +
              "stop_smal.png" +
              "'/> Durée d'arrêt : " +
              stop.stopDurationStr;
            let stopMarker = new Marker({
              lat: stop.stopLatitude,
              lng: stop.stopLongitude
            });

            this.geocodingService
              .inverseGeoconding(stop.stopLatitude, stop.stopLongitude, 17)
              .subscribe(adress => {
                let truncatedDisplayName = adress.features[0].properties.label;
                popup = popup + "<br><hr><b>" + truncatedDisplayName + "</b>";
                stopMarker.bindPopup(popup);
              });

            stopMarker.setIcon(
              new Icon({
                iconUrl: this.globalService.imagesDir + "stop_smal.png",
                iconAnchor: [-1, 18],
                popupAnchor: [10, -25]
              })
            );
            this.mapService.addMarker(stopMarker);
          });

          let polyline = L.polyline(points.coordinates, {
            color: "#0031D9",
            weight: 3
          });

          points.coordinates.forEach((coordinate: any, i: any) => {
            let marker = new Marker(coordinate);
            if (i != 0 && i != points.coordinates.length - 1) {
              if (coordinate.speed > maxSpeed) {
                marker.setIcon(
                  new Icon({
                    iconUrl: this.globalService.imagesDir + "red-point.png",
                    iconAnchor: [2, 2]
                  })
                );
              } else if (coordinate.speed < 5) {
                marker.setIcon(
                  new Icon({
                    iconUrl: this.globalService.imagesDir + "blue-point.png",
                    iconAnchor: [2, 2]
                  })
                );
              } else {
                marker.setIcon(
                  new Icon({
                    iconUrl: this.globalService.imagesDir + "green-point.png",
                    iconAnchor: [2, 2]
                  })
                );
              }
              let pointTime: any = coordinate.date;
              let coordinateTime: any = pointTime;
              coordinate.date = coordinateTime;
              // var pointDatePipe = new DatePipe(coordinate.date);
              let popup =
                "<b>Heure:</b> " +
                this.datepipe.transform(
                  coordinate.date, "dd/MM/yyyy HH:mm:ss", 'en-US'
                ) +
                "<b><br>vitesse:</b> " +
                coordinate.speed +
                " Km/h"
                + "<br>" + coordinate.lat + "<br>" + coordinate.lng;
              marker.bindPopup(popup);
              this.mapService.addMarker(marker);
            }
          });
          var startMarker = new Marker({
            lat: path.beginPathLatitude,
            lng: path.beginPathLongitude
          });
          try {
            var coordinates = points.coordinates
            var point: any[] = [];
            var times: any[] = [];

            for (var i = 0; i < coordinates.length; i++) {

              point[i] = [coordinates[i].lng, coordinates[i].lat] as never;
              times[i] = coordinates[i].date as never;

            }

            var demoTracks = {
              "type": "Feature",
              "geometry": {
                "type": "MultiPoint",
                "coordinates": point
              },
              "properties": {
                "time": times
              }
            }

            // Playback options
            var playbackOptions = {
              playControl: true,
              dateControl: false,
              sliderControl: false,
              tracksLayer: false,
              staleTime: 2000,
              fadeMarkersWhenStale: true
            };


            //Initialize playback
            //this.playback = new L.Playback(this.mapService.map, demoTracks, null, playbackOptions);

          } catch (error) {
            console.log(error)
          }
          let startTime: any = path.displayBeginPathTime;
          //var startDatePipe = new DatePipe(startTime);
          startMarker.bindPopup(
            "<b> Lieu de début: </b>" +
            this.pathClicked.beginPathGeocoding +
            "<br><b> <i>[lat: </b>" +
            path.beginPathLatitude +
            "<b> ,long: </b>" +
            path.beginPathLongitude +
            "<b>]</i><br>Temps de debut du trajet : </b>" +
            this.datepipe.transform(startTime, "dd/MM/yyyy HH:mm:ss", 'en-US')
          );
          startMarker.setIcon(
            new Icon({
              iconUrl: this.globalService.imagesDir + "startMarker.png",
              iconAnchor: [0, 25],
              popupAnchor: [10, -25]
            })
          );
          this.mapService.addMarker(startMarker);
          if (
            path.endPathLatitude != null &&
            path.endPathLongitude != null &&
            path.endPathTime != null
          ) {
            var endMarker = new Marker({
              lat: path.endPathLatitude,
              lng: path.endPathLongitude
            });
            let endTime: any = path.displayEndPathTime;
            // var endDatePipe = new DatePipe(endTime);
            endMarker.bindPopup(
              "<b> Lieu de fin: </b>" +
              this.pathClicked.endPathGeocoding +
              "<br><i>[<b>lat:  </b>" +
              path.endPathLatitude +
              "<b>,long:  </b>" +
              path.endPathLongitude +
              "]</i><br><b>Temps de fin du trajet : </b>" +
              this.datepipe.transform(endTime, "dd/MM/yyyy HH:mm:ss", 'en-US') +
              "<br><b> Durée du trajet : </b>" +
              path.pathDurationStr +
              "<br><b> Durée d'arrêt : </b>" +
              path.nextStopDurationStr +
              "<br><b> Vitesse maximum : </b>" +
              path.maxSpeed +
              " Km/h <br><b> Kilometrage parcouru : </b>" +
              path.distanceDriven.toFixed(2) +
              " Km"
            );
            endMarker.setIcon(
              new Icon({
                iconUrl: this.globalService.imagesDir + "endMarker.png",
                iconAnchor: [0, 25],
                popupAnchor: [10, -25]
              })
            );
            this.mapService.addMarker(endMarker);
          }
          this.mapService.addPolyline(polyline);
          this.mapService.map.fitBounds(polyline.getBounds());
          /*var middle =
            points.coordinates[Math.round((points.coordinates.length - 1) / 2)];
          //document.getElementById("save_path_button").classList.remove('hide');

          //map center offset(axis y)
          this.mapService.offsetMap(middle, 12, 0, 0);*/

          if (this.mapService.animatedMarker) {
            this.stopAnimation()
          }

          this.mapService.makeAnimatedMarker(polyline, points.coordinates, 'simulation')
        },
        err => { }
      );
  }

  async openPopover() {
    const popover = await this.popoverController.create({
      component: PathModalComponent,
      cssClass: 'historiquePopovers'
    });

    await popover.present();
  }



  displayGoogleAdress(path: Path) {
    if (path) {
      this.geocodingService
        .inverseGeocondingGoogle(
          path.beginPathLatitude,
          path.beginPathLongitude
        )
        .subscribe(response => {
          if (response && response.results[0]) {
            path.beginPathGeocodingDetails =
              response.results[0].formatted_address;
          }
        });

      this.geocodingService
        .inverseGeocondingGoogle(path.endPathLatitude, path.endPathLongitude)
        .subscribe(response => {
          if (response && response.results[0]) {
            path.endPathGeocodingDetails =
              response.results[0].formatted_address;
          }
        });
    }
  }

  addPathToArray(path: any, event: any) {
    if (!event.ctrlKey) {
      this.PathArray = [];
      this.mapService.removePolylinesFromMap();
      this.mapService.removeMarkersFromMap();
      /* if (this.playback) {
         this.playback.destroy();
       }*/
    }

    this.PathArray.push(path as never);
    this.drawPath(path);
    this.footerState = IonPullUpFooterState.Collapsed;
    //this.trackingPath(path);
    //this.spiderChart.getSpiderChart(this.selectedVehicule, path);
  }

  footerExpanded() {
    console.log('Footer expanded!');
  }

  footerCollapsed() {
    console.log('Footer collapsed!');
  }

  toggleFooter() {

    this.footerState = this.footerState === IonPullUpFooterState.Collapsed ? IonPullUpFooterState.Expanded : IonPullUpFooterState.Collapsed;

  }



  startAnimation() {
    this.mapService.simulation = true
    this.mapService.addMarker(this.mapService.animatedMarker)
    this.mapService.animatedMarker.start();
    this.mapService.animatedMarker.openPopup()
    this.mapService.replaySimulation = true
    //this.mapService.fitBounds(this.mapService.animatedMarker.getLatLng())
  }

  stopAnimation() {
    this.mapService.simulation = false
    this.mapService.animatedMarker.stop();
  }




}
