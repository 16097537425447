import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, AlertController } from '@ionic/angular';
import { DataManagementService } from '../../data-management/data-management.service';
import { RealTimeService } from '../realtime.service';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent {

  constructor(public navCtrl: NavController, public navParams: NavParams, private alertCtrl: AlertController,
    private realTimeService: RealTimeService, public dataManagementService : DataManagementService) {
  }

  ionViewDidLoad() {
  // console.log('ionViewDidLoad PromptPage');
  }

  async stop() {
    let alert = await this.alertCtrl.create({
      message: 'Confirmation',

      inputs: [
        {
          name: 'username',
          placeholder: 'nom d utilisateur'
        },
        {
          name: 'password',
          placeholder: 'Mot de passe',
          type: 'password'
        }
      ],
      buttons: [
        {
          text: 'confirmer',
          role: 'confirmer',
          handler: data => {
            console.log('dataaaaaaaa', JSON.stringify(data))
            this.stopEngine(this.dataManagementService.selectedVehicule!.idDevice, data)

          }
        },
        {
          text: 'Annuler',
          role: 'Annuler',
          handler: data => {

          }
        }
      ]
    });
    await alert.present();
  }

  async start() {
    let alert = await this.alertCtrl.create({
      // title: 'Confirmation',
      message: 'Confirmation',
      inputs: [
        {
          name: 'username',
          placeholder: 'nom d utilisateur'
        },
        {
          name: 'password',
          placeholder: 'Mot de passe',
          type: 'password'
        }
      ],
      buttons: [
        {
          text: 'confirmer',
          role: 'confirmer',
          handler: data => {
            this.startEngine(this.dataManagementService.selectedVehicule!.idDevice)

          }
        },
        {
          text: 'Annuler',
          role: 'Annuler',
          handler: data => {

          }
        }
      ]
    });
    await alert.present();
  }

  //start moteur
  startEngine(deviceId:any) {
    this.realTimeService.startEngine(deviceId).subscribe(cmdSended => {
      alert("commande Lancement moteur envoyé !");
    });
  }

  //stop moteur
  stopEngine(deviceId:any, authData:any) {
    this.realTimeService.stopEngine(deviceId).subscribe(cmdSended => {
      alert("commande Arrêt moteur envoyé !");
    });
  }
}
