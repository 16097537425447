import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  selectedTab : String; 
  constructor() {
    this.selectedTab = "realtime";
   }
}
