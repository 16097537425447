import { Injectable } from '@angular/core';
import { createAuthorizationHeader } from '../../utils/headers';
import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { User } from '../../signin/credentials';
import { GlobalService } from 'src/app/global.config';
import { Events } from 'src/app/utils/Events';
@Injectable({
  providedIn: 'root'
})
export class RealTimeService {
  constructor(private _http: HttpClient, public events: Events, private globalService : GlobalService) {

  }
  getRealTimeRecord(deviceId: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get( this.globalService.dns + 'realTimeRecords/' + deviceId, {headers: headers});
  }
  

  getAllRealTimeRecords(): Observable<any> {
    let headers = createAuthorizationHeader();
    let parms = "";
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser') || '{}');
   /* if (!currentUser.isRoot) {
      parms = '?id=' + currentUser.id;
    }*/
    return this._http.get( this.globalService.dns + 'realTimeRecords' + parms, { headers: headers });
  }

  startEngine(idBoitier: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post( this.globalService.dns + 'engine/start/' + idBoitier, null, { headers: headers });
  }

  stopEngine(idBoitier: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post( this.globalService.dns + 'engine/stop/' + idBoitier, null, { headers: headers });
  }
}
