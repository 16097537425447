import { Component, OnInit } from '@angular/core';
import { version } from "../../../global.config";
import { DateInterval } from "../../../model/date-interval.model";
import { AlertManagementService } from "../alert-management.service";
import { Notification } from "../../../model/notification";
import { DataManagementService } from '../../data-management/data-management.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrls: ['./alert-notification.component.scss'],
})
export class AlertNotificationComponent implements OnInit {

  notifications: Notification[];
  dateInterval: DateInterval = new DateInterval();
  liveReloadingNotif: any;
  nameNotification: string = '';
  constructor(private router: Router, private alertManagementService: AlertManagementService, public dataManagementService: DataManagementService) {
  }

  version: String = version;

  ngOnInit() {
    this.consoleNotif();
    this.initTimer();
  }

  loadNotification() {
    this.alertManagementService
      .getNotifications(this.dateInterval)
      .subscribe(notifications => {
        this.notifications = notifications;
      });
  }


  /* for (let i = 0; i < this.notifications.length; i++) {
     if (this.notifications[i].name == 'SPEED') {
       this.nameNotification = 'Vitesse';
     } else if (this.notifications[i].name == 'INGNITION') {
       this.nameNotification = 'Contact v = 1';
     } else if (this.notifications[i].name == 'POI') {
       this.nameNotification = 'Arrêts P.O.I';
     } else if (this.notifications[i].name == 'NO_POI') {
       this.nameNotification = 'Arrêts non P.O.I';
     } else if (this.notifications[i].name == 'VIDANGE') {
       this.nameNotification = 'Vidange';
     } else if (this.notifications[i].name == 'DOC') {
       this.nameNotification = 'Documents de circulations';
     } else if (this.notifications[i].name == 'BRAKE') {
       this.nameNotification = 'Freins';
     } else if (this.notifications[i].name == 'TIRE') {
       this.nameNotification = 'Pneus';
     } else if (this.notifications[i].name == 'TOW') {
       this.nameNotification = 'Remorquage';
     } else if (this.notifications[i].name == 'BATTERY') {
       this.nameNotification = 'Bateries';
     } else if (this.notifications[i].name == 'ZONE') {
       this.nameNotification = 'Zone';
     } else if (this.notifications[i].name == 'INGNITION_V0') {
       this.nameNotification = 'Contact v= 0';
     } else if (this.notifications[i].name == 'SIPHONAGE') {
       this.nameNotification = 'Variation carburant brusque';
     } else if (this.notifications[i].name == 'SPEED_NOT_HIGHWAY') {
       this.nameNotification = 'Vitesse pas autoroute';
     } else if (this.notifications[i].name == 'SPEED_HIGHWAY') {
       this.nameNotification = 'Vitesse autoroute';
     } else if (this.notifications[i].name == 'SPEED_CARTO') {
       this.nameNotification = 'Vitesse Tolerance';
     } else if (this.notifications[i].name == 'TEMP_ENGINE') {
       this.nameNotification = 'Tempeartue moteur';
     } else if (this.notifications[i].name == 'ACTIVITY_DAILY_START') {
       this.nameNotification = 'Planification';
     } else if (this.notifications[i].name == 'APPRO_FUEL') {
       this.nameNotification = ' Approvisionnement de carburant';
     } else if (this.notifications[i].name == 'REMISAGE') {
       this.nameNotification = 'Remisage';
     } else if (this.notifications[i].name == 'DAILY_DISTANCE') {
       this.nameNotification = 'Distance journalière';
     } else {
       this.nameNotification = 'Autres';
     }
   }*/


  initTimer() {
    clearTimeout(this.liveReloadingNotif);
    this.loadNotification();
    this.liveReloadingNotif = setInterval(() => {
      this.loadNotification();
    }, 20000);
  }

  consoleNotif() {
    console.log(this.notifications)
  }

  close() {
    this.router.navigate(['client']);
  }

}
