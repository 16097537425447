import * as L from 'leaflet';

import Marker = L.Marker;
import Icon = L.Icon;

export class RtMarker {
  id: number;
  value: any;
  icon: Icon | null | undefined;
  angle: number;
}
export class PoiCluster{

  mapMarkerclusterPoi: any;
  constructor(){
    this.mapMarkerclusterPoi = new Map();
  }
}
	