import { HttpHeaders } from '@angular/common/http';

export var contentHeaders = new HttpHeaders();
contentHeaders = contentHeaders.append('Accept', 'application/json');
contentHeaders = contentHeaders.append('Content-Type', 'application/json');
export var id_token=localStorage.getItem('id_token');

export function createAuthorizationHeader() : HttpHeaders {
  let headers=new HttpHeaders({ 'Accept': 'application/json',
    'Authorization': localStorage.getItem('id_token') || '{}'
  });
  return headers;
}

export function createAuthorizationHeaderForm() : HttpHeaders {
  let headers=new HttpHeaders({ 'Accept': 'application/json',
    'Authorization': localStorage.getItem('id_token') || '{}'
  });
  return headers;
}
